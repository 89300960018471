import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import proxy from 'proxy';

const initialState = {
    isError: false,
    isLoading: false,
    reportData: [],
};

export const getReportData = createAsyncThunk(
    'getReportData',
    async (projectID) => {
        const response = await fetch(
            `${proxy}/report/getSlots?projectID=${projectID}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        const responseBody = await response.json();
        return responseBody;
    },
);

export const addSlot = createAsyncThunk('addSlot', async (data) => {
    const response = await fetch(`${proxy}/report/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    const responseBody = await response.json();
    return { responseBody, data };
});

export const updateSlot = createAsyncThunk('updateSlot', async (data) => {
    const response = await fetch(`${proxy}/report/updateSlot`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    const responseBody = await response.json();
    return responseBody;
});

export const deleteSlot = createAsyncThunk('deleteSlot', async (data) => {
    const response = await fetch(`${proxy}/report/deleteSlot`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    const responseBody = await response.json();
    return { responseBody, data };
});

export const reportDuration = createAsyncThunk(
    'reportDuration',
    async (data) => {
        const response = await fetch(`${proxy}/report/updateSlotInfo`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        const responseBody = await response.json();
        return responseBody;
    },
);

const reportAutomationSlice = createSlice({
    name: 'reportAutomation',
    initialState,
    extraReducers: (builder) => {
        // for get report data
        builder.addCase(getReportData.pending, (state, action) => {
            state.isLoading = true;
            state.isError = false;
        });

        builder.addCase(getReportData.fulfilled, (state, action) => {
            const reportInfo = action.payload.data;
          

            if (action.payload.data) {
                reportInfo.forEach((report) => {

                    //for Set slots
                    if (report.setSlot.hour > 12 && report.setSlot.hour < 22) {
                        report.setSlot.hour =
                            '0' + (report.setSlot.hour - 12) + ':' + (report.setSlot.minute < 10 ? "0" + report.setSlot.minute : report.setSlot.minute) + ' P.M';
                    } else if (report.setSlot.hour >= 22) {
                        report.setSlot.hour = report.setSlot.hour - 12 + ':' +  (report.setSlot.minute < 10 ? "0" + report.setSlot.minute : report.setSlot.minute)+ ' P.M';
                    } else if (report.setSlot.hour >= 10 && report.setSlot.hour <= 12) {
                        report.setSlot.hour = report.setSlot.hour + ':' + (report.setSlot.minute < 10 ? "0" + report.setSlot.minute : report.setSlot.minute) + ' A.M';
                    } else {
                        report.setSlot.hour = '0' + report.setSlot.hour + ':' +  (report.setSlot.minute < 10 ? "0" + report.setSlot.minute : report.setSlot.minute) + ' A.M';
                    }


                    //for Set schedule
                    if (report.setSchedule.hour > 12 && report.setSchedule.hour < 22) {
                        report.setSchedule.hour =
                            '0' + (report.setSchedule.hour - 12) + ':' + (report.setSlot.minute < 10 ? "0" + report.setSlot.minute : report.setSlot.minute) + ' P.M';
                    } else if (report.setSchedule.hour >= 22) {
                        report.setSchedule.hour = report.setSchedule.hour - 12 + ':' + (report.setSlot.minute < 10 ? "0" + report.setSlot.minute : report.setSlot.minute) + ' P.M';
                    } else if (report.setSchedule.hour >= 10 && report.setSchedule.hour <= 12) {
                        report.setSchedule.hour = report.setSchedule.hour + ':' +  (report.setSlot.minute < 10 ? "0" + report.setSlot.minute : report.setSlot.minute) + ' A.M';
                    } else {
                        report.setSchedule.hour = '0' + report.setSchedule.hour + ':' +  (report.setSlot.minute < 10 ? "0" + report.setSlot.minute : report.setSlot.minute) + ' A.M';
                    }


                    //for Set timer
                    if (report.timer.hour > 12 && report.timer.hour < 22) {
                        report.timer.hour =
                            '0' + (report.timer.hour - 12) + ':' + '00' + 'P.M';
                    } else if (report.timer.hour >= 22) {
                        report.timer.hour = (report.timer.hour - 12) + ':00 ' + 'P.M';
                    } else if (report.timer.hour >= 10 && report.timer.hour <= 12) {
                        report.timer.hour = (report.timer.hour) + ':00 ' + 'A.M';
                    } else {
                        report.timer.hour = '0' + (report.timer.hour)+ ':00 ' + 'A.M';
                    }
                });
            }

            state.reportData = action.payload.data;
        });

        builder.addCase(getReportData.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = false;
        });

        //for Add slots

        builder.addCase(addSlot.pending, (state, action) => {
            state.isLoading = true;
            state.isError = false;
        });

        builder.addCase(addSlot.fulfilled, (state, action) => {
           
            const reportInfo = action.payload.responseBody.slot
            if (state.reportData !== undefined) {

                

                    //for Set slots
                    if (reportInfo.setSlot.hour > 12 && reportInfo.setSlot.hour < 22) {
                        reportInfo.setSlot.hour =
                            '0' + (reportInfo.setSlot.hour - 12) + ':' + (reportInfo.setSlot.minute < 10 ? "0" + reportInfo.setSlot.minute : reportInfo.setSlot.minute) + ' P.M';
                    } else if (reportInfo.setSlot.hour >= 22) {
                        reportInfo.setSlot.hour = reportInfo.setSlot.hour - 12 + ':' + (reportInfo.setSlot.minute < 10 ? "0" + reportInfo.setSlot.minute : reportInfo.setSlot.minute) + ' P.M';
                    } else if (reportInfo.setSlot.hour >= 10 && reportInfo.setSlot.hour <= 12) {
                        reportInfo.setSlot.hour = reportInfo.setSlot.hour + ':' +  (reportInfo.setSlot.minute < 10 ? "0" + reportInfo.setSlot.minute : reportInfo.setSlot.minute) + ' A.M';
                    } else {
                        reportInfo.setSlot.hour = '0' + reportInfo.setSlot.hour + ':' +  (reportInfo.setSlot.minute < 10 ? "0" + reportInfo.setSlot.minute : reportInfo.setSlot.minute) + ' A.M';
                    }


                    //for Set schedule
                    if (reportInfo.setSchedule.hour > 12 && reportInfo.setSchedule.hour < 22) {
                        reportInfo.setSchedule.hour =
                            '0' + (reportInfo.setSchedule.hour - 12) + ':' +  (reportInfo.setSlot.minute < 10 ? "0" + reportInfo.setSlot.minute : reportInfo.setSlot.minute) + ' P.M';
                    } else if (reportInfo.setSchedule.hour >= 22) {
                        reportInfo.setSchedule.hour = reportInfo.setSchedule.hour - 12 + ':' + (reportInfo.setSlot.minute < 10 ? "0" + reportInfo.setSlot.minute : reportInfo.setSlot.minute) + ' P.M';
                    } else if (reportInfo.setSchedule.hour >= 10 && reportInfo.setSchedule.hour <= 12) {
                        reportInfo.setSchedule.hour = reportInfo.setSchedule.hour + ':' +  (reportInfo.setSlot.minute < 10 ? "0" + reportInfo.setSlot.minute : reportInfo.setSlot.minute) + ' A.M';
                    } else {
                        reportInfo.setSchedule.hour = '0' + reportInfo.setSchedule.hour + ':' +  (reportInfo.setSlot.minute < 10 ? "0" + reportInfo.setSlot.minute : reportInfo.setSlot.minute) + ' A.M';
                    }


                    //for Set timer
                    if (reportInfo.timer.hour > 12 && reportInfo.timer.hour < 22) {
                        reportInfo.timer.hour =
                            '0' + (reportInfo.timer.hour - 12) + ':' + '00' + 'P.M';
                    } else if (reportInfo.timer.hour >= 22) {
                        reportInfo.timer.hour = (reportInfo.timer.hour - 12) + ':00 ' + 'P.M';
                    } else if (reportInfo.timer.hour >= 10 && reportInfo.timer.hour <= 12) {
                        reportInfo.timer.hour = (reportInfo.timer.hour) + ':00 ' + 'A.M';
                    } else {
                        reportInfo.timer.hour = '0' + (reportInfo.timer.hour)+ ':00 ' + 'A.M';
                    }

                    
               
                state.reportData = [...state.reportData, reportInfo];
            } else {
                if (reportInfo.setSlot.hour > 12 && reportInfo.setSlot.hour < 22) {
                    reportInfo.setSlot.hour =
                        '0' + (reportInfo.setSlot.hour - 12) + ':' + reportInfo.setSlot.minute + ' P.M';
                } else if (reportInfo.setSlot.hour >= 22) {
                    reportInfo.setSlot.hour = reportInfo.setSlot.hour - 12 + ':' +  reportInfo.setSlot.minute + ' P.M';
                } else if (reportInfo.setSlot.hour >= 10 && reportInfo.setSlot.hour <= 12) {
                    reportInfo.setSlot.hour = reportInfo.setSlot.hour + ':' +  reportInfo.setSlot.minute + ' A.M';
                } else {
                    reportInfo.setSlot.hour = '0' + reportInfo.setSlot.hour + ':' +  reportInfo.setSlot.minute + ' A.M';
                }


                //for Set schedule
                if (reportInfo.setSchedule.hour > 12 && reportInfo.setSchedule.hour < 22) {
                    reportInfo.setSchedule.hour =
                        '0' + (reportInfo.setSchedule.hour - 12) + ':' +  reportInfo.setSchedule.minute + ' P.M';
                } else if (reportInfo.setSchedule.hour >= 22) {
                    reportInfo.setSchedule.hour = reportInfo.setSchedule.hour - 12 + ':' + reportInfo.setSchedule.minute + ' P.M';
                } else if (reportInfo.setSchedule.hour >= 10 && reportInfo.setSchedule.hour <= 12) {
                    reportInfo.setSchedule.hour = reportInfo.setSchedule.hour + ':' +  reportInfo.setSchedule.minute + ' A.M';
                } else {
                    reportInfo.setSchedule.hour = '0' + reportInfo.setSchedule.hour + ':' +  reportInfo.setSchedule.minute + ' A.M';
                }


                //for Set timer
                if (reportInfo.timer.hour > 12 && reportInfo.timer.hour < 22) {
                    reportInfo.timer.hour =
                        '0' + (reportInfo.timer.hour - 12) + ':' + '00' + 'P.M';
                } else if (reportInfo.timer.hour >= 22) {
                    reportInfo.timer.hour = (reportInfo.timer.hour - 12) + ':00 ' + 'P.M';
                } else if (reportInfo.timer.hour >= 10 && reportInfo.timer.hour <= 12) {
                    reportInfo.timer.hour = (reportInfo.timer.hour) + ':00 ' + 'A.M';
                } else {
                    reportInfo.timer.hour = '0' + (reportInfo.timer.hour)+ ':00 ' + 'A.M';
                }
                state.reportData = [reportInfo];
            }
        });

        builder.addCase(addSlot.rejected, (state, action) => {
            console.log('slot error');
        });

        // for update slot
        builder.addCase(updateSlot.pending, (state, action) => {
            console.log(' no update');
        });

        builder.addCase(updateSlot.fulfilled, (state, action) => {
            console.log('yess update ');
        });

        builder.addCase(updateSlot.rejected, (state, action) => {
            console.log('update done');
        });

        // for delete slot
        builder.addCase(deleteSlot.pending, (state, action) => {
            state.isLoading = true;
            state.isError = false;
        });

        builder.addCase(deleteSlot.fulfilled, (state, action) => {
            
            const filteredData = state.reportData.filter(
                (data) => data.id !== action.payload.data.slotID,
            );
            state.reportData = filteredData;
        });

        builder.addCase(deleteSlot.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
        });

        // for Report Duration (daily, weekly, monthly)
        builder.addCase(reportDuration.pending, (state, action) => {
            console.log(' Daily Report');
        });

        builder.addCase(reportDuration.fulfilled, (state, action) => {
            console.log('Weekly Report ');
        });

        builder.addCase(reportDuration.rejected, (state, action) => {
            console.log('Monthly Report ');
        });
    },
});

export default reportAutomationSlice.reducer;
