import React from "react";
import styles from "./AccessControl.module.css";
import Confirm from "./components/Confirm"
import { useState, useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { useSelector } from "react-redux";

const AccessControl = ({ roleList, rolePermissions, updatedPermissions, setUpdatedPermissions }) => {
  const [role, setRole] = useState("owner");
  const [permissionEdit, setPermissionEdit] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const userPermission = useSelector(state => state.organization.userPermission);

  useEffect(() => {

    const selectedPermissions = rolePermissions.find(
      (roleName) => Object.keys(roleName)[0] === role
    );


    if (selectedPermissions) {
      setUpdatedPermissions(selectedPermissions[role].permission);
    }
  }, [role])

  const handleRoleChange = (e) => {
    const selectedRole = e.target.value;
    setRole(selectedRole);
  };


  const handlePermissionChange = (e) => {
    setPermissionEdit(true);
    const { name, checked, dataset } = e.target;

    // Check if the permission being updated is part of projectAccess
    if   (dataset.projectid) {
      setUpdatedPermissions((prevPermissions) => ({
        ...prevPermissions,
        projectAccess: prevPermissions.projectAccess.map((project) =>
          project.projectId === dataset.projectid
            ? { ...project, allowed: checked }
            : project
        ),
      }));
    } else {
      setUpdatedPermissions((prevPermissions) => ({
        ...prevPermissions,
        [name]: checked,
      }));
    }
  };

  useEffect(() => {
    console.log(updatedPermissions)
  }, [updatedPermissions])

  return (
    <div className={styles.mainContainer}>
      <div className={styles.bodyContainer}>
        <div className={styles.body}>
          <div className={styles.bodyHeader}>
            <div>
              <h1 className={styles.heading1}>Role Permissions</h1>
            </div>
            <div className={styles.dropdown}>
              <div className={styles.dropdownText}>
                <h3>Role:</h3>
              </div>
              <select className={styles.roleName} onChange={handleRoleChange}>
                {roleList.map((role, key) => (
                  <option key={key}>{role}</option>
                ))}
              </select>
            </div>
          </div>
          <div className={styles.roleWrapper}>
            <div className={styles.input}>
              <div className={styles.alarms}>
                <hr />
                <div className={styles.permissions}>
                  View Alarm
                  <div className={styles.radio}>
                    <input
                      type="checkbox"
                      name="viewAlarm"
                      checked={updatedPermissions.viewAlarm}
                      onChange={handlePermissionChange}
                      className={styles.radioInput}
                    ></input>
                  </div>
                </div>
                <div className={styles.permissions}>
                  Update Alarm
                  <div className={styles.radio}>
                    <input
                      type="checkbox"
                      name="updateAlarm"
                      checked={updatedPermissions.updateAlarm}
                      onChange={handlePermissionChange}
                      className={styles.radioInput}
                    ></input>
                  </div>
                </div>
                <div className={styles.permissions}>
                  Delete Alarm
                  <div className={styles.radio}>
                    <input
                      type="checkbox"
                      name="deleteAlarm"
                      checked={updatedPermissions.deleteAlarm}
                      onChange={handlePermissionChange}
                      className={styles.radioInput}
                    ></input>
                  </div>
                </div>
                <div className={styles.permissions}>
                  Add Alarm
                  <div className={styles.radio}>
                    <input
                      type="checkbox"
                      name="addAlarm"
                      checked={updatedPermissions.addAlarm}
                      className={styles.radioInput}
                      onChange={handlePermissionChange}
                    ></input>
                  </div>
                </div>
                <div className={styles.permissions}>
                  Toggle Alarm
                  <div className={styles.radio}>
                    <input
                      type="checkbox"
                      name="toggleAlarm"
                      checked={updatedPermissions.toggleAlarm}
                      className={styles.radioInput}
                      onChange={handlePermissionChange}
                    ></input>
                  </div>
                </div>
              </div>
              <div className={styles.projects}>
                <hr />
                <div className={styles.permissions}>
                  Delete Project
                  <div className={styles.radio}>
                    <input
                      type="checkbox"
                      name="deleteProject"
                      checked={updatedPermissions.deleteProject}
                      className={styles.radioInput}
                      onChange={handlePermissionChange}
                    ></input>
                  </div>
                </div>
                <div className={styles.permissions}>
                  Transfer Project
                  <div className={styles.radio}>
                    <input
                      type="checkbox"
                      name="transferProject"
                      checked={updatedPermissions.transferProject}
                      className={styles.radioInput}
                      onChange={handlePermissionChange}
                    ></input>
                  </div>
                </div>
                <div className={styles.permissions}>
                  Suspend Project
                  <div className={styles.radio}>
                    <input
                      type="checkbox"
                      name="suspendProject"
                      checked={updatedPermissions.suspendProject}
                      className={styles.radioInput}
                      onChange={handlePermissionChange}
                    ></input>
                  </div>
                </div>
                <div className={styles.permissions}>
                  Project Data
                  <div className={styles.radio}>
                    <input
                      type="checkbox"
                      name="projectData"
                      checked={updatedPermissions.projectData}
                      className={styles.radioInput}
                      onChange={handlePermissionChange}
                    ></input>
                  </div>
                </div>
                <div className={styles.permissions}>
                  Update Project
                  <div className={styles.radio}>
                    <input
                      type="checkbox"
                      name="updateProject"
                      checked={updatedPermissions.updateProject}
                      className={styles.radioInput}
                      onChange={handlePermissionChange}
                    ></input>
                  </div>
                </div>
              </div>
              <div className={styles.permissionList}>
                <hr />
                <div className={styles.permissions}>
                  Update Permission
                  <div className={styles.radio}>
                    <input
                      type="checkbox"
                      name="updatePermissions"
                      checked={updatedPermissions.updatePermissions}
                      className={styles.radioInput}
                      onChange={handlePermissionChange}
                    ></input>
                  </div>
                </div>
              </div>
              <div className={styles.settings}>
                <hr />
                <div className={styles.permissions}>
                  Read Settings
                  <div className={styles.radio}>
                    <input
                      type="checkbox"
                      name="readSettings"
                      checked={updatedPermissions.readSettings}
                      className={styles.radioInput}
                      onChange={handlePermissionChange}
                    ></input>
                  </div>
                </div>
                <div className={styles.permissions}>
                  Edit Connection Settings
                  <div className={styles.radio}>
                    <input
                      type="checkbox"
                      name="editConnectionSettings"
                      checked={updatedPermissions.editConnectionSettings}
                      className={styles.radioInput}
                      onChange={handlePermissionChange}
                    ></input>
                  </div>
                </div>
                <div className={styles.permissions}>
                  Edit Device Settings
                  <div className={styles.radio}>
                    <input
                      type="checkbox"
                      name="editDeviceSettings"
                      checked={updatedPermissions.editDeviceSettings}
                      className={styles.radioInput}
                      onChange={handlePermissionChange}
                    ></input>
                  </div>
                </div>
              </div>

              <div className={styles.misc}>
                <hr />
                <div className={styles.permissions}>
                  Edit View
                  <div className={styles.radio}>
                    <input
                      type="checkbox"
                      name="editView"
                      checked={updatedPermissions.editView}
                      className={styles.radioInput}
                      onChange={handlePermissionChange}
                    ></input>
                  </div>
                </div>
                <div className={styles.permissions}>
                  Export Report
                  <div className={styles.radio}>
                    <input
                      type="checkbox"
                      name="exportReport"
                      checked={updatedPermissions.exportReport}
                      className={styles.radioInput}
                      onChange={handlePermissionChange}
                    ></input>
                  </div>
                </div>
                <div className={styles.permissions}>
                  Virtual Channel
                  <div className={styles.radio}>
                    <input
                      type="checkbox"
                      name="virtualChannel"
                      checked={updatedPermissions.virtualChannel}
                      className={styles.radioInput}
                      onChange={handlePermissionChange}
                    ></input>
                  </div>
                </div>

                <div className={styles.permissions}>
                  Manage Organization
                  <div className={styles.radio}>
                    <input
                      type="checkbox"
                      name="manageOrganization"
                      checked={updatedPermissions.manageOrganization}
                      className={styles.radioInput}
                      onChange={handlePermissionChange}
                    ></input>
                  </div>
                </div>

                <div className={styles.permissions}>
                  Write Feedback
                  <div className={styles.radio}>
                    <input
                      type="checkbox"
                      name="writeFeedback"
                      checked={updatedPermissions.writeFeedback}
                      className={styles.radioInput}
                      onChange={handlePermissionChange}
                    ></input>
                  </div>
                </div>

                <div className={styles.permissions}>
                  Danger Zone
                  <div className={styles.radio}>
                    <input
                      type="checkbox"
                      name="dangerZone"
                      checked={updatedPermissions.dangerZone}
                      className={styles.radioInput}
                      onChange={handlePermissionChange}
                    ></input>
                  </div>
                </div>
                <div className={styles.permissions}>
                  Billing Info
                  <div className={styles.radio}>
                    <input
                      type="checkbox"
                      name="billingInfo"
                      checked={updatedPermissions.billingInfo}
                      className={styles.radioInput}
                      onChange={handlePermissionChange}
                    ></input>
                  </div>
                </div>
                <hr />
                {updatedPermissions?.projectAccess?.map((project, index) => (
                  <div key={index} className={styles.permissions}>
                    {project?.projectName}
                    <br />
                    {project?.projectId}
                    <div className={styles.radio}>
                      <input
                        type="checkbox"
                        name="projectAccess"
                        checked={project?.allowed}
                        className={styles.radioInput}
                        data-projectid={project?.projectId}
                        onChange={handlePermissionChange}
                      ></input>
                    </div>
                  </div>
                ))}

                <div className={styles.btnDiv}>
                  <button className={styles.blueBtn} onClick={() => setConfirmModal(true)}>Confirm</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {confirmModal && (
        <Confirm
          setConfirmModal={setConfirmModal}
          setConfirmPassword={setConfirmPassword}
          permissionEdit={permissionEdit}
          role={role}
          updatedPermissions={updatedPermissions}
        />
      )}
    </div>
  );
};

export default AccessControl;
