import React, { useState } from "react";
import NotificationCard from "./components/NotificationCard";
import SettingsModel from "./components/SettingsModel";
import HamburgerModel from "./components/HamburgerModel";
import search from "../../assets/search.svg";
import settings from "../../assets/Settings.svg";
import Hamburger from "../../assets/Hamburger button.svg";
import styles from "./Notification.module.css";

const Notification = ({setPage, setPageTitle}) => {

  setPage(`notification`);
  setPageTitle(`Notification`);

  const [selectedOption, setSelectedOption] = useState("");
  const [isSettingModel, setIsSettingModel] = useState(false);
  const [isHamburgerModal, setIsHamburgerModal] = useState(false);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission here, if needed
  };

  return (
    <div className={styles.superWrapper}>
      <div className={styles.wrapper1}>
        <div className={styles.mainDiv}>
          <div className={styles.mainContainer}>
            <div className={styles.upperPart}>
              <div className={styles.dropDown}>
                <form className={styles.form} onSubmit={handleSubmit}>
                  <label htmlFor="notificationDropdown"></label>
                  <select
                    className={styles.select}
                    id="notificationDropdown"
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="">Select</option>
                    <option value="option1">All</option>
                    <option value="option2">None</option>
                    <option value="option3">Read</option>
                    <option value="option3">Unread</option>
                  </select>
                  <button
                    type="submit"
                    className={styles.submitButton}
                  ></button>
                </form>
              </div>
              <div className={styles.searchSetting}>
                <button className={styles.searchImg}>
                  <img src={search} alt="" />
                </button>

                <input
                  type="text"
                  className={styles.search}
                  placeholder="search"
                />

                <button className={styles.hamBurgerBtn}>
                  <img src={Hamburger} alt="" className={styles.hamBurgerImg} onClick={() => setIsHamburgerModal(true)} />
                </button>

                <button className={styles.settingBtn}>
                  <img
                    src={settings}
                    alt=""
                    className={styles.settingImg}
                    onClick={() => setIsSettingModel(true)}
                  />
                </button>
              </div>
            </div>
            <div className={styles.allButtons}>
              <button className={styles.all}>Recents</button>
              <button className={styles.all}>Today</button>
              <button className={styles.all}>Yesterday</button>
              <button className={styles.all}>week</button>
              <button className={styles.all}>Month</button>
            </div>
            <div className={styles.wrapper2}>
              <NotificationCard />
            </div>
          </div>
        </div>
      </div>
      {isSettingModel && (
        <SettingsModel setIsSettingModel={setIsSettingModel} />
      )}

      {isHamburgerModal && (
         <HamburgerModel setIsHamburgerModal={setIsHamburgerModal}/>
      )}
    </div>
  );
};

export default Notification;
