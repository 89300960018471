import React from "react";
import styles from "./AuditTrail.module.css";
import AuditCollection from "./components/AuditCollection";
import { useSelector } from "react-redux";

const AuditTrail = ({ setPage, setPageTitle }) => {
  setPage("auditTrail");
  setPageTitle("Audit Trail");
  
  const userPermission = useSelector(state => state.organization.userPermission);
  return (
    <div className={styles.alarmLogs}>
      <div className={styles.wrapper}>
        <div className={styles.bodywrapper}>
          <AuditCollection />
        </div>
      </div>
    </div>
  );
};

export default AuditTrail;
