import React, { useState } from "react";
import styles from "./AuditData.module.css";

const AuditData = ({ data }) => {
  const [isExpanded, setisExpanded] = useState(false);

  const date = new Date(data.timestamp);
  const cardDate = date.getDate();
  const month = date.getMonth()+1;
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    timeZone: "UTC",
  };

  const formattedDate = `${cardDate}/${month}/${year}`

  const toggleExpand = () => {
    setisExpanded(!isExpanded);
  };

  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <div className={styles.bodyContainer}>
          <div className={styles.valueWrapper}>
            <div className={styles.part1}>
              <div className={styles.subpart}>
                <div className={styles.cardheader}>Action:</div>
                <div className={styles.cardBody}>{data.action}</div>
              </div>
              <div className={styles.subpart}>
                <div className={styles.cardheader}>Role:</div>
                <div className={styles.cardBody}>{data.role}</div>
              </div>
              <div className={styles.subpart}>
                <div className={styles.cardheader}>Email:</div>
                <div className={styles.cardBody}>{data.email}</div>
              </div>
              <div className={styles.subpart}>
                <div className={styles.cardheader}>Timestamp:</div>
                <div className={styles.cardBody}>
                  {formattedDate} {hours}:{minutes}:{seconds}
                </div>
              </div>
            </div>
            <div className={styles.part2}>
              <div className={styles.cardheader}>Details:</div>
              <div className={styles.cardBody} onClick={toggleExpand}>
                {isExpanded ? data.details : `${data?.details?.slice(0, 100)}...`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuditData;
