import React from "react";
import styles from "./ForgotPass.module.css";
import dicotLogo from "assets/Logo.svg";
import ForgotPassForm from "./ForgotPassForm.jsx";

function App({ setPageTitle }) {
  React.useLayoutEffect(() => {
    document.title = "Forgot password";
  }, []);
  return (
    <div className={styles.mainDiv}>
      <div className={styles.mainLogo}>
        <img className={styles.logoImage} src={dicotLogo} alt="DicotLogo" />
      </div>
      <ForgotPassForm />
    </div>
  );
}

export default App;
