import { configureStore } from "@reduxjs/toolkit";
import accountInfoReducer from "./slices/accountInfoSlice";
import projectDataReducer from "./slices/projectSlice";
import alarmDataReducer from "./slices/alarmSlice";
import alarmLogReducer from "./slices/alarmLogSlice";
import projectSettingReducer from "./slices/projectSetting";
import dangerZoneReducer from "./slices/dangerZoneSlice";
import viewDataReducer from "./slices/viewDataSlice";
import auditTrailsReducer from "./slices/auditTrailsSlice";
import profileReducer from "./slices/profileSlice";
import billingReducer from "./slices/billingSlice";
import derivedChannelReducer from "./slices/derivedChannelSlice";
import organizationReducer from "./slices/organizationSlice";
import reportAutomationReducer from "./slices/reportAutomationSlice";
import batchReducer from "./slices/batchSlice";

export const store = configureStore({
  reducer: {
    accountInfo: accountInfoReducer,
    projectData: projectDataReducer,
    alarmData: alarmDataReducer,
    alarmLogs :  alarmLogReducer,
    projectSetting : projectSettingReducer,
    dangerZone : dangerZoneReducer,
    viewData : viewDataReducer,
    auditTrails : auditTrailsReducer,
    profile : profileReducer,
    billing : billingReducer,
    organization : organizationReducer,
    derivedChannel : derivedChannelReducer,
    reportAutomation : reportAutomationReducer,
    batch : batchReducer
  },
});
