import React, { useEffect, useState } from "react";
import ProfileInfo from "./components/ProfileInfo";
import styles from "./UserProfile.module.css";
import PersonalInfo from "./components/ProfileCards";
import proxy from "../../proxy";
import { useNavigate } from "react-router-dom";
import ModelProfile from "./components/Others/UpdateProfile";
import DeleteModelProfile from "./components/Others/DeleteConfirmationModel";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile, updateProfile } from "../../redux/slices/profileSlice";

const UserProfile = ({ setPageTitle, setPage}) => {

  
  setPageTitle("profile");
  setPage("profile");
  const navigate=useNavigate()
  const [data, setData] = useState({});
  const [updateProfileInfo, setUpdateProfileInfo] = useState({});
  const [openModel, setOpenModel] = useState(false);
  const [inputValues, setInputValues] = useState();
  const [selectedImg, setSelectedImg] = useState();
  const [deleteModel, setDeleteModel] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token");
    dispatch(getUserProfile(token));
  }, []);

  useEffect(() => {
    if (inputValues) {
      const token = localStorage.getItem("token");
      const valuesToUpdate = inputValues;
      valuesToUpdate["image"] =
        selectedImg || localStorage.getItem("selectedImageURL");

        setUpdateProfileInfo(valuesToUpdate);
    }
  }, [inputValues, data]);

  if (!localStorage.getItem("token")) {
    navigate("auth/login");
  }

  return (
    <>
      <div className={styles.profile}>
        <div className={styles.wrapper}>
          <div className={styles.information}>
            <div className={styles.profileHero}>
              <ProfileInfo
                data={data}
                setData={setData}
                setOpenModel={setOpenModel}
                selectedImg={selectedImg}
                setSelectedImg={setSelectedImg}
              />
            </div>
            <div className={styles.profileCards}>
              <PersonalInfo data={data} setModel={setDeleteModel} />
            </div>
          </div>
        </div>
      </div>

      {openModel && (
        <ModelProfile
          setOpenModel={setOpenModel}
          setInputValues={setInputValues}
          data={data}
          updateProfileInfo={updateProfileInfo}
        />
      )}

      {deleteModel && <DeleteModelProfile setDeleteModel={setDeleteModel} setOpenModel={setOpenModel} type="delete" header="Are you sure to delete your account?" />}
    </>
  );
};

export default UserProfile;
