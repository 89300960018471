// local proxy
// const proxy = "http://192.168.1.106:8075"


// const proxy = "http://10.17.12.6:8080" //Jalish  
// const proxy = "http://localhost:8080" //Rishabh

// global proxy
// const proxy = "http://vision-web.tech"
const proxy = "https://api.vision-web.tech"



module.exports = proxy;
