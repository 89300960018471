import React from "react";
import styles from "./CardData.module.css";

const Card = (props) => {
    return (
        <div className={styles.cardContainer}>
            <div className={styles.cardTitleWrapper}>

            <div className={styles.cardTitle}>
                {props.cardTitle}
            </div>
            <img src={props.icon} alt="" />
            </div>
            <div className={styles.cardData}>
                <div className={styles.amount}>
                    {props.amount}
                </div>
                <div className={styles.unit}>
                    {props.unit}
                </div>
            </div>
        </div>
    );
};

export default Card;