import React from "react";
import styles from "./Channel.module.css";

const Channel = ({ data }) => {
  return (
    <div className={styles.channel}>
      <div className={styles.channelWrapper}>
        <div className={styles.channelTop}>
          <div className={styles.field}>{data.Header}</div>
          <div className={styles.property}>{data.val}</div>
        </div>
        <div className={styles.dateTime}>Last Updated: {data.time}</div>
      </div>
    </div>
  );
};

export default Channel;
