import React, { useEffect, useState } from "react";
import styles from "./ProgressBar.module.css";

const ProgressBar = ({
  dateTimesLive,
  dataArrLive,
  chartChannel,
  minVal,
  maxVal,
  rotate,
}) => {
  const [currentValue, setCurrentValue] = useState();
  const [percentage, setPercentage] = useState();
  const [isRotate, setIsRotate] = useState();

  useEffect(() => {
    setIsRotate(rotate);
    const matchedDatasets =
      chartChannel.length > 0
        ? chartChannel?.map((label) =>
            dataArrLive?.find((dataset) => dataset.label === label)
          )
        : [];
      
    const currentVal =
      matchedDatasets.length > 0 ? matchedDatasets[0]?.data.slice(-1) : [1];
    if (currentVal) {
      const newPercentage = (currentVal[0] / maxVal) * 100;
      setCurrentValue(currentVal[0]);
      setPercentage(newPercentage);
    }
  }, [dateTimesLive, chartChannel, minVal, maxVal, rotate]);

  return (
    <div
      className={styles.progressBar}
      style={{
        transform: isRotate ? "rotate(-90deg)" : null,
        transition : 'transform 0.4s linear' 
      }}
    >
      <div
        className={styles.minValue}
        style={{
          transform: isRotate ? "rotate(90deg)" : null,
        }}
      >
        {minVal}
      </div>
      <div
        className={styles.valSection}
        style={{ gap: isRotate ? "0.3rem" : null }}
      >
        <div
          className={styles.currentValue}
          style={{
            transform: isRotate ? "rotate(90deg)" : null,
            padding: rotate ? "0.5rem" : null,
          }}
        >
          {currentValue}
        </div>
        <div
          className={styles.emptyProgressBar}
          style={{
            width: "100%",
          }}
        >
          <div
            className={styles.fillingProgressBar}
            style={{
              left: percentage - 100 + "%",
              transition: "2s",
            }}
          ></div>
        </div>
      </div>
      <div
        className={styles.maxValue}
        style={{
          transform: isRotate ? "rotate(90deg)" : null,
          padding: isRotate ? "0rem 0.5rem 0rem 0rem" : null,
        }}
      >
        {maxVal}
      </div>
    </div>
  );
};

const NewProgressBar = ({
  dateTimesLive,
  dataArrLive,
  chartChannel,
  minVal,
  maxVal,
  chartIndex,
  rotateBar
}) => {
  const [currentValue, setCurrentValue] = useState();
  const [percentage, setPercentage] = useState();

  useEffect(() => {
    const matchedDatasets =
      chartChannel.length === 0
        ? null
        : dataArrLive?.find(
            (dataset) => dataset.label === chartChannel[0]
          );

    const currentVal = matchedDatasets
      ? matchedDatasets?.data.slice(-1)
      : [100];
    const newPercentage = (currentVal[0] / maxVal) * 100;

    setCurrentValue(currentVal[0]);
    setPercentage(newPercentage);
  }, [dateTimesLive, chartChannel, minVal, maxVal, percentage]);

  return (
    <div className={styles.progressBar}  style={{
      transform: rotateBar ? "rotate(-90deg)" : null,
    }}>
      <div className={styles.minValue}     style={{
          transform: rotateBar ? "rotate(90deg)" : null,
        }}>{minVal ? minVal : 0}</div>

      <div className={styles.valSection}
         style={{ gap: rotateBar ? "0.3rem" : null }}
      >
        <div className={styles.currentValue}
                  style={{
                    transform: rotateBar ? "rotate(90deg)" : null,
                    padding: rotateBar ? "0.5rem" : null,
                  }}
        >{currentValue}</div>
        <div className={styles.emptyProgressBar} style={{ width: "100%" }}>
          <div
            className={styles.fillingProgressBar}
            style={{
              left: percentage - 100 + "%",
              transition: "2s",
            }}
          ></div>
        </div>
      </div>
      <div className={styles.maxValue}
          style={{
            transform: rotateBar ? "rotate(90deg)" : null,
            padding: rotateBar ? "0rem 0.5rem 0rem 0rem" : null,
          }}
      >
        {maxVal ? maxVal : 100}
      </div>
    </div>
  );
};

export { ProgressBar, NewProgressBar };
