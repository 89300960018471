import React, { useState } from 'react';
import styles from "./AdminSignup.module.css";
import dicotLogo from "../../../assets/Logo.svg"
import proxy from '../../../proxy';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import openEye from "../../../assets/openEye.png";
import closeEye from "../../../assets/closeEye.png";

const AdminSignup = () => {

    const toast = useToast();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const navigate = useNavigate();

    const togglePasswordVisibility = (e) => {
        e.preventDefault();
        const pwInput = document.getElementById("pwd");
        pwInput.type = isPasswordVisible ? "password" : "text";
        setIsPasswordVisible(!isPasswordVisible);
    };

    const handleSignup = async (e) => {
        console.log(e);
        e.preventDefault();

        const response = await fetch(`${proxy}/admin/signup`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                firstName: firstName,
                lastName: lastName,
                email: email,
                password: password,
                role: "admin"
            })
        });
        const status = response.status;
        const responseBody = await response.json();
        if (status === 201) {
            toast({
                title: responseBody.message || "Signup Successfully",
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
            })
            navigate("/admin/signin")
        } else {
            toast({
                title: responseBody.message || "Signup Unsuccessfull",
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
            })
        }

    }


    return (
        <div className={styles.page}>
            <img src={dicotLogo} alt="" srcset="" className={styles.logo} />
            <div className={styles.container}>
                <h1 className={styles.titleText}>Register</h1>
                <input type="text" placeholder='First Name' className={styles.input} onChange={(e) => setFirstName(e.target.value)} />
                <input type="text" placeholder='Last Name' className={styles.input} onChange={(e) => setLastName(e.target.value)} />
                <input type="text" placeholder='Email' className={styles.input} onChange={(e) => setEmail(e.target.value)} />

                <div className={styles.passwordSection}>

                    <input type="password" placeholder='Password' className={styles.input} onChange={(e) => setPassword(e.target.value)} id='pwd' />
                    <button
                        className={styles.pwVisibilityToggle}
                        onClick={togglePasswordVisibility}
                    >
                        {!isPasswordVisible ? (
                            <img src={closeEye} alt="Close Eye" />
                        ) : (
                            <img src={openEye} alt="Open Eye" />
                        )}
                    </button>
                </div>
                <button className={styles.btn} onClick={(e) => handleSignup(e)}>Register</button>
            </div>
        </div>
    )
}

export default AdminSignup