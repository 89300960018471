import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import proxy from 'proxy';

const initialState = {
  batchData: [],
};

export const getBatches = createAsyncThunk(
  'getBatches',
  async (uniqueProjectId, token) => {
    const response = await fetch(
      `${proxy}/batch/getBatches?projectid=${uniqueProjectId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );

    const responseBody = await response.json();
    return responseBody;
  },
);

export const addBatch = createAsyncThunk('addBatch', async (data) => {
  const response = await fetch(`${proxy}/batch/createBatch`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${data.token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  const responseBody = await response.json();
  return { responseBody, data };
});

export const deleteBatch = createAsyncThunk('deleteBatch', async (data) => {
  const response = await fetch(`${proxy}/batch/deleteBatch`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${data.token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  const responseBody = await response.json();
  return { responseBody, data };
});

export const endBatch = createAsyncThunk('endBatch', async (data) => {
  const response = await fetch(`${proxy}/batch/endBatch`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const status = response.status;
  const responseBody = await response.json();
  return { responseBody, data, status };
});

export const updateBatch = createAsyncThunk('updateBatch', async (data) => {
  const response = await fetch(`${proxy}/batch/editBatch`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  //   const status = response.status;
  const responseBody = await response.json();
  return { responseBody, data };
});

const batchSlice = createSlice({
  name: 'batch',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getBatches.fulfilled, (state, action) => {
      if (action.payload.status === 200) {
        state.batchData = action.payload.data;
      }
    });

    builder.addCase(addBatch.fulfilled, (state, action) => {
      if (action.payload.responseBody.status === 200) {
        const newBatch = {
          batchID: action.payload.responseBody.batchID,
          batchName: action.payload.data.batchName,
          channelName: action.payload.data.channelName,
          status: action.payload.data.status,
          startTime: action.payload.responseBody.startTime,
        };
        if (state.batchData !== undefined) {
          state.batchData = [...state.batchData, newBatch];
        } else {
          state.batchData = [newBatch];
        }
      }
    });

    builder.addCase(deleteBatch.fulfilled, (state, action) => {
      if (action.payload.responseBody.status === 200) {
        const filteredBatches = state.batchData.filter(
          (batch) => batch.batchID !== action.payload.data.batchID,
        );
        state.batchData = filteredBatches;
      }
    });

    builder.addCase(endBatch.fulfilled, (state, action) => {
      if (action.payload.status === 200) {
        const copiedBatches = [...state.batchData];

        const batchToUpdate = copiedBatches.findIndex(
          (batch) => batch.batchID === action.payload.data.batchID,
        );

        copiedBatches[batchToUpdate].status =
          !copiedBatches[batchToUpdate].status;
        copiedBatches[batchToUpdate].endTime =
          action.payload.responseBody.endTime;

        state.batchData = copiedBatches;
        console.log(state.batchData);
      }
    });

    builder.addCase(updateBatch.fulfilled, (state, action) => {
      const updatedBatchIndex = state.batchData.findIndex(
        (batch) => batch.batchID === action.payload.data.batchID,
      );

      if (action.payload.responseBody.status === 200) {
        if (updatedBatchIndex !== -1) {
          state.batchData[updatedBatchIndex] = action.payload.data;
        }
      }
    });
  },
});

export default batchSlice.reducer;
