import React from "react";
import styles from "./OverviewInfo.module.css";

const OverviewTable = ({ project }) => {
  return (
      <div className={styles.overview_section}>
        <div className={styles.heading}>
          {Object.keys(project).length > 0 ? project.projectName : "Loading..."}
        </div>
        <div className={styles.content}>
          <div className={styles.status}>
            <p className={styles.statusPara}>Status</p> :{" "}
            {Object.keys(project).length > 0
              ? project.projectOnlineStatus
                ? "Connected"
                : "Not Connected"
              : "Loading..."}
          </div>
          <div className={styles.desc}>
            <p className={styles.descPara}>Description</p> :{" "}
            {Object.keys(project).length > 0 ? project.desc : "Loading..."}
          </div>
        </div>
      </div>
  );
};

export default OverviewTable;
