import React, { useRef, useState } from "react";
import styles from "./ToolBar.module.css";
import Draggble from "react-draggable";
import { useToast } from "@chakra-ui/react";
import motor from "../../../../../assets/motor.png";

const ToolBar = ({
  setDisplayImage,
  setMultipleImages,
  multipleImages,
  activeTabIndex,
  setStoreTabIndexes,
  storeTabIndexes,
  setMultipleDevices,
  multipleDevices,
  setTabIndexForDevices,
  tabIndexForDevice,
  setMultipleController,
  multipleController,
  setMultipleIO,
  multipleIO,
  setMultipleFlowM,
  multipleFlowM,
  multipleEnergyM,
  setMultipleEnergyM,
  setMultipleLineCharts,
  multipleLineCharts,
  multiplePieChart,
  setMultiplePieChart,
  setMultipleBarChart,
  multipleBarChart,
  multipleHistogram,
  setMultipleHistogram,
  setMultipleGauge,
  multipleGauge,
  multipleProgress,
  setMultipleProgress,
  multipleGrid,
  setMultipleGrid,
  setMultipleTable,
  multipleTable,
  updatedMultipleDevices,
  setUpdatedMultipleDevices,
  updatedMultipleController,
  setUpdatedMultipleController,
  updatedMultipleIO,
  setUpdatedMultipleIO,
  updatedMultipleFLowM,
  setUpdatedMultipleFlowM,
  updatedMultipleEnergyM,
  setUpdatedMultipleEnergyM,
  updatedMultipleLineChart,
  setUpdatedMultipleLineChart,
  updatedMultiplePieChart,
  setUpdatedMultiplePieChart,
  updatedMultipleBarChart,
  setUpdatedMultipleBarChart,
  updatedMultipleHistogram,
  setUpdatedMultipleHistogram,
  updatedMultipleGauge,
  setUpdatedMultipleGauge,
  updatedMultipleProgress,
  setUpdatedMultipleProgress,
  updatedMultipleGrid,
  setUpdatedMultipleGrid,
  updatedMultipleTable,
  setUpdatedMultipleTable,
  multipleMotor,
  setMultipleMotor,
  updatedMultipleMotor,
  setUpdatedMultipleMotor,
  isMotorOptions,
  setIsMotorOptions,
  isDeviceOption,
  setIsDeviceOption,
  multipleDisplay,
  setMultipleDisplay,
  updatedMultipleDisplay,
  setUpdatedMultipleDisplay,
  setMultipleText,
  multipleText,
  setUpdatedMultipleText,
  updatedMultipleText

}) => {
  const fileInputRef = useRef(null);
  const toast = useToast();
  const [deviceOptionPosi, setDeviceOptionPosi] = useState({ x: 0, y: 0 });

  const openFileDialog = () => {
    if (activeTabIndex > 3) {
      fileInputRef.current.click();
    }
  };

  const HandleImageChange = (e) => {
    if (activeTabIndex > 3) {
      const file = e.target.files[0];
      if (file) {

        const reader = new FileReader();

        reader.onload = () => {
          // const imageUrl = URL.createObjectURL(file);
          const base64Image = reader.result;
          setDisplayImage(base64Image);
          setMultipleImages(multipleImages.concat(base64Image));
          setStoreTabIndexes(storeTabIndexes.concat(activeTabIndex));
        };

        reader.readAsDataURL(file);
      }
    }
  };

  const handleDisplayDeviceOptions = (e) => {

    setIsDeviceOption(true);
    setDeviceOptionPosi(
      {
        x: e.clientX.toString(),
        y: e.clientY.toString()
      }
    )

  }

  const handleDisplay = () => {
    const display = document.getElementById("display").innerHTML;
    const deviceType = "Display";

    if (activeTabIndex > 3) {
      setMultipleDisplay(
        multipleDisplay.concat({ deviceName: display, type: deviceType, tabIndex: activeTabIndex })
      );
      setUpdatedMultipleDisplay(
        updatedMultipleDisplay.concat({ deviceName: display, type: deviceType, tabIndex: activeTabIndex })
      );
      setTabIndexForDevices(tabIndexForDevice.concat(activeTabIndex));
    }

  }

  const handleIndicator = () => {

    const indicator = document.getElementById("indicator").innerHTML;
    const deviceType = "Indicator";
    if (activeTabIndex > 3) {

      setMultipleDevices(
        multipleDevices.concat({ deviceName: indicator, type: deviceType, tabIndex: activeTabIndex })
      );
      setUpdatedMultipleDevices(
        updatedMultipleDevices.concat({ deviceName: indicator, type: deviceType, tabIndex: activeTabIndex })
      );
      setTabIndexForDevices(tabIndexForDevice.concat(activeTabIndex));
    }
    else {
      toast({
        title: "You cannot add device/chart in this tab.",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleController = () => {
    const controller = document.getElementById("controller").innerHTML;
    const deviceType = "Controller";
    if (activeTabIndex > 3) {
      setMultipleController(
        multipleController.concat({ deviceName: controller, type: deviceType, tabIndex: activeTabIndex })
      );
      setUpdatedMultipleController(
        updatedMultipleController.concat({ deviceName: controller, type: deviceType, tabIndex: activeTabIndex })
      );
      setTabIndexForDevices(tabIndexForDevice.concat(activeTabIndex));
    } else {
      toast({
        title: "You cannot add device/chart in this tab.",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDigitalIO = () => {

    const digitalIO = document.getElementById("digitalIO").innerHTML;
    const deviceType = "DigitalIO";
    if (activeTabIndex > 3) {
      setMultipleIO(
        multipleIO.concat({ deviceName: digitalIO, type: deviceType, tabIndex: activeTabIndex })
      );

      setUpdatedMultipleIO(
        updatedMultipleIO.concat({ deviceName: digitalIO, type: deviceType, tabIndex: activeTabIndex })
      );

      setTabIndexForDevices(tabIndexForDevice.concat(activeTabIndex));
    }
    else {
      toast({
        title: "You cannot add device/chart in this tab.",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsMotorOptions(false);
  };

  const handleFlowMeter = () => {
    const flowMeter = document.getElementById("flowMeter").innerHTML;
    const deviceType = "FlowMeter";
    if (activeTabIndex > 3) {
      setMultipleFlowM(
        multipleFlowM.concat({ deviceName: flowMeter, type: deviceType, tabIndex: activeTabIndex })
      );
      setUpdatedMultipleFlowM(
        updatedMultipleFLowM.concat({ deviceName: flowMeter, type: deviceType, tabIndex: activeTabIndex })
      );
      setTabIndexForDevices(tabIndexForDevice.concat(activeTabIndex));
    }
    else {
      toast({
        title: "You cannot add device/chart in this tab.",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEnergyMeter = () => {
    const EnergyMeter = document.getElementById("energyMeter").innerHTML;
    const deviceType = "EnergyMeter";
    if (activeTabIndex > 3) {
      setMultipleEnergyM(
        multipleEnergyM.concat({ deviceName: EnergyMeter, type: deviceType, tabIndex: activeTabIndex })
      );
      setUpdatedMultipleEnergyM(
        updatedMultipleEnergyM.concat({ deviceName: EnergyMeter, type: deviceType, tabIndex: activeTabIndex })
      );
      setTabIndexForDevices(tabIndexForDevice.concat(activeTabIndex));
    }
    else {
      toast({
        title: "You cannot add device/chart in this tab.",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleMotor = () => {
    const motorName = document.getElementById("motor").innerHTML;
    const deviceType = "Motor";
    if (activeTabIndex > 3) {

      setMultipleMotor(
        multipleMotor.concat({ deviceName: motorName, type: deviceType, tabIndex: activeTabIndex, image: motor })
      );

      setUpdatedMultipleMotor(
        updatedMultipleMotor.concat({ deviceName: motorName, type: deviceType, tabIndex: activeTabIndex, image: motor })
      );

      setTabIndexForDevices(tabIndexForDevice.concat(activeTabIndex));
      setIsMotorOptions(false);

    }

    else {
      toast({
        title: "You cannot add device/chart in this tab.",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  }

  const handleText = () => {
    const textName = document.getElementById("text").innerHTML;
    const deviceType = "Text";
    if (activeTabIndex > 3) {
      setMultipleText(
        multipleText.concat({ deviceName: textName, type: deviceType, tabIndex: activeTabIndex, text: "Text" })
      );

      setUpdatedMultipleText(
        updatedMultipleText.concat({ deviceName: textName, type: deviceType, tabIndex: activeTabIndex, text: "Text" })
      );

      setTabIndexForDevices(tabIndexForDevice.concat(activeTabIndex));
      setIsMotorOptions(false);
    }

    else {
      toast({
        title: "You cannot add device/chart in this tab.",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  }

  const handleLineChart = () => {
    const chartName = "LineChart";
    const deviceType = "LineChart";
    if (activeTabIndex > 3) {
      setMultipleLineCharts(
        multipleLineCharts.concat({ deviceName: chartName, type: deviceType, tabIndex: activeTabIndex })
      );
      setUpdatedMultipleLineChart(
        updatedMultipleLineChart.concat({ deviceName: chartName, type: deviceType, tabIndex: activeTabIndex })
      );
      setTabIndexForDevices(tabIndexForDevice.concat(activeTabIndex));
    } else {
      toast({
        title: "You cannot add device/chart in this tab.",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handlePieChart = () => {
    const chartName = "PieChart";
    const deviceType = "PieChart";
    if (activeTabIndex > 3) {
      setMultiplePieChart(
        multiplePieChart.concat({ deviceName: chartName, type: deviceType, tabIndex: activeTabIndex, x: 0, y: 0 })
      );

      setUpdatedMultiplePieChart(
        updatedMultiplePieChart.concat({ deviceName: chartName, type: deviceType, tabIndex: activeTabIndex, x: 0, y: 0 })
      );

      setTabIndexForDevices(tabIndexForDevice.concat(activeTabIndex));
    }

    else {
      toast({
        title: "You cannot add device/chart in this tab.",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleBarChart = () => {
    const chartName = "BarChart";
    const deviceType = "BarChart";

    if (activeTabIndex > 3) {
      setMultipleBarChart(
        multipleBarChart.concat({ deviceName: chartName, type: deviceType, tabIndex: activeTabIndex })
      );

      setUpdatedMultipleBarChart(
        updatedMultipleBarChart.concat({ deviceName: chartName, type: deviceType, tabIndex: activeTabIndex })
      );
      setTabIndexForDevices(tabIndexForDevice.concat(activeTabIndex));
    }

    else {
      toast({
        title: "You cannot add device/chart in this tab.",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleHistogram = () => {
    const chartName = "Histogram";
    const deviceType = "Histogram";

    if (activeTabIndex > 3) {
      setMultipleHistogram(
        multipleHistogram.concat({ deviceName: chartName, type: deviceType, tabIndex: activeTabIndex })
      );

      setUpdatedMultipleHistogram(
        updatedMultipleHistogram.concat({ deviceName: chartName, type: deviceType, tabIndex: activeTabIndex })
      );
      setTabIndexForDevices(tabIndexForDevice.concat(activeTabIndex));
    }

    else {
      toast({
        title: "You cannot add device/chart in this tab.",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleGauge = () => {
    const gaugeName = "Gauge";
    const deviceType = "Gauge";
    if (activeTabIndex > 3) {
      setMultipleGauge(
        multipleGauge.concat({ deviceName: gaugeName, type: deviceType, tabIndex: activeTabIndex })
      );

      setUpdatedMultipleGauge(
        updatedMultipleGauge.concat({ deviceName: gaugeName, type: deviceType, tabIndex: activeTabIndex })
      );

      setTabIndexForDevices(tabIndexForDevice.concat(activeTabIndex));
    }
    else {
      toast({
        title: "You cannot add device/chart in this tab.",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleProgressBar = () => {
    const progreesBarName = "ProgressBar";
    const deviceType = "ProgressBar";
    if (activeTabIndex > 3) {
      setMultipleProgress(
        multipleProgress.concat({
          deviceName: progreesBarName,
          type: deviceType,
          tabIndex: activeTabIndex
        })
      );

      setUpdatedMultipleProgress(
        updatedMultipleProgress.concat({
          deviceName: progreesBarName,
          type: deviceType,
          tabIndex: activeTabIndex
        })
      );

      setTabIndexForDevices(tabIndexForDevice.concat(activeTabIndex));
    }
    else {
      toast({
        title: "You cannot add device/chart in this tab.",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleGridView = () => {
    const gridName = "Grid";
    const deviceType = "Grid";
    if (activeTabIndex > 3) {
      setMultipleGrid(
        multipleGrid.concat({
          deviceName: gridName,
          type: deviceType,
          tabIndex: activeTabIndex
        })
      );

      setUpdatedMultipleGrid(
        updatedMultipleGrid.concat({
          deviceName: gridName,
          type: deviceType,
          tabIndex: activeTabIndex
        })
      );
      setTabIndexForDevices(tabIndexForDevice.concat(activeTabIndex));
    }
    else {
      toast({
        title: "You cannot add device/chart in this tab.",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleTable = () => {
    const tableName = "Table";
    const deviceType = "Table";
    if (activeTabIndex > 3) {
      setMultipleTable(
        multipleTable.concat({
          deviceName: tableName,
          type: deviceType,
          tabIndex: activeTabIndex
        })
      );
      setUpdatedMultipleTable(
        updatedMultipleTable.concat({
          deviceName: tableName,
          type: deviceType,
          tabIndex: activeTabIndex
        })
      );
      setTabIndexForDevices(tabIndexForDevice.concat(activeTabIndex));
    }
    else {
      toast({
        title: "You cannot add device/chart in this tab.",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Draggble>
        <div className={styles.toolBar} onContextMenu={(e) => e.preventDefault()} >
          <div className={styles.container}>
            <div className={styles.section}>
              <button className={styles.ImgSectionName} onClick={openFileDialog}>
                Images
              </button>
              <input
                type="file"
                accept=".jpg,.png,.gif,.svg"
                ref={fileInputRef}
                onChange={HandleImageChange}
                style={{ display: "none" }}
              />
            </div>
            <div className={styles.section}>
              <button className={styles.sectionName} onClick={(e) => handleDisplayDeviceOptions(e)}>Devices</button>
            </div>
            <div className={styles.section}>
              <div className={styles.sectionName}>Charts</div>
              <div className={styles.chartOptions}>
                <button onClick={handlePieChart}>Pie</button>
                <button onClick={handleLineChart}>Line</button>
                <button onClick={handleBarChart}>Bar</button>
                <button onClick={handleHistogram}>Histogram</button>
                <button onClick={handleGauge}>Gauge</button>
                <button onClick={handleProgressBar}>ProgressBar</button>
                <button onClick={handleGridView}>Grid View</button>
                <button onClick={handleTable}>Table</button>
              </div>
            </div>
          </div>
        </div>

      </Draggble>

      {isDeviceOption && (
        <div
          style={{ position: "fixed", top: deviceOptionPosi.y - 15 + "px", left: deviceOptionPosi.x - 150 + "px", background: "#060623", borderRadius: "0.625rem", padding: "0.5rem 1rem", color: "#fff", display: "flex", flexDirection: "column", gap: "0.3rem", zIndex: "10" }}
        >
          <button id="display" onClick={handleDisplay}>
            Display
          </button>
          <button onClick={handleController} id="controller">
            Controller
          </button>
          <button onClick={handleIndicator} id="indicator">
            Indicator
          </button>
          <button onClick={handleDigitalIO} id="digitalIO">
            Digital I/O
          </button>
          <button onClick={handleEnergyMeter} id="energyMeter">
            Energy Meter
          </button>
          <button onClick={handleFlowMeter} id="flowMeter">
            Flow Meter
          </button>
          <button onClick={handleMotor} id="motor">
            Motor
          </button>
          <button onClick={handleText} id="text">
            Text
          </button>
        </div>
      )}






    </>

  );
};

export default ToolBar;
