import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import proxy from "../../proxy";

const initialState = {
    isLoading : false,
    accountEmail : '',
    isError : false
};

//Actions
export const fetchAccountEmail = createAsyncThunk("fetchAccountEmail" , async(data) => {
    const response = await fetch(`${proxy}/auth/account-info`,{
        method : 'POST',
        headers: {
            Authorization: `Bearer ${data.token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    });

    const responseBody = await response.json();
    return responseBody;
})


export const accountInfoSlice = createSlice({
    name : 'accoutInfo',
    initialState,
    extraReducers : (builder) => {
        builder.addCase(fetchAccountEmail.pending, (state, action) => {
            state.isLoading = true;
            state.isError = false;
        });
        builder.addCase(fetchAccountEmail.fulfilled, (state, action) => {
            state.accountEmail = action.payload.email;
            state.status=action.payload.status
            state.isLoading = false;
            state.isError = false;
        })
        builder.addCase(fetchAccountEmail.rejected,(state, action) => {
           
            state.isError = true;
        })

    }
})

export default accountInfoSlice.reducer;