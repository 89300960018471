import React, { useEffect, useState } from 'react'
import styles from './Toggle.module.css';
import proxy from "proxy";
import { useToast } from "@chakra-ui/react";


const Toggle = (props) => {
  const toast = useToast();
  const [isToggle, setIsToggle] = useState(props.on);

  const handleToggle = async () => {
    setIsToggle(!isToggle);
    if (props.alarm) {
      
      const token = localStorage.getItem("token");
      const tempElement = {
        alarmName: props.alarm.alarmName,
        channel: props.alarm.channel,
        value: props.alarm.value,
        medium: props.alarm.medium,
        operation: props.alarm.operation,
        alarmId: props.alarm.alarmId,
      };
  
      await fetch(`${proxy}/api/setAlarm`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Project_id: props.alarm.Project_id ?? props.alarm.projectId,
          on: !isToggle,
          alarmId : props.alarm.alarmId
        }),
      })
        .then((response) => {
          if(response.status === 200){
            toast({
              title: `Successfully updated status of alarm`,
              status: "success",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
          }else {
            toast({
              title: `Couldn't update status of alarm`,
              status: "error",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
          }
        })
        .catch((error) => {
          console.error('Fetch error:', error);
        });
    }
  };
  
  return (
    <div className={`${styles.toggleBox} ${isToggle? styles.active : ""}`} onClick={handleToggle}>
      <span className={`${styles.toggle} ${isToggle ? styles.active : ''}`} ></span>
    </div>
  )
}

export default Toggle;
