import React from "react";
import styles from "./Invite.module.css"
import { useState, useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import dicot_img from "assets/Logo.svg";
import proxy from "proxy.js";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "components/Loader.jsx";

function Invite() {
  const [projectID, setProjectID] = useState("");
  const [projectName, setProjectName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const toast = useToast();
  const searchParams = new URLSearchParams(location.search);
  const inviteToken = searchParams.get("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (inviteToken) {
      const token = localStorage.getItem("token");
      try {
        fetch(`${proxy}/api/inviteDetails?inviteToken=${inviteToken}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status === 200) {
              setProjectID(data.projectID);
              setRole(data.role);
              setProjectName(data.projectName);
              setAdminEmail(data.adminEmail);
            } else {
              toast({
                title: "Couldn't find your invite",
                description: data.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
            }
          });
      } catch (error) {
        console.error("Error:", error);
        // Handle any other errors here
      } finally {
        setLoading(false);
      }
    }
  }, [inviteToken]);

  const handleAccept = async () => {
    try {
      const token = localStorage.getItem("token");
      await fetch(`${proxy}/api/acceptInvite?token=${inviteToken}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          clientresponse: "true",
        },
        body: JSON.stringify({
          Project_id: projectID,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 200) {
            navigate("/");
          } else {
            toast({
              title: "Couldn't accept the invite",
              description: data.message,
              status: "error",
              position: "top-right",
              duration: 5000,
              isClosable: true,
            });
          }
        });
    } catch (error) {
      console.error("Error:", error);
      // Handle any other errors here
    }
  };

  const handleDecline = async () => {
    const token = localStorage.getItem("token");

    const response = await fetch(proxy + "/api/declineInviteMember", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        clientresponse: "true",
      },
      body: JSON.stringify({
        token: token,
        fromEmail: localStorage.getItem("email"),
      }),
    });
    if (response.ok) {
      navigate("/auth/login");
    } else {
      toast({
        title: "Error",
        description: await response.json().message,
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.page}>
          <div className={styles.superContainer}>
            <div className={styles.imageContainer}>
              <img className={styles.logo} src={dicot_img} />
            </div>
            <div className={styles.invite}>
              <div className={styles.title}>Project Invite</div>
              <div className={styles.question}>
                {`${adminEmail} has invited you to join Project: ${projectName} [ID: ${projectID}] as ${role}`}
              </div>
              <div className={styles.options}>
                <button className={styles.acceptButton} onClick={handleAccept}>
                  Accept
                </button>
                <button className={styles.declineButton} onClick={handleDecline}>
                  Decline
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Invite;
