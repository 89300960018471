/* eslint-disable import/no-anonymous-default-export */
export default () => {
    /*eslint-disable-next-line no-restricted-globals*/
    self.onmessage = function (e) {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (e.data.data.length > 0) {
            
            const allData = e.data.data;
            const headers = [];
            
            allData.forEach(dataset => {
                const dataOfDataPoint = dataset.data;
                dataOfDataPoint.forEach(element => {
                    const header = element.Header;
                    if (headers.indexOf(header) === -1) {
                        headers.push(header);
                    }
                });
            });

            const arrayOfArrays = [];
            allData.forEach(item => {
                const row = [];
                headers.forEach(header => {
                    // Check if the item has the same header, if not, push an empty string
                    const matchingItem = item.data.find(dataItem => dataItem.Header === header);
                    row.push(matchingItem ? matchingItem.value : null);
                });
                arrayOfArrays.push(row);
            });

            const lastNonNullIndexes = {};
            const channelWiseArrayOfArrays = [];
            const numberOnlyArray = [];

            // Initialize the lastNonNullIndexes object with 0 for each channel
            headers.forEach(header => {
                lastNonNullIndexes[header] = 0;
                channelWiseArrayOfArrays.push([]);
                numberOnlyArray.push([]);
            });

            // Iterate through the rows to find the last non-null index for each channel and create channel-wise data
            for (let rowIndex = 0; rowIndex < arrayOfArrays.length; rowIndex++) {
                const row = arrayOfArrays[rowIndex];
                for (let columnIndex = 0; columnIndex < row.length; columnIndex++) {
                    const number = parseFloat(row[columnIndex]);
                    channelWiseArrayOfArrays[columnIndex].push(number)
                    if (row[columnIndex]) {
                        lastNonNullIndexes[headers[columnIndex]] = rowIndex;
                        numberOnlyArray[columnIndex].push(number)
                    }
                }
            }

            // Table Data organization
            const tableData = headers.map((header, index) => {
                const numberArray = numberOnlyArray[index];
                const min = numberArray.reduce((min, element) => Math.min(min, element), Infinity);
                const max = numberArray.reduce((max, element) => Math.max(max, element), -Infinity);
                return {
                    Header: header,
                    time: new Date(allData[lastNonNullIndexes[header]].time)
                        .toLocaleTimeString(undefined, {
                            day: "2-digit",
                            month: "2-digit",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: false,
                            timeZone: userTimeZone,
                        }).replace(",", ""),
                    val: channelWiseArrayOfArrays[index][lastNonNullIndexes[header]],
                    maxValue: max != NaN || max != null ? max : null,
                    minValue: max != NaN ? min : null,
                }
            });
            const dateTimeArray = allData.map(item => item.time);
            postMessage({ data: tableData, headers: headers, channelWiseData: channelWiseArrayOfArrays, arrayOfArrays: arrayOfArrays, dateTimeArray: dateTimeArray });
        }
    }
};