import React, { useEffect, useState } from "react";
import close from "../../../../../assets/close.png";
import styles from "./AddBatch.module.css";
import proxy from "../../../../../proxy";
import { useToast } from "@chakra-ui/react";
import { addBatch, updateBatch } from "../../../../../redux/slices/batchSlice";
import { useDispatch } from "react-redux";

const AddBatch = ({
  isOpen,
  setIsOpen,
  channelNames,
  setBatchName,
  setBatchData,
  batchData,
  uniqueProjectId,
  setRefresh,
  setNewBatch,
  cardBatchId
}) => {
  const [data, setData] = useState({
    name: "",
    selectedItems: [],
    selectAll: false,
    // Add other fields as needed
  });



  const [Name, setName] = useState("Name");

  const toast = useToast();
  const dispatch = useDispatch();

  const handleCheckboxChange = (channelName) => {
    const updatedSelectedItems = [...data.selectedItems];

    if (updatedSelectedItems.includes(channelName)) {
      // If the channelName is already in the array, remove it
      updatedSelectedItems.splice(updatedSelectedItems.indexOf(channelName), 1);
    } else {
      // If the channelName is not in the array, add it
      updatedSelectedItems.push(channelName);
    }

    const selectAll = updatedSelectedItems.length === channelNames.length;

    setData({
      ...data,
      selectedItems: updatedSelectedItems,
      selectAll: selectAll,
    });
  };

  const handleSelectAllChange = () => {
    const updatedSelectedItems = data.selectAll ? [] : [...channelNames];
    const selectAll = updatedSelectedItems.length === channelNames.length;
    setData({
      ...data,
      selectedItems: updatedSelectedItems,
      selectAll: selectAll,
    });
  };

  const handleStartBatch = async () => {
    setIsOpen("");
    const batchInput = document.getElementById("batchName").value;
    setBatchName(batchInput);

    const token = localStorage.getItem("token");
    try {
      // await fetch(`${proxy}/batch/createBatch`, {
      //   method: "POST",
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({
      //     batchName: batchInput,
      //     Project_id: uniqueProjectId,
      //     channelName: data.selectedItems,
      //     status: true,
      //   }),
      // }).then(async (res) => {
      //   const response = await res.json();
      //   if(response.status === 200){
      //     toast({
      //       title: `Batch created successfully`,
      //       status: "success",
      //       position: "top-right",
      //       duration: 5000,
      //       isClosable: true,
      //     })
      //     setBatchData([
      //       ...batchData,
      //       {
      //         batchName: batchInput,
      //         status: true,
      //         channelName: data.selectedItems,
      //         batchID: response.batchID,
      //         startTime: response.startTime,
      //         projectID : uniqueProjectId
      //       },
      //     ]);
      //   } else {
      //     toast({
      //       title: `Couldn't create the batch`,
      //       status: "error",
      //       position: "top-right",
      //       duration: 5000,
      //       isClosable: true,
      //     })
      //   }
      // });

      const apiBody = {
        token: token,
        batchName: batchInput,
        Project_id: uniqueProjectId,
        channelName: data.selectedItems,
        status: true,
      }

      dispatch(addBatch(apiBody)).then((response) => {
        if (response.payload.responseBody.status === 200) {
          toast({
            title: `Batch created successfully`,
            status: "success",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          })
        } else {
          toast({
            title: `Couldn't create the batch`,
            status: "error",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          })
        }
      })
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditBatch = async () => {
    setIsOpen("");
    const batchInput = document.getElementById("batchName").value;
    const modifiedValues = {
      batchID: cardBatchId,
      batchName: batchInput,
      channelName: data.selectedItems,
    };

    const token = localStorage.getItem("token");
    try {
      // const response = await fetch(`${proxy}/batch/editBatch`, {
      //   method: "POST",
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify(modifiedValues),
      // });
      // if (response.ok) {
      //   // Update the batch data in the component state without reloading the page
      //   const updatedBatchData = batchData.map((batch) => {
      //     if (batch.batchID === cardBatchId) {
      //       return {
      //         ...batch,
      //         batchName: batchInput,
      //         selectedchannels: data.selectedItems,
      //       };
      //     }
      //     return batch;
      //   });
      //   setBatchData(updatedBatchData);

      const apiBody = {
        token: token,
        batchID: cardBatchId,
        batchName: batchInput,
        channelName: data.selectedItems
      }
      dispatch(updateBatch(apiBody)).then((response) => {
        if (response.payload.responseBody.status === 200) {
          toast({
            title: `Batch updated successfully`,
            status: "success",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          })
        } else {
          toast({
            title: `Couldn't update the batch`,
            status: "error",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          })
        }
      })

    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={styles.AddBatch}>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.text}>
              {isOpen === "addBatch" ? "Add Batch" : "Edit Batch"}
            </div>
            <button className={styles.closeBtn}>
              <img
                src={close}
                alt=""
                srcSet=""
                onClick={() => setIsOpen(false)}
              />
            </button>
          </div>
          <div className={styles.inputWrapper}>
            <div className={styles.name}>
              <input
                type={Name}
                placeholder="Name"
                id="batchName"
                className={styles.inputName}
              />
            </div>

            <div className={styles.select}>Select Channels</div>

            <div className={styles.check}>

              <div className={styles.selectChannel}>
                {/* "Select All" checkbox */}
                <input
                  type="checkbox"
                  checked={data.selectAll}
                  onChange={handleSelectAllChange}
                />
                <label htmlFor="selectAll">Select All</label>
              </div>
              {/* Map over the backend list to create checkboxes */}
              {channelNames.map((item, key) => (
                <div className={styles.checkBox} key={key}>
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxChange(item)}
                    checked={data.selectedItems.includes(item)}
                  />
                  <span>{item}</span>
                </div>
              ))}
            </div>
            <div className={styles.checkStart}>
              <button
                className={styles.start}
                onClick={
                  isOpen === "addBatch" ? handleStartBatch : handleEditBatch
                }
              >
                {isOpen === "addBatch" ? "Start" : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBatch;
