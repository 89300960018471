import React, { useEffect, useState } from "react";
import Channel from "./Channel";
import tableInitWorker from "Utils/restructure.worker";
import WorkerBuilder from "Utils/builder.worker";
import valueExtractorWorker from "Utils/valueExtractor.worker";
import Draggable from "react-draggable";
import { useSelector } from "react-redux";

const tableInitializer = new WorkerBuilder(tableInitWorker);
const valueExtractor = new WorkerBuilder(valueExtractorWorker);

const Channels = ({
  data,
  socketData,
  handleDefaultGridDrop,
  handleDefaultGridDimension,
  openNewPage,
  selectedTabIndex,
  tabIndexDefaultGrid,
  tempTabIndexDefaultGrid,
  isMobile,
  scaleValue
}) => {
  const [tableData, setTableData] = useState([]); // Data formatted for Table
  const [init, setInit] = useState(false);
  const [isWorkerBusy, setIsWorkerBusy] = useState(false);
  const [dateTimesLive, setDateTimesLive] = useState([]); // DateTime labels for y-axis
  const [dataArrLive, setDataArrLive] = useState([]); // Real-time data received for live
  const [channelNames, setChannelNames] = useState([]); //  Channel names
  const graphData = useSelector(state => state.viewData.graphData)

  useEffect(() => {
    // Parsing data from from get request in the parent component
    const fetchData = async () => {
      try {
        if (graphData.length > 0) {
          const copiedDataset = [...graphData];
          // Live Data Chart
          const currentTime = new Date();
          currentTime.setTime(currentTime.getTime() - 1 * 60 * 60 * 1000); // Subtracting 1 hour
          const liveDataFiltered = copiedDataset.filter(
            (dataset) => new Date(dataset.time) >= currentTime
          );

          // Restructure Data for Table
          const tableDataArrayGen = async () => {
            const dataPromise = new Promise((resolve) => {
              tableInitializer.onmessage = (e) => {
                resolve(e.data);
              };
            });
            tableInitializer.postMessage({ data: liveDataFiltered });
            return await dataPromise;
          };
          const tableDataArray = await tableDataArrayGen();
          let gotHeaders = [];
          if (tableDataArray.data) {
            gotHeaders = tableDataArray.headers;
            const arrayOfValueArrays = tableDataArray.channelWiseData;

            const getNewDataArr = () =>
              gotHeaders.map((header, index) => ({
                label: header,
                data: arrayOfValueArrays[index],
                fill: false,
              }));

            const newDataArr = getNewDataArr();

            setChannelNames(gotHeaders);
            setTableData(tableDataArray.data);
            setDataArrLive(newDataArr);
            setDateTimesLive(tableDataArray.dateTimeArray);
            setInit(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [graphData]);

  useEffect(() => {
    try {
      const updateData = async () => {
        if (socketData && init && !isWorkerBusy) {
          setIsWorkerBusy(true);
          const structuredData = async () => {
            const dataPromise = new Promise((resolve) => {
              valueExtractor.onmessage = (e) => {
                resolve(e.data);
              };
            });
            valueExtractor.postMessage({
              socketData: socketData,
              currentTable: tableData,
              dateTimeArray: dateTimesLive,
              dataArray: dataArrLive,
              headers: channelNames,
              duration: 1,
            });
            return await dataPromise;
          };

          const workerResponse = await structuredData();
          if (workerResponse.updatedTable) {
            setChannelNames(workerResponse.updatedHeaders);
            setTableData(workerResponse.updatedTable);
            setDataArrLive(workerResponse.updatedLiveArray);
            setDateTimesLive(workerResponse.updatedDateTimeArray);
          }
        }
      };
      updateData();
    } catch (error) {
      console.log(error);
    }
    setIsWorkerBusy(false);
  }, [socketData]);

  const generateDefaultGrid = () => {
    const selectedTabDevices = tabIndexDefaultGrid[selectedTabIndex];
    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices?.map((device, index) => {
        const { x, y, id, width, height } = device;
        const deviceStyle = {
          position: "absolute",
          left: x ? x + "px" : "",
          top: y ? y + "px" : "",
          display: isMobile ? "grid" : "flex",
          gridTemplateColumns: "1fr 1fr 1fr",
          flexWrap: "wrap",
          width: width ? width : "100%",
          maxWidth: isMobile ? "" : "100%",
          height: height ? height : "",
          padding: "1.2rem",
          gap: "2rem",
          maxHeight: "75vh",
          overflowY: "auto",
          resize: openNewPage ? "both" : "",
          scale: isMobile ? scaleValue.toString() : "1"
        };
        return (
          <>
            <div style={deviceStyle} id="DefaultGrid">
              {tableData.map((channel, index) => (
                <Channel data={channel} />
              ))}
            </div>
          </>
        );
      });
    } else {
      const deviceStyle = {
        maxWidth: isMobile ? "" : " 100%",
        position: "absolute",
        top: "70px",
        display: isMobile ? "grid" : "flex",
        gridTemplateColumns: "1fr 1fr 1fr",
        flexWrap: "wrap",
        padding: "1.2rem",
        gap: "2rem",
        maxHeight: "75vh",
        overflowY: "auto",
        resize: openNewPage ? "both" : "",
        scale: isMobile ? scaleValue.toString() : "1"
      };
      return (
        <>
          <div style={deviceStyle} id="DefaultGrid">
            {tableData.map((channel, index) => (
              <Channel data={channel} />
            ))}
          </div>
        </>
      );
    }
  };

  const tempGenerateDefaultGrid = () => {
    const selectedTabDevices = tempTabIndexDefaultGrid[selectedTabIndex];
    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices?.map((device, index) => {
        const { x, y, id, width, height } = device;
        const deviceStyle = {
          position: "absolute",
          left: x ? x + "px" : "",
          top: y ? y + "px" : "",
          display: isMobile ? "grid" : "flex",
          gridTemplateColumns: "1fr 1fr 1fr",
          flexWrap: "wrap",
          width: width ? width : "",
          maxWidth: isMobile ? "" : "100%",
          height: height ? height : "",
          padding: "1.2rem",
          gap: "2rem",
          maxHeight: "75vh",
          overflowY: "auto",
          resize: openNewPage ? "both" : "",
        };
        return (
          <>
            <Draggable bounds="parent"
              onStop={(e, data) => handleDefaultGridDrop(e, data, id, x, y)}
            >
              <div
                style={deviceStyle}
                id="DefaultGrid"
                onContextMenu={(e) => handleDefaultGridDimension(e, id)}
              >
                {tableData.map((channel, index) => (
                  <Channel data={channel} />
                ))}
              </div>
            </Draggable>
          </>
        );
      });
    } else {
      const deviceStyle = {
        maxWidth: isMobile ? "" : "100%",
        position: "absolute",
        top: "70px",
        display: isMobile ? "grid" : "flex",
        gridTemplateColumns: "1fr 1fr 1fr",
        flexWrap: "wrap",
        padding: "1.2rem",
        gap: "2rem",
        maxHeight: "75vh",
        overflowY: "auto",
        resize: openNewPage ? "both" : "",
      };
      return (
        <>
          <Draggable bounds="parent"
          >
            <div style={deviceStyle} id="DefaultGrid">
              {tableData.map((channel, index) => (
                <Channel data={channel} />
              ))}
            </div>
          </Draggable>
        </>
      );
    }
  };

  return (
    <>
      {openNewPage ? (
        tempGenerateDefaultGrid()
      ) : (
        generateDefaultGrid()
      )}
    </>
  )
};

export default Channels;
