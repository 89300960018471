import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

const Gauge = ({
  dateTimesLive,
  channelNames,
  dataArrLive,
  chartChannel,
  minVal,
  maxVal,
  isMobile,
  changeRange,
  id,
  specificMaxVal,
  specificMinVal
}) => {
  const [gaugeData, setGaugeData] = useState([]);
  const [chartOption, setChartOption] = useState([]);
  const [chartPlugins, setChartPlugins] = useState();
  const [color, setColor] = useState(false);
  ChartJS.register(ArcElement, Tooltip, Legend);

  useEffect(() => {
    const lastValues =
      dataArrLive.length === 0
        ? [0, 100]
        : channelNames?.map(
            (header, index) => dataArrLive[index].data.slice(-1)[0]
          );
    const matchedDatasets = channelNames?.includes(chartChannel?.length>0 && chartChannel[0])
      ? chartChannel?.map((label) =>
          dataArrLive?.find((dataset) => dataset.label === label)
        )
      : [];

    const newChartData = {
      labels: chartChannel?.length > 0 ? chartChannel : channelNames,
      datasets: [
        {
          label: "channel",
          data:
            matchedDatasets.length > 0
              ? matchedDatasets[0]?.data.slice(-1)
              : lastValues,
          cutout: "85%",
          circumference: 180,
          rotation: 270,
        },
      ],
    };

    const options = {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    };
    setColor(true);
    setChartOption(options);
    setGaugeData(newChartData);
  }, [
    dateTimesLive,
    dataArrLive,
    chartChannel,
    minVal,
    maxVal,
    channelNames,
    color,
    changeRange,
  ]);

  useEffect(() => {
    const gaugePlugins = {
      id: id,
      afterDatasetsDraw(chart, args, pluginsOptions) {
        const {
          ctx,
          data,
          chartArea: { top, bottom, right, left, width, height },
          scales: { r },
        } = chart;
        ctx.save();
        const dataset = data.datasets[0]; // Access the first dataset
        dataset.backgroundColor = ctx.createLinearGradient(
          left,
          top,
          right,
          bottom
        ); // Create gradient for doughnut
        dataset?.backgroundColor.addColorStop(0, "green");
        dataset?.backgroundColor.addColorStop(0.5, "yellow");
        dataset?.backgroundColor.addColorStop(0.6, "orange");
        dataset?.backgroundColor.addColorStop(1, "red");

        const xCoor = chart.getDatasetMeta(0).data[0]
          ? chart.getDatasetMeta(0).data[0].x
          : 129.5;
        const yCoor = chart.getDatasetMeta(0).data[0]
          ? chart.getDatasetMeta(0).data[0].y
          : 193.75;

        const currentValue =
          dataset.data[0] !== undefined ? dataset.data[0] : NaN; // Access the current value
        // Assuming a maximum value of 1000
        ctx.font = isMobile ? "15px san-serif" : "20px san-serif";
        ctx.fillStyle = "#666";
        ctx.textBaseLine = "top";
        ctx.textAlign = "left";
        ctx.fillText(minVal, xCoor - xCoor + 5, yCoor + 17);

        ctx.textAlign = "right";

        ctx.fillText(maxVal, right, yCoor + 17);

        ctx.font = isMobile ? "40px san-serif" : "60px san-serif";
        ctx.textAlign = "center";

        const percentage = (currentValue / maxVal) * 100;
        if (percentage >= 0 && percentage < 40) {
          ctx.fillStyle = "green";
        } else if (percentage >= 40 && percentage < 50) {
          ctx.fillStyle = "yellow";
        } else if (percentage >= 50 && percentage < 60) {
          ctx.fillStyle = "orange";
        } else {
          ctx.fillStyle = "red";
        }
        ctx.fillText(currentValue, xCoor, yCoor);
      },
    };
    setChartPlugins(() => gaugePlugins);
  }, [specificMaxVal, specificMinVal]);

  return (
    <div>
      {gaugeData && gaugeData?.labels ? (
        <Doughnut
          data={gaugeData}
          options={chartOption}
          plugins={[chartPlugins]}
          id={id}
        />
      ) : (
        <p>Loading chart data...</p>
      )}
    </div>
  );
};

const NewGauge = ({
  openNewPage,
  dateTimesLive,
  channelNames,
  dataArrLive,
  chartChannel,
}) => {
  const [gaugeData, setGaugeData] = useState([]);
  const [chartOption, setChartOption] = useState([]);
  const [chartPlugins, setChartPlugins] = useState();
  const [color, setColor] = useState(false);

  ChartJS.register(ArcElement, Tooltip, Legend);

  useEffect(() => {
    const values =
      chartChannel !== undefined
        ? dataArrLive.find((header) => header.label === chartChannel)
        : null;

    const lastValues =
      chartChannel !== undefined
        ? [values.data.slice(-1)[0]]
        : dataArrLive.length > 0 ? dataArrLive.map(
            (header, index) => dataArrLive[index].data.slice(-1)[0]
          ) : [100, 0]

    const newChartData = {
      labels: channelNames,
      datasets: [
        {
          label: "channel",
          data: lastValues,
          cutout: "85%",
          circumference: 180,
          rotation: 270,
        },
      ],
    };

    const options = {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    };

    setColor(true);
    const gaugePlugins = {
      id: "gaugeChartText",
      afterDatasetsDraw(chart, args, pluginsOptions) {
        const {
          ctx,
          data,
          chartArea: { top, bottom, right, left, width, height },
          scales: { r },
        } = chart;
        ctx.save();

        const dataset = data.datasets[0]; // Access the first dataset

        dataset.backgroundColor = ctx.createLinearGradient(
          left,
          top,
          right,
          bottom
        ); // Create gradient for doughnut

        dataset?.backgroundColor.addColorStop(0, "green");
        dataset?.backgroundColor.addColorStop(0.5, "yellow");
        dataset?.backgroundColor.addColorStop(0.6, "orange");
        dataset?.backgroundColor.addColorStop(1, "red");
        const xCoor = chart.getDatasetMeta(0).data.length > 0 
          ? chart.getDatasetMeta(0).data[0].x
          : null;
        const yCoor = chart.getDatasetMeta(0).data.length > 0 
          ? chart.getDatasetMeta(0).data[0].y
          : null;

        const currentValue = dataset.data[0] ? dataset.data[0] : 100;// Access the current value
        const endValue = chartChannel !== undefined ? dataset.data[1] : 100; // Access the end value

        ctx.font = "20px san-serif";  
        ctx.fillStyle = "#666";
        ctx.textBaseLine = "top";
        ctx.textAlign = "left";
        ctx.fillText(0, xCoor - xCoor + 5, yCoor + 17);

        ctx.textAlign = "right";

        ctx.fillText(endValue, right, yCoor + 17);

        ctx.font = "60px san-serif";
        ctx.textAlign = "center";

        const percentage = (currentValue / endValue) * 100;
        if (percentage >= 0 && percentage < 40) {
          ctx.fillStyle = "green";
        } else if (percentage >= 40 && percentage < 50) {
          ctx.fillStyle = "yellow";
        } else if (percentage >= 50 && percentage < 60) {
          ctx.fillStyle = "orange";
        } else {
          ctx.fillStyle = "red";
        }

        ctx.fillText(currentValue, xCoor, yCoor);
      },
    };

    setChartPlugins(gaugePlugins);
    setChartOption(options);
    setGaugeData(newChartData);
  }, [dateTimesLive, dataArrLive, chartChannel, color]);

  return (
    <div>
      {gaugeData && gaugeData?.labels ? (
        <Doughnut
          data={gaugeData}
          options={chartOption}
          plugins={[chartPlugins]}
          id="gaugeChart"
        />
      ) : (
        <p>Loading chart data...</p>
      )}
    </div>
  );
};

export { Gauge, NewGauge };
