import React, { useEffect, useState } from "react";
import styles from "./Devices.module.css";

const Devices = ({
  name,
  tableData,
  channelName,
  activeTabIndex,
  openNewPage,
  tempTabIndexDevices,
  index,
  tempImages,
  id,
  unit,
  isMobile,
  setTempImages,
}) => {
  const [oldPV, setOldPV] = useState();

  useEffect(() => {
    const pvVal = tableData.find((data) => data.Header === channelName);
    setOldPV(pvVal);
  }, [openNewPage, tableData, oldPV, channelName]);

  const handleUnitChange = (e) => {
    const unitValue = e.target.value;
    const indexDevice = tempTabIndexDevices[activeTabIndex];
    indexDevice[index].unit = unitValue;
    const originalDeviceIndex = tempImages.findIndex((data) => data.id === id);
    const tempIndicator = [...tempImages];

    const updatedData = {
      ...tempIndicator[originalDeviceIndex],
      unit: unitValue,
    };
    tempIndicator[originalDeviceIndex] = updatedData;
    setTempImages(tempIndicator);
  };

  return (
    <>
      <div className={styles.deviceName}>{name}</div>
      <div className={styles.pvWrapper}>
        <div className={styles.pvText}>PV</div>
        <div className={styles.pvValueWrapper}>
          <span className={styles.pvValue}>
            {oldPV?.Header === channelName ? oldPV?.val : "--"}
          </span>
        </div>
      </div>
      <div className={styles.unitContainer}>
        <div
          className={styles.unitText}
          style={{ display: openNewPage && isMobile ? "none" : "inline" }}
        >
          Unit:
        </div>
        {!openNewPage && <div className={styles.unitValue}>{unit}</div>}
        {openNewPage && (
          <select
            defaultValue={unit}
            className={styles.unitSelector}
            onChange={(e) => handleUnitChange(e)}
          >
            <>
              <option>--</option>
              <option>°C</option>
              <option>°F</option>
              <option>°K</option>
              <option>%</option>
              <option>Hz</option>
              <option>pH</option>
              <option>KW</option>
              <option>kWh</option>
              <option>W</option>
              <option>V</option>
              <option>KA</option>
              <option>A</option>
              <option>L/h</option>
              <option>MJ</option>
              <option>J</option>
              <option>m</option>
              <option>L</option>
              <option>kg/h</option>
              <option>T</option>
              <option>T/h</option>
              <option>%RH</option>
              <option>KPa</option>
              <option>MPa</option>
              <option>hPa</option>
              <option>Pa</option>
              <option>ppm</option>
              <option>m³</option>
              <option>m³/h</option>
              <option>min¯¹</option>
              <option>kg/cm²</option>
              <option>m3/min</option>
              <option>m3/s</option>
              <option>S m3/h</option>
              <option>S m3/min</option>
              <option>S m3/s C</option>
              <option>N m3/h</option>
              <option>N m3/min</option>
              <option>N m3/s C</option>
              <option>kg/min</option>
              <option>kg/s</option>
              <option>T/min</option>
              <option>T/s</option>
              <option>L/min</option>
              <option>L/s</option>
              <option>gm/h</option>
              <option>gm/min</option>
              <option>gm/s</option>
              <option>QF/h</option>
              <option>QF/min</option>
              <option>QF/s</option>
            </>
          </select>
        )}
      </div>
    </>
  );
};

const Indicator = ({
  device,
  tableData,
  pvChannel,
  multipleDevices,
  index,
  tempImages,
}) => {
  const [displayChannel, setDisplayChannel] = useState();
  const [responseChannel, setResponseChannel] = useState();

  useEffect(() => {
    setDisplayChannel(pvChannel);
    let selectedChannelData;
    selectedChannelData = tableData.find(
      (data) => data.Header === displayChannel
    );
    setResponseChannel(selectedChannelData);
  }, [responseChannel, displayChannel, pvChannel, tableData]);

  const handleUnitChange = (e) => {
    const unitValue = e.target.value;
    multipleDevices[index].unit = unitValue;
  };

  return (
    <>
      <div className={styles.deviceName}>{device}</div>
      <div className={styles.pvWrapper}>
        <div className={styles.pvText}>PV</div>
        <div className={styles.pvValueWrapper}>
          <span className={styles.pvValue}>
            {responseChannel ? responseChannel.val : "--"}
          </span>
        </div>
      </div>
      <div className={styles.unitContainer}>
        <div className={styles.unitText}>Unit:</div>
        <select
          name=""
          className={styles.unitSelector}
          onChange={(e) => handleUnitChange(e)}
        >
          <option>--</option>
          <option>°C</option>
          <option>°F</option>
          <option>°K</option>
          <option>%</option>
          <option>Hz</option>
          <option>pH</option>
          <option>KW</option>
          <option>kWh</option>
          <option>W</option>
          <option>V</option>
          <option>KA</option>
          <option>A</option>
          <option>L/h</option>
          <option>MJ</option>
          <option>J</option>
          <option>m</option>
          <option>L</option>
          <option>kg/h</option>
          <option>T</option>
          <option>T/h</option>
          <option>%RH</option>
          <option>KPa</option>
          <option>MPa</option>
          <option>hPa</option>
          <option>Pa</option>
          <option>ppm</option>
          <option>m³</option>
          <option>m³/h</option>
          <option>min¯¹</option>
          <option>kg/cm²</option>
          <option>m3/min</option>
          <option>m3/s</option>
          <option>S m3/h</option>
          <option>S m3/min</option>
          <option>S m3/s C</option>
          <option>N m3/h</option>
          <option>N m3/min</option>
          <option>N m3/s C</option>
          <option>kg/min</option>
          <option>kg/s</option>
          <option>T/min</option>
          <option>T/s</option>
          <option>L/min</option>
          <option>L/s</option>
          <option>gm/h</option>
          <option>gm/min</option>
          <option>gm/s</option>
          <option>QF/h</option>
          <option>QF/min</option>
          <option>QF/s</option>
        </select>
      </div>
    </>
  );
};

const Display = ({
  device,
  tableData,
  pvChannel,
  multipleDisplay,
  index,
}) => {

  const [displayChannel, setDisplayChannel] = useState();
  const [responseChannel, setResponseChannel] = useState();

  useEffect(() => {
    setDisplayChannel(pvChannel);
    let selectedChannelData;
    selectedChannelData = tableData.find(
      (data) => data.Header === displayChannel
    );
    setResponseChannel(selectedChannelData);
  }, [responseChannel, displayChannel, pvChannel,tableData]);

  return (
    <div className={styles.displayValue}>
        {responseChannel ? responseChannel.val : "--"}
    </div>
  )
}


const DeviceDisplay = ({
  tableData,
  channelName,
  openNewPage,
}) => {
  const [oldPV, setOldPV] = useState();

  useEffect(() => {
    const pvVal = tableData.find((data) => data.Header === channelName);
    setOldPV(pvVal);
  }, [openNewPage, tableData, channelName]);
  return (
    <div className={styles.displayValue}>
      {oldPV?.Header === channelName ? oldPV?.val : "--"}
    </div>
  )
}


const DeviceController = ({
  name,
  openNewPage,
  activeTabIndex,
  clickedDeviceIndex,
  selectedDevice,
  tableData,
  channelName,
  currentPVChannel,
  currentSVChannel,
  unit,
  tabIndex,
  images,
  tempTabIndexController,
  id,
  isMobile,
  tempImages,
  setTempImages
}) => {
  const [oldPV, setOldPV] = useState();
  const [oldSV, setOldSV] = useState();
  useEffect(() => {
    const pvVal = tableData.find((data) => data.Header === channelName.PV);
    setOldPV(pvVal);
    const svVal = tableData.find((data) => data.Header === channelName.SV);
    setOldSV(svVal);
  }, [
    openNewPage,
    clickedDeviceIndex,
    activeTabIndex,
    channelName,
    selectedDevice,
    currentPVChannel,
    currentSVChannel,
    tableData,
    oldPV,
    oldSV,
  ]);

  const handleUnitChange = (e) => {
    const unitValue = e.target.value;
    const indexDevice = tempTabIndexController[tabIndex];
    const newIndex = indexDevice.findIndex((data) => data.id === id);
    indexDevice[newIndex].unit = unitValue;
    const originalDeviceIndex = images.findIndex((data) => data.id === id);
    const tempController = [...tempImages];

    const updatedData = {
      ...tempController[originalDeviceIndex],
      unit: unitValue
    }

    tempController[originalDeviceIndex] = updatedData;
    setTempImages(tempController);
  };

  return (
    <>
      <div className={styles.deviceName}>{name}</div>
      <div className={styles.pvWrapper}>
        <div className={styles.pvText}>PV</div>
        <div className={styles.pvValueWrapper}>
          <span className={styles.pvValue}>
            {oldPV?.Header === channelName.PV ? oldPV?.val : "--"}
          </span>
        </div>
      </div>
      <div className={styles.svWrapper}>
        <div className={styles.svText}>SV</div>
        <div className={styles.svValueWrapper}>
          <span className={styles.svValue}>
            {oldSV?.Header === channelName.SV ? oldSV?.val : "--"}
          </span>
        </div>
      </div>
      <div className={styles.unitContainer}>
        <div
          className={styles.unitText}
          style={{ display: openNewPage && isMobile ? "none" : "inline" }}
        >
          Unit:
        </div>
        {!openNewPage && <div className={styles.unitValue}>{unit}</div>}
        {openNewPage && (
          <select
            defaultValue={unit}
            className={styles.unitSelector}
            onChange={(e) => handleUnitChange(e)}
          >
            <>
              <option>--</option>
              <option>°C</option>
              <option>°F</option>
              <option>°K</option>
              <option>%</option>
              <option>Hz</option>
              <option>pH</option>
              <option>KW</option>
              <option>kWh</option>
              <option>W</option>
              <option>V</option>
              <option>KA</option>
              <option>A</option>
              <option>L/h</option>
              <option>MJ</option>
              <option>J</option>
              <option>m</option>
              <option>L</option>
              <option>kg/h</option>
              <option>T</option>
              <option>T/h</option>
              <option>%RH</option>
              <option>KPa</option>
              <option>MPa</option>
              <option>hPa</option>
              <option>Pa</option>
              <option>ppm</option>
              <option>m³</option>
              <option>m³/h</option>
              <option>min¯¹</option>
              <option>kg/cm²</option>
              <option>m3/min</option>
              <option>m3/s</option>
              <option>S m3/h</option>
              <option>S m3/min</option>
              <option>S m3/s C</option>
              <option>N m3/h</option>
              <option>N m3/min</option>
              <option>N m3/s C</option>
              <option>kg/min</option>
              <option>kg/s</option>
              <option>T/min</option>
              <option>T/s</option>
              <option>L/min</option>
              <option>L/s</option>
              <option>gm/h</option>
              <option>gm/min</option>
              <option>gm/s</option>
              <option>QF/h</option>
              <option>QF/min</option>
              <option>QF/s</option>
            </>
          </select>
        )}
      </div>
    </>
  );
};


const Controller = ({
  device,
  tableData,
  pvChannel,
  svChannel,
  multipleController,
  index,
}) => {
  const [displayPVChannel, setDisplayPVChannel] = useState();
  const [displaySVChannel, setDisplaySVChannel] = useState();
  const [responsePVChannel, setResponsePVChannel] = useState();
  const [responseSVChannel, setResponseSVChannel] = useState();

  useEffect(() => {
    setDisplayPVChannel(pvChannel);
    setDisplaySVChannel(svChannel);
    let pvValue;
    let svValue;

    pvValue = tableData.find((data) => data.Header === displayPVChannel);
    setResponsePVChannel(pvValue);

    svValue = tableData.find((data) => data.Header === displaySVChannel);
    setResponseSVChannel(svValue);
  }, [
    responsePVChannel,
    responseSVChannel,
    displayPVChannel,
    displaySVChannel,
    pvChannel,
    svChannel,
    tableData
  ]);

  const handleUnitChange = (e) => {
    const unitValue = e.target.value;
    multipleController[index].unit = unitValue;
  };

  return (
    <>
      <div className={styles.deviceName}>{device}</div>
      <div className={styles.pvWrapper}>
        <div className={styles.pvText}>PV</div>
        <div className={styles.pvValueWrapper}>
          <span className={styles.pvValue}>
            {responsePVChannel ? responsePVChannel.val : "--"}
          </span>
        </div>
      </div>
      <div className={styles.svWrapper}>
        <div className={styles.svText}>SV</div>
        <div className={styles.svValueWrapper}>
          <span className={styles.svValue}>
            {responseSVChannel ? responseSVChannel.val : "--"}
          </span>
        </div>
      </div>
      <div className={styles.unitContainer}>
        <div className={styles.unitText}>Unit:</div>
        <select
          name=""
          id="unitSelector"
          className={styles.unitSelector}
          onChange={(e) => handleUnitChange(e)}
        >
          <option>--</option>
          <option>°C</option>
          <option>°F</option>
          <option>°K</option>
          <option>%</option>
          <option>Hz</option>
          <option>pH</option>
          <option>KW</option>
          <option>kWh</option>
          <option>W</option>
          <option>V</option>
          <option>KA</option>
          <option>A</option>
          <option>L/h</option>
          <option>MJ</option>
          <option>J</option>
          <option>m</option>
          <option>L</option>
          <option>kg/h</option>
          <option>T</option>
          <option>T/h</option>
          <option>%RH</option>
          <option>KPa</option>
          <option>MPa</option>
          <option>hPa</option>
          <option>Pa</option>
          <option>ppm</option>
          <option>m³</option>
          <option>m³/h</option>
          <option>min¯¹</option>
          <option>kg/cm²</option>
          <option>m3/min</option>
          <option>m3/s</option>
          <option>S m3/h</option>
          <option>S m3/min</option>
          <option>S m3/s C</option>
          <option>N m3/h</option>
          <option>N m3/min</option>
          <option>N m3/s C</option>
          <option>kg/min</option>
          <option>kg/s</option>
          <option>T/min</option>
          <option>T/s</option>
          <option>L/min</option>
          <option>L/s</option>
          <option>gm/h</option>
          <option>gm/min</option>
          <option>gm/s</option>
          <option>QF/h</option>
          <option>QF/min</option>
          <option>QF/s</option>
        </select>
      </div>
    </>
  );
};

const DigitalIO = ({ device }) => {
  return (
    <>
      <div className={styles.ioName}>{device}</div>
      <div className={styles.ioRound}>
        <span className={styles.ioText}>OFF</span>
      </div>
    </>
  );
};

const DeviceIO = ({ name, on }) => {

  return (
    <>
      <div className={styles.ioName} >{name}</div>
      <div className={styles.ioRound} style={{ background: `${on ? "green" : "red"}` }}>
        <span className={styles.ioText} >{on === true ? "ON" : "OFF"}</span>
      </div>
    </>
  );
};

const FlowMeter = ({
  device,
  pvChannel,
  svChannel,
  tableData,
  multipleFlowM,
  index,
  unitPv,
  unitSv
}) => {
  const [displayPVChannel, setDisplayPVChannel] = useState();
  const [displaySVChannel, setDisplaySVChannel] = useState();
  const [responsePVChannel, setResponsePVChannel] = useState();
  const [responseSVChannel, setResponseSVChannel] = useState();

  useEffect(() => {
    setDisplayPVChannel(pvChannel);
    setDisplaySVChannel(svChannel);
    let pvValue;
    let svValue;

    pvValue = tableData.find((data) => data.Header === displayPVChannel);
    setResponsePVChannel(pvValue);

    svValue = tableData.find((data) => data.Header === displaySVChannel);
    setResponseSVChannel(svValue);
  }, [
    responsePVChannel,
    responseSVChannel,
    displayPVChannel,
    displaySVChannel,
    pvChannel,
    svChannel,
    tableData
  ]);

  const handleUnitChangePV = (e) => {
    const unitValue = e.target.value;
    multipleFlowM[index].unitPv = unitValue;

  };

  const handleUnitChangeSv = (e) => {
    const unitValue = e.target.value;
    multipleFlowM[index].unitSv = unitValue;
  };
  return (
    <>
      <div className={styles.deviceName}>{device}</div>
      <div className={styles.pvWrapper}>
        <div className={styles.unitContainer}>
          <select
            className={styles.flowMtrUnitSel}
            onClick={(e) => handleUnitChangePV(e)}
          >
            <option>Unit:</option>
            <option>°C</option>
            <option>°F</option>
            <option>°K</option>
            <option>%</option>
            <option>Hz</option>
            <option>pH</option>
            <option>KW</option>
            <option>kWh</option>
            <option>W</option>
            <option>V</option>
            <option>KA</option>
            <option>A</option>
            <option>L/h</option>
            <option>MJ</option>
            <option>J</option>
            <option>m</option>
            <option>L</option>
            <option>kg/h</option>
            <option>T</option>
            <option>T/h</option>
            <option>%RH</option>
            <option>KPa</option>
            <option>MPa</option>
            <option>hPa</option>
            <option>Pa</option>
            <option>ppm</option>
            <option>m³</option>
            <option>m³/h</option>
            <option>min¯¹</option>
            <option>kg/cm²</option>
            <option>m3/min</option>
            <option>m3/s</option>
            <option>S m3/h</option>
            <option>S m3/min</option>
            <option>S m3/s C</option>
            <option>N m3/h</option>
            <option>N m3/min</option>
            <option>N m3/s C</option>
            <option>kg/min</option>
            <option>kg/s</option>
            <option>T/min</option>
            <option>T/s</option>
            <option>L/min</option>
            <option>L/s</option>
            <option>gm/h</option>
            <option>gm/min</option>
            <option>gm/s</option>
            <option>QF/h</option>
            <option>QF/min</option>
            <option>QF/s</option>
          </select>
        </div>
        <div className={styles.pvValueWrapperF}>
          <span className={styles.pvValue}>
            {responsePVChannel ? responsePVChannel.val : "--"}
          </span>
        </div>
      </div>
      <div className={styles.svWrapper}>
        <div className={styles.unitContainer}>
          <select
            className={styles.flowMtrUnitSel}
            onClick={(e) => handleUnitChangeSv(e)}
          >
            <option>Unit:</option>
            <option>°C</option>
            <option>°F</option>
            <option>°K</option>
            <option>%</option>
            <option>Hz</option>
            <option>pH</option>
            <option>KW</option>
            <option>kWh</option>
            <option>W</option>
            <option>V</option>
            <option>KA</option>
            <option>A</option>
            <option>L/h</option>
            <option>MJ</option>
            <option>J</option>
            <option>m</option>
            <option>L</option>
            <option>kg/h</option>
            <option>T</option>
            <option>T/h</option>
            <option>%RH</option>
            <option>KPa</option>
            <option>MPa</option>
            <option>hPa</option>
            <option>Pa</option>
            <option>ppm</option>
            <option>m³</option>
            <option>m³/h</option>
            <option>min¯¹</option>
            <option>kg/cm²</option>
            <option>m3/min</option>
            <option>m3/s</option>
            <option>S m3/h</option>
            <option>S m3/min</option>
            <option>S m3/s C</option>
            <option>N m3/h</option>
            <option>N m3/min</option>
            <option>N m3/s C</option>
            <option>kg/min</option>
            <option>kg/s</option>
            <option>T/min</option>
            <option>T/s</option>
            <option>L/min</option>
            <option>L/s</option>
            <option>gm/h</option>
            <option>gm/min</option>
            <option>gm/s</option>
            <option>QF/h</option>
            <option>QF/min</option>
            <option>QF/s</option>
          </select>
        </div>
        <div className={styles.svValueWrapperF}>
          <span className={styles.svValue}>
            {responseSVChannel ? responseSVChannel.val : "--"}
          </span>
        </div>
      </div>

    </>
  );
};

const DeviceFlowMeter = ({
  name,
  openNewPage,
  tempTabIndexFlowM,
  index,
  activeTabIndex,
  clickedDeviceIndex,
  selectedDevice,
  tableData,
  channelName,
  currentPVChannel,
  currentSVChannel,
  images,
  id,
  unitPv,
  unitSv,
  isMobile,
  tempImages,
  setTempImages
}) => {
  const [oldPV, setOldPV] = useState();
  const [oldSV, setOldSV] = useState();

  useEffect(() => {
    const pvVal = tableData.find((data) => data.Header === channelName.PV);
    setOldPV(pvVal);
    const svVal = tableData.find((data) => data.Header === channelName.SV);
    setOldSV(svVal);

  }, [
    openNewPage,
    clickedDeviceIndex,
    tempTabIndexFlowM,
    activeTabIndex,
    channelName,
    selectedDevice,
    tableData,
    currentPVChannel,
    currentSVChannel,
    oldPV,
    oldSV,
  ]);

  const handleUnitChangePv = (e) => {
    const unitValue = e.target.value;

    const indexDevice = tempTabIndexFlowM[activeTabIndex];
    indexDevice[index].unit = unitValue;
    const originalDeviceIndex = images.findIndex((data) => data.id === id);
    const tempFlowM = [...tempImages];

    const updatedData = {
      ...tempFlowM[originalDeviceIndex],
      unitPv: unitValue
    }

    tempFlowM[originalDeviceIndex] = updatedData;
    setTempImages(tempFlowM);
  };

  const handleUnitChangeSv = (e) => {
    const unitValue = e.target.value;

    const indexDevice = tempTabIndexFlowM[activeTabIndex];
    indexDevice[index].unit = unitValue;
    const originalDeviceIndex = images.findIndex((data) => data.id === id);
    const tempFlowM = [...tempImages];

    const updatedData = {
      ...tempFlowM[originalDeviceIndex],
      unitSv: unitValue
    }

    tempFlowM[originalDeviceIndex] = updatedData;
    setTempImages(tempFlowM);
  };
  return (
    <>
      <div className={styles.deviceName}>{name}</div>
      <div className={styles.pvWrapper}>
        <div className={styles.unitContainer}>
          {!openNewPage && <div className={styles.unitValue}>{unitPv}</div>}
          {openNewPage && (
            <select
              defaultValue={unitPv}
              className={styles.flowMtrUnitSel}
              onChange={(e) => handleUnitChangePv(e)}
            >
              <>
                <option>Unit:</option>
                <option>°C</option>
                <option>°F</option>
                <option>°K</option>
                <option>%</option>
                <option>Hz</option>
                <option>pH</option>
                <option>KW</option>
                <option>kWh</option>
                <option>W</option>
                <option>V</option>
                <option>KA</option>
                <option>A</option>
                <option>L/h</option>
                <option>MJ</option>
                <option>J</option>
                <option>m</option>
                <option>L</option>
                <option>kg/h</option>
                <option>T</option>
                <option>T/h</option>
                <option>%RH</option>
                <option>KPa</option>
                <option>MPa</option>
                <option>hPa</option>
                <option>Pa</option>
                <option>ppm</option>
                <option>m³</option>
                <option>m³/h</option>
                <option>min¯¹</option>
                <option>kg/cm²</option>
                <option>m3/min</option>
                <option>m3/s</option>
                <option>S m3/h</option>
                <option>S m3/min</option>
                <option>S m3/s C</option>
                <option>N m3/h</option>
                <option>N m3/min</option>
                <option>N m3/s C</option>
                <option>kg/min</option>
                <option>kg/s</option>
                <option>T/min</option>
                <option>T/s</option>
                <option>L/min</option>
                <option>L/s</option>
                <option>gm/h</option>
                <option>gm/min</option>
                <option>gm/s</option>
                <option>QF/h</option>
                <option>QF/min</option>
                <option>QF/s</option>
              </>
            </select>
          )}
        </div>
        <div className={styles.pvValueWrapperF}>
          <span className={styles.pvValue}>
            {oldPV?.Header === channelName.PV ? oldPV?.val : "--"}
          </span>
        </div>
      </div>
      <div className={styles.svWrapper}>
        <div className={styles.unitContainer}>
          {!openNewPage && <div className={styles.unitValue}>{unitSv}</div>}
          {openNewPage && (
            <select
              defaultValue={unitSv}
              className={styles.flowMtrUnitSel}
              onChange={(e) => handleUnitChangeSv(e)}
            >
              <>
                <option>Unit:</option>
                <option>°C</option>
                <option>°F</option>
                <option>°K</option>
                <option>%</option>
                <option>Hz</option>
                <option>pH</option>
                <option>KW</option>
                <option>kWh</option>
                <option>W</option>
                <option>V</option>
                <option>KA</option>
                <option>A</option>
                <option>L/h</option>
                <option>MJ</option>
                <option>J</option>
                <option>m</option>
                <option>L</option>
                <option>kg/h</option>
                <option>T</option>
                <option>T/h</option>
                <option>%RH</option>
                <option>KPa</option>
                <option>MPa</option>
                <option>hPa</option>
                <option>Pa</option>
                <option>ppm</option>
                <option>m³</option>
                <option>m³/h</option>
                <option>min¯¹</option>
                <option>kg/cm²</option>
                <option>m3/min</option>
                <option>m3/s</option>
                <option>S m3/h</option>
                <option>S m3/min</option>
                <option>S m3/s C</option>
                <option>N m3/h</option>
                <option>N m3/min</option>
                <option>N m3/s C</option>
                <option>kg/min</option>
                <option>kg/s</option>
                <option>T/min</option>
                <option>T/s</option>
                <option>L/min</option>
                <option>L/s</option>
                <option>gm/h</option>
                <option>gm/min</option>
                <option>gm/s</option>
                <option>QF/h</option>
                <option>QF/min</option>
                <option>QF/s</option>
              </>
            </select>
          )}
        </div>
        <div className={styles.svValueWrapperF}>
          <span className={styles.svValue}>
            {oldSV?.Header === channelName.SV ? oldSV?.val : "--"}
          </span>
        </div>
      </div>

    </>
  );
};

const EnergyMeter = ({
  device,
  tableData,
  pvChannel,
  svChannel,
  svChannel2,
  svChannel3,
  multipleEnergyM,
  index,
  unitPv,
  unitSv,
  unitSv1,
  unitSv2
}) => {
  const [displayPVChannel, setDisplayPVChannel] = useState();
  const [displaySVChannel, setDisplaySVChannel] = useState();
  const [displaySVChannel2, setDisplaySVChannel2] = useState();
  const [displaySVChannel3, setDisplaySVChannel3] = useState();
  const [responsePVChannel, setResponsePVChannel] = useState();
  const [responseSVChannel, setResponseSVChannel] = useState();
  const [responseSVChannel2, setResponseSVChannel2] = useState();
  const [responseSVChannel3, setResponseSVChannel3] = useState();

  useEffect(() => {
    setDisplayPVChannel(pvChannel);
    setDisplaySVChannel(svChannel);
    setDisplaySVChannel2(svChannel2);
    setDisplaySVChannel3(svChannel3);

    let pvValue;
    let svValue;
    let svValue2;
    let svValue3;

    pvValue = tableData.find((data) => data.Header === displayPVChannel);
    setResponsePVChannel(pvValue);

    svValue = tableData.find((data) => data.Header === displaySVChannel);
    setResponseSVChannel(svValue);

    svValue2 = tableData.find((data) => data.Header === displaySVChannel2);
    setResponseSVChannel2(svValue2);

    svValue3 = tableData.find((data) => data.Header === displaySVChannel3);
    setResponseSVChannel3(svValue3);
  }, [
    responsePVChannel,
    responseSVChannel,
    displayPVChannel,
    displaySVChannel,
    pvChannel,
    svChannel,
    displaySVChannel2,
    displaySVChannel3,
    responseSVChannel2,
    responseSVChannel3,
    tableData
  ]);

  const handleUnitChangePv = (e) => {
    const unitValue = e.target.value;
    multipleEnergyM[index].unitPv = unitValue;
  };

  const handleUnitChangeSv = (e) => {
    const unitValue = e.target.value;
    multipleEnergyM[index].unitSv = unitValue;
  };

  const handleUnitChangeSv2 = (e) => {
    const unitValue = e.target.value;
    multipleEnergyM[index].unitSv2 = unitValue;
  };

  const handleUnitChangeSv3 = (e) => {
    const unitValue = e.target.value;
    multipleEnergyM[index].unitSv3 = unitValue;
  };
  return (
    <>
      <div className={styles.deviceName}>{device}</div>
      <div className={styles.container}>
        <div className={styles.pvWrapperEnergy}>
          <div className={styles.unitContainer}>
            <select name="" id="unitSelector" className={styles.flowMtrUnitSel} onChange={(e) => handleUnitChangePv(e)}>
              <option>Unit:</option>
              <option>°C</option>
              <option>°F</option>
              <option>°K</option>
              <option>%</option>
              <option>Hz</option>
              <option>pH</option>
              <option>KW</option>
              <option>kWh</option>
              <option>W</option>
              <option>V</option>
              <option>KA</option>
              <option>A</option>
              <option>L/h</option>
              <option>MJ</option>
              <option>J</option>
              <option>m</option>
              <option>L</option>
              <option>kg/h</option>
              <option>T</option>
              <option>T/h</option>
              <option>%RH</option>
              <option>KPa</option>
              <option>MPa</option>
              <option>hPa</option>
              <option>Pa</option>
              <option>ppm</option>
              <option>m³</option>
              <option>m³/h</option>
              <option>min¯¹</option>
              <option>kg/cm²</option>
              <option>m3/min</option>
              <option>m3/s</option>
              <option>S m3/h</option>
              <option>S m3/min</option>
              <option>S m3/s C</option>
              <option>N m3/h</option>
              <option>N m3/min</option>
              <option>N m3/s C</option>
              <option>kg/min</option>
              <option>kg/s</option>
              <option>T/min</option>
              <option>T/s</option>
              <option>L/min</option>
              <option>L/s</option>
              <option>gm/h</option>
              <option>gm/min</option>
              <option>gm/s</option>
              <option>QF/h</option>
              <option>QF/min</option>
              <option>QF/s</option>
            </select>
          </div>
          <div className={styles.pvValueWrapperF}>
            <span className={styles.pvValue}>
              {responsePVChannel ? responsePVChannel.val : "--"}
            </span>
          </div>
        </div>
        <div className={styles.svWrapperEnergy}>
          <div className={styles.unitContainer}>
            <select name="" id="unitSelector" className={styles.flowMtrUnitSel} onChange={(e) => handleUnitChangeSv(e)}>
              <option>Unit:</option>
              <option>°C</option>
              <option>°F</option>
              <option>°K</option>
              <option>%</option>
              <option>Hz</option>
              <option>pH</option>
              <option>KW</option>
              <option>kWh</option>
              <option>W</option>
              <option>V</option>
              <option>KA</option>
              <option>A</option>
              <option>L/h</option>
              <option>MJ</option>
              <option>J</option>
              <option>m</option>
              <option>L</option>
              <option>kg/h</option>
              <option>T</option>
              <option>T/h</option>
              <option>%RH</option>
              <option>KPa</option>
              <option>MPa</option>
              <option>hPa</option>
              <option>Pa</option>
              <option>ppm</option>
              <option>m³</option>
              <option>m³/h</option>
              <option>min¯¹</option>
              <option>kg/cm²</option>
              <option>m3/min</option>
              <option>m3/s</option>
              <option>S m3/h</option>
              <option>S m3/min</option>
              <option>S m3/s C</option>
              <option>N m3/h</option>
              <option>N m3/min</option>
              <option>N m3/s C</option>
              <option>kg/min</option>
              <option>kg/s</option>
              <option>T/min</option>
              <option>T/s</option>
              <option>L/min</option>
              <option>L/s</option>
              <option>gm/h</option>
              <option>gm/min</option>
              <option>gm/s</option>
              <option>QF/h</option>
              <option>QF/min</option>
              <option>QF/s</option>
            </select>
          </div>
          <div className={styles.svValueWrapperF}>
            <span className={styles.svValue}>
              {responseSVChannel ? responseSVChannel.val : "--"}
            </span>
          </div>
        </div>
        <div className={styles.svWrapperEnergy}>
          <div className={styles.unitContainer}>
            <select name="" id="unitSelector" className={styles.flowMtrUnitSel} onChange={(e) => handleUnitChangeSv2(e)}>
              <option>Unit:</option>
              <option>°C</option>
              <option>°F</option>
              <option>°K</option>
              <option>%</option>
              <option>Hz</option>
              <option>pH</option>
              <option>KW</option>
              <option>kWh</option>
              <option>W</option>
              <option>V</option>
              <option>KA</option>
              <option>A</option>
              <option>L/h</option>
              <option>MJ</option>
              <option>J</option>
              <option>m</option>
              <option>L</option>
              <option>kg/h</option>
              <option>T</option>
              <option>T/h</option>
              <option>%RH</option>
              <option>KPa</option>
              <option>MPa</option>
              <option>hPa</option>
              <option>Pa</option>
              <option>ppm</option>
              <option>m³</option>
              <option>m³/h</option>
              <option>min¯¹</option>
              <option>kg/cm²</option>
              <option>m3/min</option>
              <option>m3/s</option>
              <option>S m3/h</option>
              <option>S m3/min</option>
              <option>S m3/s C</option>
              <option>N m3/h</option>
              <option>N m3/min</option>
              <option>N m3/s C</option>
              <option>kg/min</option>
              <option>kg/s</option>
              <option>T/min</option>
              <option>T/s</option>
              <option>L/min</option>
              <option>L/s</option>
              <option>gm/h</option>
              <option>gm/min</option>
              <option>gm/s</option>
              <option>QF/h</option>
              <option>QF/min</option>
              <option>QF/s</option>
            </select>
          </div>
          <div className={styles.svValueWrapperF}>
            <span className={styles.svValue}>
              {responseSVChannel2 ? responseSVChannel2.val : "--"}
            </span>
          </div>
        </div>
        <div className={styles.svWrapperEnergy}>
          <div className={styles.unitContainer}>
            <select name="" id="unitSelector" className={styles.flowMtrUnitSel} onChange={(e) => handleUnitChangeSv3(e)}>
              <option>Unit:</option>
              <option>°C</option>
              <option>°F</option>
              <option>°K</option>
              <option>%</option>
              <option>Hz</option>
              <option>pH</option>
              <option>KW</option>
              <option>kWh</option>
              <option>W</option>
              <option>V</option>
              <option>KA</option>
              <option>A</option>
              <option>L/h</option>
              <option>MJ</option>
              <option>J</option>
              <option>m</option>
              <option>L</option>
              <option>kg/h</option>
              <option>T</option>
              <option>T/h</option>
              <option>%RH</option>
              <option>KPa</option>
              <option>MPa</option>
              <option>hPa</option>
              <option>Pa</option>
              <option>ppm</option>
              <option>m³</option>
              <option>m³/h</option>
              <option>min¯¹</option>
              <option>kg/cm²</option>
              <option>m3/min</option>
              <option>m3/s</option>
              <option>S m3/h</option>
              <option>S m3/min</option>
              <option>S m3/s C</option>
              <option>N m3/h</option>
              <option>N m3/min</option>
              <option>N m3/s C</option>
              <option>kg/min</option>
              <option>kg/s</option>
              <option>T/min</option>
              <option>T/s</option>
              <option>L/min</option>
              <option>L/s</option>
              <option>gm/h</option>
              <option>gm/min</option>
              <option>gm/s</option>
              <option>QF/h</option>
              <option>QF/min</option>
              <option>QF/s</option>
            </select>
          </div>
          <div className={styles.svValueWrapperF}>
            <span className={styles.svValue}>
              {responseSVChannel3 ? responseSVChannel3.val : "--"}
            </span>
          </div>
        </div>
      </div>

    </>
  );
};

const DeviceEnergyMeter = ({
  name,
  openNewPage,
  tempTabIndexEnergyM,
  activeTabIndex,
  clickedDeviceIndex,
  selectedDevice,
  tableData,
  channelName,
  currentPVChannel,
  currentSVChannel,
  images,
  index,
  id,
  unitPv,
  unitSv,
  unitSv2,
  unitSv3,
  tempImages,
  setTempImages
}) => {
  const [oldPV, setOldPV] = useState();
  const [oldSV, setOldSV] = useState();
  const [oldSV2, setOldSV2] = useState();
  const [oldSV3, setOldSV3] = useState();

  useEffect(() => {

    const pvVal = tableData.find((data) => data.Header === channelName.PV);
    setOldPV(pvVal);
    const svVal = tableData.find((data) => data.Header === channelName.SV);
    setOldSV(svVal);
    const svVal2 = tableData.find((data) => data.Header === channelName.SV2);
    setOldSV2(svVal2);
    const svVal3 = tableData.find((data) => data.Header === channelName.SV3);
    setOldSV3(svVal3);
  }, [
    openNewPage,
    clickedDeviceIndex,
    tempTabIndexEnergyM,
    activeTabIndex,
    channelName,
    selectedDevice,
    tableData,
    currentPVChannel,
    currentSVChannel,
    oldPV,
    oldSV,
    oldSV2,
    oldSV3,
  ]);

  const handleUnitChangePv = (e) => {
    const unitValue = e.target.value;
    const indexDevice = tempTabIndexEnergyM[activeTabIndex];
    indexDevice[index].unit = unitValue;
    const originalDeviceIndex = images.findIndex((data) => data.id === id);
    const tempEnergyM = [...tempImages];
    const updatedData = {
      ...tempEnergyM[originalDeviceIndex],
      unitPv: unitValue
    }
    tempEnergyM[originalDeviceIndex] = updatedData;
    setTempImages(tempEnergyM);
  };

  const handleUnitChangeSv = (e) => {
    const unitValue = e.target.value;
    const indexDevice = tempTabIndexEnergyM[activeTabIndex];
    indexDevice[index].unit = unitValue;
    const originalDeviceIndex = images.findIndex((data) => data.id === id);
    const tempEnergyM = [...tempImages];
    const updatedData = {
      ...tempEnergyM[originalDeviceIndex],
      unitSv: unitValue
    }
    tempEnergyM[originalDeviceIndex] = updatedData;
    setTempImages(tempEnergyM);
  };

  const handleUnitChangeSv2 = (e) => {
    const unitValue = e.target.value;
    const indexDevice = tempTabIndexEnergyM[activeTabIndex];
    indexDevice[index].unit = unitValue;
    const originalDeviceIndex = images.findIndex((data) => data.id === id);
    const tempEnergyM = [...tempImages];
    const updatedData = {
      ...tempEnergyM[originalDeviceIndex],
      unitSv2: unitValue
    }
    tempEnergyM[originalDeviceIndex] = updatedData;
    setTempImages(tempEnergyM);
  };

  const handleUnitChangeSv3 = (e) => {
    const unitValue = e.target.value;
    const indexDevice = tempTabIndexEnergyM[activeTabIndex];
    indexDevice[index].unit = unitValue;
    const originalDeviceIndex = images.findIndex((data) => data.id === id);
    const tempEnergyM = [...tempImages];
    const updatedData = {
      ...tempEnergyM[originalDeviceIndex],
      unitSv3: unitValue
    }
    tempEnergyM[originalDeviceIndex] = updatedData;
    setTempImages(tempEnergyM);
  };

  return (
    <>
      <div className={styles.deviceName}>{name}</div>
      <div className={styles.container}>
        <div className={styles.pvWrapperEnergy}>
          <div className={styles.unitContainer}>
            {!openNewPage && <div className={styles.unitValue}>{unitPv}</div>}
            {openNewPage && (
              <select
                defaultValue={unitPv}
                className={styles.flowMtrUnitSel}
                onChange={(e) => handleUnitChangePv(e)}
              >
                <>
                  <option>Unit:</option>
                  <option>°C</option>
                  <option>°F</option>
                  <option>°K</option>
                  <option>%</option>
                  <option>Hz</option>
                  <option>pH</option>
                  <option>KW</option>
                  <option>kWh</option>
                  <option>W</option>
                  <option>V</option>
                  <option>KA</option>
                  <option>A</option>
                  <option>L/h</option>
                  <option>MJ</option>
                  <option>J</option>
                  <option>m</option>
                  <option>L</option>
                  <option>kg/h</option>
                  <option>T</option>
                  <option>T/h</option>
                  <option>%RH</option>
                  <option>KPa</option>
                  <option>MPa</option>
                  <option>hPa</option>
                  <option>Pa</option>
                  <option>ppm</option>
                  <option>m³</option>
                  <option>m³/h</option>
                  <option>min¯¹</option>
                  <option>kg/cm²</option>
                  <option>m3/min</option>
                  <option>m3/s</option>
                  <option>S m3/h</option>
                  <option>S m3/min</option>
                  <option>S m3/s C</option>
                  <option>N m3/h</option>
                  <option>N m3/min</option>
                  <option>N m3/s C</option>
                  <option>kg/min</option>
                  <option>kg/s</option>
                  <option>T/min</option>
                  <option>T/s</option>
                  <option>L/min</option>
                  <option>L/s</option>
                  <option>gm/h</option>
                  <option>gm/min</option>
                  <option>gm/s</option>
                  <option>QF/h</option>
                  <option>QF/min</option>
                  <option>QF/s</option>
                </>
              </select>
            )}
          </div>
          <div className={styles.pvValueWrapperF}>
            <span className={styles.pvValue}>
              {oldPV?.Header === channelName.PV ? oldPV?.val : "--"}
            </span>
          </div>
        </div>
        <div className={styles.svWrapperEnergy}>
          <div className={styles.unitContainer}>
            {!openNewPage && <div className={styles.unitValue}>{unitSv}</div>}
            {openNewPage && (
              <select
                defaultValue={unitSv}
                className={styles.flowMtrUnitSel}
                onChange={(e) => handleUnitChangeSv(e)}
              >
                <>
                  <option>Unit:</option>
                  <option>°C</option>
                  <option>°F</option>
                  <option>°K</option>
                  <option>%</option>
                  <option>Hz</option>
                  <option>pH</option>
                  <option>KW</option>
                  <option>kWh</option>
                  <option>W</option>
                  <option>V</option>
                  <option>KA</option>
                  <option>A</option>
                  <option>L/h</option>
                  <option>MJ</option>
                  <option>J</option>
                  <option>m</option>
                  <option>L</option>
                  <option>kg/h</option>
                  <option>T</option>
                  <option>T/h</option>
                  <option>%RH</option>
                  <option>KPa</option>
                  <option>MPa</option>
                  <option>hPa</option>
                  <option>Pa</option>
                  <option>ppm</option>
                  <option>m³</option>
                  <option>m³/h</option>
                  <option>min¯¹</option>
                  <option>kg/cm²</option>
                  <option>m3/min</option>
                  <option>m3/s</option>
                  <option>S m3/h</option>
                  <option>S m3/min</option>
                  <option>S m3/s C</option>
                  <option>N m3/h</option>
                  <option>N m3/min</option>
                  <option>N m3/s C</option>
                  <option>kg/min</option>
                  <option>kg/s</option>
                  <option>T/min</option>
                  <option>T/s</option>
                  <option>L/min</option>
                  <option>L/s</option>
                  <option>gm/h</option>
                  <option>gm/min</option>
                  <option>gm/s</option>
                  <option>QF/h</option>
                  <option>QF/min</option>
                  <option>QF/s</option>
                </>
              </select>
            )}
          </div>
          <div className={styles.svValueWrapperF}>
            <span className={styles.svValue}>
              {oldSV?.Header === channelName.SV ? oldSV?.val : "--"}
            </span>
          </div>
        </div>
        <div className={styles.svWrapperEnergy}>
          <div className={styles.unitContainer}>
            {!openNewPage && <div className={styles.unitValue}>{unitSv2}</div>}
            {openNewPage && (
              <select
                defaultValue={unitSv2}
                className={styles.flowMtrUnitSel}
                onChange={(e) => handleUnitChangeSv2(e)}
              >
                <>
                  <option>Unit:</option>
                  <option>°C</option>
                  <option>°F</option>
                  <option>°K</option>
                  <option>%</option>
                  <option>Hz</option>
                  <option>pH</option>
                  <option>KW</option>
                  <option>kWh</option>
                  <option>W</option>
                  <option>V</option>
                  <option>KA</option>
                  <option>A</option>
                  <option>L/h</option>
                  <option>MJ</option>
                  <option>J</option>
                  <option>m</option>
                  <option>L</option>
                  <option>kg/h</option>
                  <option>T</option>
                  <option>T/h</option>
                  <option>%RH</option>
                  <option>KPa</option>
                  <option>MPa</option>
                  <option>hPa</option>
                  <option>Pa</option>
                  <option>ppm</option>
                  <option>m³</option>
                  <option>m³/h</option>
                  <option>min¯¹</option>
                  <option>kg/cm²</option>
                  <option>m3/min</option>
                  <option>m3/s</option>
                  <option>S m3/h</option>
                  <option>S m3/min</option>
                  <option>S m3/s C</option>
                  <option>N m3/h</option>
                  <option>N m3/min</option>
                  <option>N m3/s C</option>
                  <option>kg/min</option>
                  <option>kg/s</option>
                  <option>T/min</option>
                  <option>T/s</option>
                  <option>L/min</option>
                  <option>L/s</option>
                  <option>gm/h</option>
                  <option>gm/min</option>
                  <option>gm/s</option>
                  <option>QF/h</option>
                  <option>QF/min</option>
                  <option>QF/s</option>
                </>
              </select>
            )}
          </div>
          <div className={styles.svValueWrapperF}>
            <span className={styles.svValue}>
              {oldSV2?.Header === channelName.SV2 ? oldSV2?.val : "--"}
            </span>
          </div>
        </div>
        <div className={styles.svWrapperEnergy}>
          <div className={styles.unitContainer}>
            {!openNewPage && <div className={styles.unitValue}>{unitSv3}</div>}
            {openNewPage && (
              <select
                defaultValue={unitSv3}
                className={styles.flowMtrUnitSel}
                onChange={(e) => handleUnitChangeSv3(e)}
              >
                <>
                  <option>Unit:</option>
                  <option>°C</option>
                  <option>°F</option>
                  <option>°K</option>
                  <option>%</option>
                  <option>Hz</option>
                  <option>pH</option>
                  <option>KW</option>
                  <option>kWh</option>
                  <option>W</option>
                  <option>V</option>
                  <option>KA</option>
                  <option>A</option>
                  <option>L/h</option>
                  <option>MJ</option>
                  <option>J</option>
                  <option>m</option>
                  <option>L</option>
                  <option>kg/h</option>
                  <option>T</option>
                  <option>T/h</option>
                  <option>%RH</option>
                  <option>KPa</option>
                  <option>MPa</option>
                  <option>hPa</option>
                  <option>Pa</option>
                  <option>ppm</option>
                  <option>m³</option>
                  <option>m³/h</option>
                  <option>min¯¹</option>
                  <option>kg/cm²</option>
                  <option>m3/min</option>
                  <option>m3/s</option>
                  <option>S m3/h</option>
                  <option>S m3/min</option>
                  <option>S m3/s C</option>
                  <option>N m3/h</option>
                  <option>N m3/min</option>
                  <option>N m3/s C</option>
                  <option>kg/min</option>
                  <option>kg/s</option>
                  <option>T/min</option>
                  <option>T/s</option>
                  <option>L/min</option>
                  <option>L/s</option>
                  <option>gm/h</option>
                  <option>gm/min</option>
                  <option>gm/s</option>
                  <option>QF/h</option>
                  <option>QF/min</option>
                  <option>QF/s</option>
                </>
              </select>
            )}
          </div>
          <div className={styles.svValueWrapperF}>
            <span className={styles.svValue}>
              {oldSV3?.Header === channelName.SV3 ? oldSV3?.val : "--"}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export {
  Devices,
  Indicator,
  Controller,
  DeviceController,
  DigitalIO,
  DeviceIO,
  FlowMeter,
  DeviceFlowMeter,
  EnergyMeter,
  DeviceEnergyMeter,
  Display,
  DeviceDisplay
};
