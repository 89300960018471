import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import proxy from "proxy";

const initialState = {
  isLoading: false,
  isError: false,
  feedBackMsg: null,
};

export const sendFeedback = createAsyncThunk("sendFeedback", async (data) => {
  
  const response = await fetch(`${proxy}/api/feedback`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${data.token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  console.log(response)
  const responseBody = await response.json();
  return responseBody;
});

export const feedBackSlice = createSlice({
  name: "feedback",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(sendFeedback.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });

    builder.addCase(sendFeedback.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.feedBackMsg = action.payload;
    });

    builder.addCase(sendFeedback.rejected , (state, action) => {
        state.isLoading = false;
        state.isError = true;
    });
  },
});
