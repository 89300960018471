import React from "react";
import styles from "./ConfirmTransfer.module.css";
import proxy from "proxy";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";


function ConfirmTransfer({setPageTitle}) {

  const toast=useToast()
  const location=useLocation();
  const navigate=useNavigate()
  const token=location.search.replace(/\?token=/g,'');
  async function handleClick(event){
    if(event.target.innerHTML=="Confirm"){
      const response=await fetch(proxy + `/dangerzone/confirm-transfer?token=${token}`);
      const data=await response.json();
      if(data.code==200){
        toast({
          title: "Successfully Transfer Project",
          description: "SuccessFully Transfer to ",
          status: "success",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        })
        navigate('/')
      }
      else{
        toast({title: "Error",
          description: data.message,
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true});
          navigate('/auth/login')
      }
    }
    else if(event.target.innerHTML=="Decline"){
    const options={
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: localStorage.getItem('email'),
        token:token,
      }),
    }
      const response=await fetch(proxy+'/api/declineConfirmTransferProject',options);
      const data=await response.json();
      navigate('/auth/login')
    }
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.bodyWrapper}>
          <div className={styles.headers}>
            <h2 className={styles.header2}>Confirm Transfer Project</h2>
          </div>
          <div>
          <p className={styles.paragraph}> Do you want to confirm the transfer of the project to Jalish? </p>
          </div>
          <div className={styles.body}>
            <button className={styles.confirmBtn} onClick={handleClick}>Confirm</button>
            <button className={styles.declineBtn} onClick={handleClick}>Decline</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmTransfer;
