import React, { useEffect, useState } from "react";
import styles from "./HamburgerModel.module.css";
import ArrowRight from "../../../assets/ArrowRight.svg";
import close from "../../../assets/Close.svg";

const NotificationHamburgerButton = ({ setIsHamburgerModal }) => {
  const [activeColor, setActiveColor] = useState("");
  const [projectContextMenuVisible, setProjectContextMenuVisible] =
    useState(false);
  const [alarmContextMenuVisible, setAlarmContextMenuVisible] = useState(false);
  const [batchesContextMenuVisible, setBatchesContextMenuVisible] =
    useState(false);
  const [organizationContextMenuVisible, setOrganizationContextMenuVisible] =
    useState(false);

  const handleProjectContextMenu = (event) => {
    event.preventDefault();
    setActiveColor("Projects");
    setProjectContextMenuVisible(true);
  };

  useEffect(() => {
    if (activeColor === "Projects") {
      setProjectContextMenuVisible(true);
    } else {
      setProjectContextMenuVisible(false);
    }

    if (activeColor === "Alarms") {
      setAlarmContextMenuVisible(true);
    } else {
      setAlarmContextMenuVisible(false);
    }
    if (activeColor === "Batches") {
      setBatchesContextMenuVisible(true);
    } else {
      setBatchesContextMenuVisible(false);
    }
    if (activeColor === "Organization") {
      setOrganizationContextMenuVisible(true);
    } else {
      setOrganizationContextMenuVisible(false);
    }
  }, [activeColor]);

  const handleAlarmContextMenu = (event) => {
    event.preventDefault();
    setActiveColor("Alarms");
    setAlarmContextMenuVisible(true);
  };

  const handleBatchesContextMenu = (event) => {
    event.preventDefault();
    setActiveColor("Batches");
    setBatchesContextMenuVisible(true);
  };

  const handleOrganizationContextMenu = (event) => {
    event.preventDefault();
    setActiveColor("Organization");
    setOrganizationContextMenuVisible(true);
  };

  const handleCloseProjectContextMenu = () => {
    setActiveColor("");
    setProjectContextMenuVisible(false);
  };

  const handleCloseAlarmContextMenu = () => {
    setActiveColor("");
    setAlarmContextMenuVisible(false);
  };

  const handleCloseBatchesContextMenu = () => {
    setActiveColor("");
    setBatchesContextMenuVisible(false);
  };

  const handleCloseOrganizationContextMenu = () => {
    setActiveColor("");
    setOrganizationContextMenuVisible(false);
  };

  useEffect(() => {
    if (
      activeColor === "Projects" ||
      activeColor === "Alarms" ||
      activeColor === "Batches" ||
      activeColor === "Organization"
    ) {
      document.addEventListener("click", handleCloseProjectContextMenu);
      document.addEventListener("click", handleCloseAlarmContextMenu);
      document.addEventListener("click", handleCloseBatchesContextMenu);
      document.addEventListener("click", handleCloseOrganizationContextMenu);
      return () => {
        document.removeEventListener("click", handleCloseProjectContextMenu);
        document.removeEventListener("click", handleCloseAlarmContextMenu);
        document.removeEventListener("click", handleCloseBatchesContextMenu);
        document.removeEventListener(
          "click",
          handleCloseOrganizationContextMenu
        );
      };
    }
  }, [activeColor]);

  return (
    <>
      <div className={styles.overLay}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <h1 className={styles.type}>Type</h1>
            <button
              className={styles.closeBtn}
              onClick={() => setIsHamburgerModal(false)}
            >
              <img src={close} alt="" className={styles.closeImg} />
            </button>
          </div>
          <div className={styles.modalContent}>
            <div className={styles.nameContext}>
              <div>
                <button
                  className={styles.nameArrow}
                  onContextMenu={handleProjectContextMenu}
                  style={{
                    background:
                      activeColor === "Projects"
                        ? "rgba(228, 230, 255, 1)"
                        : "white",
                  }}
                >
                  <div className={styles.name}>Projects</div>
                  <div>
                    <img src={ArrowRight} alt="" className={styles.arrowImg} />
                  </div>
                </button>
              </div>
            </div>
            <div className={styles.nameContext}>
              <div>
                <button
                  className={styles.nameArrow}
                  onContextMenu={handleAlarmContextMenu}
                  style={{
                    background:
                      activeColor === "Alarms"
                        ? "rgba(228, 230, 255, 1)"
                        : "white",
                  }}
                >
                  <div className={styles.name}>Alarms</div>
                  <div>
                    <img src={ArrowRight} alt="" className={styles.arrowImg} />
                  </div>
                </button>
              </div>
            </div>
            <div className={styles.nameContext}>
              <div>
                <button
                  className={styles.nameArrow}
                  onContextMenu={handleBatchesContextMenu}
                  style={{
                    background:
                      activeColor === "Batches"
                        ? "rgba(228, 230, 255, 1)"
                        : "white",
                  }}
                >
                  <div className={styles.name}>Batches</div>
                  <div>
                    <img src={ArrowRight} alt="" className={styles.arrowImg} />
                  </div>
                </button>
              </div>
            </div>
            <div className={styles.nameContext}>
              <div>
                <button
                  className={styles.nameArrow}
                  onContextMenu={handleOrganizationContextMenu}
                  style={{
                    background:
                      activeColor === "Organization"
                        ? "rgba(228, 230, 255, 1)"
                        : "white",
                  }}
                >
                  <div className={styles.name}>Organization</div>
                  <div>
                    <img src={ArrowRight} alt="" className={styles.arrowImg} />
                  </div>
                </button>
              </div>
            </div>
            <div className={styles.name}>
              <button
                className={styles.btn}
                onClick={() => setActiveColor("2stepAuthentication")}
                style={{
                  background:
                    activeColor === "2stepAuthentication"
                      ? "rgba(228, 230, 255, 1)"
                      : "white",
                }}
              >
                <div className={styles.size}>2 Step Authentication</div>
              </button>
            </div>
            <div className={styles.name}>
              <button
                className={styles.btn}
                onClick={() => setActiveColor("LoginDetails")}
                style={{
                  background:
                    activeColor === "LoginDetails"
                      ? "rgba(228, 230, 255, 1)"
                      : "white",
                }}
              >
                <div className={styles.size}>Login Details</div>
              </button>
            </div>
            <div className={styles.name}>
              <button
                className={styles.btn}
                onClick={() => setActiveColor("Billing")}
                style={{
                  background:
                    activeColor === "Billing"
                      ? "rgba(228, 230, 255, 1)"
                      : "white",
                }}
              >
                <div className={styles.size}>Billing</div>
              </button>
            </div>
          </div>
        </div>
      </div>

      {projectContextMenuVisible && (
        <div className={styles.projectContextMenu}>
          <ul className={styles.individual}>
            <li>Edit</li>
            <li>Add</li>
            <li>Transfer</li>
            <li>Update</li>
            <li>Delete</li>
          </ul>
        </div>
      )}
      {alarmContextMenuVisible && (
        <div className={styles.alarmContextMenu}>
          <ul className={styles.individual}>
            <li>Add</li>
            <li>Update</li>
            <li>Delete</li>
            <li>stop</li>
          </ul>
        </div>
      )}
      {batchesContextMenuVisible && (
        <div className={styles.batchesContextMenu}>
          <ul className={styles.individual}>
            <li>Add</li>
            <li>Update</li>
            <li>Delete</li>
            <li>Channel Offline</li>
          </ul>
        </div>
      )}
      {organizationContextMenuVisible && (
        <div className={styles.organizationContextMenu}>
          <ul className={styles.individual}>
            <li>Permission Edit</li>
            <li>Accepted invite</li>
            <li>Declined invite</li>
            <li>Transfer</li>
            <li>Delete</li>
          </ul>
        </div>
      )}
    </>
  );
};

export default NotificationHamburgerButton;
