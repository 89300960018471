import React, { useState, useMemo, useEffect } from "react";
import styles from "./DeleteConfirmationModel.module.css";
import close from "assets/close.png";
import openEye from "assets/openEye.png";
import { Toast, useToast } from "@chakra-ui/react";
import proxy from "proxy";
import closeEye from "assets/closeEye.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteAccount, logoutAllDevice, toggleTwoStep, updateProfile } from "../../../../redux/slices/profileSlice.js";

const DeleteModelProfile = ({ setIsToggle, setOpenModel, isToggle, setDeleteModel, header, type, toggleAuth, logOutBody, setIsPasswordModal, setConfirmPwd, setConfirmPwdLogOut, updateProfileInfo }) => {
  const [error, setError] = useState(null);
  const toast = useToast();
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const deleteAccMsg = useSelector((state) => state.profile.deleteAccMsg);
  const dispatch = useDispatch();
  const handleSave = async () => {
    if (type === "delete") {
      const apiBody = {
        password: password.trim(),
        email: localStorage.getItem("email")?.trim(),
      }
      dispatch(deleteAccount(apiBody)).then((response) => {
        if (response.payload.status === 200) {
          toast({
            title: "Successfully",
            description: response.payload.message,
            status: "success",
            position: "top-right",
            duration: 1000,
            isClosable: true,
          });
          navigate("/auth/register");
          localStorage.removeItem("token");
        } else {
          toast({
            title: "Failed",
            description: response.payload.message,
            status: "error",
            position: "top-right",
            duration: 1000,
            isClosable: true,
          });
        }
      });
      setDeleteModel(false);
    }
    if (type === "logout") {

      const data = { ...logOutBody }
      data.password = password.trim()

      localStorage.clear();

      dispatch(logoutAllDevice(data)).then((response) => {
        if (response.payload.status === 200) {
          toast({
            title: "Successfully",
            description: response.payload.message,
            status: "success",
            position: "top-right",
            duration: 1000,
            isClosable: true,
          });
          navigate("/auth/login");
          localStorage.removeItem("token");

        } else {
          toast({
            title: "Failed",
            description: response.payload.message,
            status: "error",
            position: "top-right",
            duration: 1000,
            isClosable: true,
          });
        }
      });
      setConfirmPwdLogOut(false);
    }
    if (type === "toggle") {
      setConfirmPwd(true);

      const toggledata = { ...toggleAuth }
      toggledata.password = password.trim()

      dispatch(toggleTwoStep(toggledata)).then((response) => {
        if (response.payload.status === 200) {
          toast({
            title: "Successfully",
            description: response.payload.message,
            status: "success",
            position: "top-right",
            duration: 1000,
            isClosable: true,
          });
          setIsToggle(!isToggle);

        } else {
          toast({
            title: "Failed",
            description: response.payload.message,
            status: "error",
            position: "top-right",
            duration: 1000,
            isClosable: true,
          });
        }
      });
      setConfirmPwd(false);
    };


    if (type === "updateProfilePwd") {
      const token = localStorage.getItem("token");

      const infoData = { ...updateProfileInfo }
      infoData.password = password.trim()

      dispatch(updateProfile({ token: token, valuesToUpdate: infoData })).then((response) => {
       
        if (response.payload.status === 200) {
          toast({
            title: "Success",
            description: "Profile updated successfully",
            status: "success",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Failed",
            description: "Couldn't update the profile",
            status: "error",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
        }
      });
      setIsPasswordModal(false);
      setOpenModel(false);
    }
  }

  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    const pwInput = document.getElementById("pwd");
    pwInput.type = isPasswordVisible ? "password" : "text";
    setIsPasswordVisible(!isPasswordVisible);
  };
  const handleCloseModel = () => {
    if (type === "delete") {
      setDeleteModel(false);
    }
    if (type === "logout") {
      setConfirmPwdLogOut(false);
    }
    if (type === "toggle") {
      setConfirmPwd(false);
    }
    if (type === "updateProfilePwd") {
      setIsPasswordModal(false);
    }
  };

  const changeHandle = (event) => {
    setPassword(event.target.value);
  };
  return (
    <div className={styles.modelProfile}>
      <div className={styles.modelWrapper}>
        <div className={styles.modelHeader}>
          <h3 className={styles.headerText}>
            {header}
          </h3>
          <button className={styles.closeBtn} onClick={handleCloseModel}>
            <img src={close} alt="" />
          </button>
        </div>
        <div className={styles.inputWrapper}>
          <input
            onChange={changeHandle}
            className={styles.countrySelector}
            type="password"
            id="pwd"
            placeholder="Enter your password"
          />
          <div
            className={styles.pwVisibilityToggle}
            onClick={togglePasswordVisibility}
          >
            {!isPasswordVisible ? (
              <img src={closeEye} alt="Close Eye" srcSet="" className={styles.eyeImg}/>
            ) : (
              <img src={openEye} alt="Open Eye" srcSet="" className={styles.eyeImg}/>
            )}
          </div>
        </div>
        <button className={styles.editBtn} onClick={handleSave}>
          Confirm
        </button>

        {error && <p style={{ color: "red" }}>{error}</p>}
      </div>
    </div>
  );
};

export default DeleteModelProfile;
