import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import proxy from 'proxy';

const initialState = {
  projectData: [],
  isLoading: false,
  isError: false,
  addProjectMessage: null,
};

// Actions

// Get Projects Data
export const fetchProjectData = createAsyncThunk(
  'fetchProjectData',
  async (data) => {
    const response = await fetch(`${proxy}/api/getprojects`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${data.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const responseBody = await response.json();
    return responseBody;
  },
);

// Add Project Action
export const addProject = createAsyncThunk('addProject', async (project) => {
  const { token, newProject, orgEmail } = project;
  const response = await fetch(`${proxy}/api/addProject`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: token,
      orgEmail : orgEmail,
      projects: [
        {
          projectName: newProject.projectName,
          projectOnlineStatus: newProject.projectOnlineStatus,
          location: newProject.location,
          desc: newProject.desc,
        },
      ],
    }),
  });
  const responseMessage = await response.json();
  return { responseMessage, newProject };
});

export const projectDataSlice = createSlice({
  name: 'projectData',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchProjectData.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });

    // Fetch Projects
    builder.addCase(fetchProjectData.fulfilled, (state, action) => {
      const draft =
        action.payload.data &&
        action.payload.data.map((value) => {
          const days = Math.round(
            (new Date().getTime() - Number(value.LastModified)) /
              (1000 * 60 * 60 * 24),
          );
          return {
            projectName: value.metadata.Project,
            uniqueProjectId: value.Project_id,
            location: value.metadata.Location,
            desc: value.metadata.Description,
            LastModified: {
              time: new Date(Number(value.LastModified)).toISOString(),
              day: days.toString(),
            },
            projectOnlineStatus: value.metadata.isVerified,
            admin: value.admin,
          };
        });

      state.projectData = draft;
      state.isLoading = false;
      state.isError = false;
    });

    builder.addCase(fetchProjectData.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });

    // Add Project
    builder.addCase(addProject.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(addProject.fulfilled, (state, action) => {
      if (action.payload.responseMessage.status === 200) {
        const newProject = {
          projectName: action.payload.newProject.projectName,
          projectOnlineStatus: action.payload.newProject.projectOnlineStatus,
          location: action.payload.newProject.location,
          desc: action.payload.newProject.desc,
          uniqueProjectId: action.payload.responseMessage.uuid,
        };

        if (state.projectData !== undefined) {
          state.projectData.push(newProject);
        } else {
          state.projectData = [newProject];
        }
        state.isLoading = false;
      } else {
        state.isError = true;
        
      } 
      state.addProjectMessage = action.payload.responseMessage;
    });
    builder.addCase(addProject.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.addProjectMessage = action.payload.responseMessage;
    });
  },
});

export default projectDataSlice.reducer;
