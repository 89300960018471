import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

const BarChart = ({
  dateTimesLive,
  dataArrLive,
  getRandomColor,
  chartChannel,
  selectedTabIndex,
}) => {
  const [xMin, setXMin] = useState(0);
  const [xMax, setXmax] = useState(100);
  const [barChartData, setBarChartData] = useState([]);

  ChartJS.register(ArcElement, Tooltip, Legend);

  useEffect(() => {
    const availableChannels = dataArrLive.filter(
      (data, index) => data.data.slice(-1)[0] !== null
    );

    //available channel names
    const availableChannelsNames = availableChannels.map((data) => data.label);

    const lastValues = availableChannelsNames.map(
      (header, index) => dataArrLive[index].data.slice(-1)[0]
    );

    let channelsLastValues;
    channelsLastValues =
      chartChannel?.length > 0
        ? chartChannel.map(
            (chart, index) => availableChannels[index]?.data.slice(-1)[0]
          )
        : lastValues;
    const newPieChartData = {
      labels: chartChannel?.length > 0 ? chartChannel : availableChannelsNames,
      datasets: [
        {
          label: "Available Channels",
          data: channelsLastValues,
          backgroundColor: dataArrLive.map(() => getRandomColor()),
        },
      ],
    };

    setBarChartData(newPieChartData);
  }, [dateTimesLive, selectedTabIndex, chartChannel]);

  return (
    <div>
      {barChartData && barChartData.labels ? (
        <Bar data={barChartData} />
      ) : (
        <p>Loading chart data...</p>
      )}
    </div>
  );
};

const NewBarChart = ({
  pieChartData,
  openNewPage,
  dateTimesLive,
  channelNames,
  dataArrLive,
  getRandomColor,
  setPieChartData,
  chartChannel,
  tabIndexPieChart,
  selectedTabIndex,
}) => {
  const [newChartData, setNewChartData] = useState([]);
  ChartJS.register(ArcElement, Tooltip, Legend);
  useEffect(() => {
    const availableChannels = dataArrLive.filter(
      (data, index) => data.data.slice(-1)[0] !== null
    );

    //available channel names
    const availableChannelsNames = availableChannels.map((data) => data.label);

    const lastValues =
      chartChannel !== undefined
        ? chartChannel.map(
            (header, index) => availableChannels[index].data.slice(-1)[0]
          )
        : availableChannels.map((header, index) => header.data.slice(-1)[0]);

    let dataSetLabels = [];
    chartChannel !== undefined
      ? chartChannel.map((item) => dataSetLabels.push(item))
      : availableChannelsNames.map((item) => dataSetLabels.push(item));
    const newPieChartData = {
      labels: dataSetLabels,
      datasets: [
        {
          label: "Available Channels",
          data: lastValues,
          backgroundColor: dataArrLive.map(() => getRandomColor()),
        },
      ],
    };

    setNewChartData(newPieChartData);
  }, [dateTimesLive, selectedTabIndex, chartChannel]);

  return (
    <div>
      {newChartData && newChartData.labels ? (
        <Bar data={newChartData} />
      ) : (
        <p>Loading chart data...</p>
      )}
    </div>
  );
};

export { BarChart, NewBarChart };
