import React, { useEffect, useState } from 'react';
import styles from "./adminUserModal.module.css";
import close from "../../../../assets/Close.svg";
import proxy from '../../../../proxy';
import { useToast } from '@chakra-ui/react';


const AdminUserModal = ({ modelTitle, setIsModal, selectedUser, setUserList }) => {

    const [fullName, setFullname] = useState("");
    const [email, setEmail] = useState("");
    const toast = useToast();

    useEffect(() => {
        if (modelTitle === "Edit") {
            if (selectedUser) {
                setFullname(selectedUser?.name);
                setEmail(selectedUser?.userEmail);
            }
        }
    }, []);


    const handleSaveModal = async () => {
        if (modelTitle === "Add") {
            const newUser = {
                name: fullName,
                userEmail: email,
                email: localStorage.getItem("adminEmail"),
            };

            const response = await fetch(`${proxy}/admin/addUser`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(newUser),
            });
            const status = response.status;
            const responseBody = await response.json();

            if (status === 200) {
                toast({
                    title: responseBody.message || "Successfully added user",
                    status: "success",
                    position: "top-right",
                    duration: 5000,
                    isClosable: true,
                });
                setUserList(responseBody?.data);
            } else {
                toast({
                    title: responseBody.message || "Error while add user",
                    status: "error",
                    position: "top-right",
                    duration: 5000,
                    isClosable: true,
                });
            }
        }

        if (modelTitle === "Edit") {
            const userToUpdate = {
                name: fullName,
                email: localStorage.getItem("adminEmail"),
                id: selectedUser?._id,
                newEmail: email,
            };

            const response = await fetch(`${proxy}/admin/updateUser`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(userToUpdate),
            });

            const status = response.status;
            const responseBody = await response.json();

            if (status === 200) {
                toast({
                    title: responseBody.message || "Successfully updated user",
                    status: "success",
                    position: "top-right",
                    duration: 5000,
                    isClosable: true,
                });
                setUserList(responseBody?.data);
            } else {
                toast({
                    title: responseBody.message || "Error while update user",
                    status: "error",
                    position: "top-right",
                    duration: 5000,
                    isClosable: true,
                });
            }
        }

        setIsModal(false);
    };


    

    return (
        <div className={styles.model}>
            <div className={styles.container}>
                <div className={styles.headerWrapper}>
                    <label className={styles.titleText}>
                        {modelTitle === "Add" ? "Add User" : "Edit User"}
                    </label>
                    <img
                        src={close}
                        alt="close"
                        className={styles.closeImg}
                        onClick={() => setIsModal(false)}
                    />
                </div>
                <input
                    type="text"
                    placeholder="Name"
                    className={styles.modalInput}
                    defaultValue={modelTitle === "Add" ? "" : selectedUser?.name}
                    onChange={(e) => setFullname(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Email"
                    className={styles.modalInput}
                    defaultValue={
                        modelTitle === "Add" ? "" : selectedUser?.userEmail
                    }
                    onChange={(e) => setEmail(e.target.value)}
                />
                <button className={styles.saveBtn} onClick={handleSaveModal}>
                    Save
                </button>
            </div>
        </div>
    )
}

export default AdminUserModal;