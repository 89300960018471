import React, { useEffect, useState } from 'react';
import styles from "./AdminUser.module.css";
import Edit from "../../../assets/Edit.svg";
import deleteIcon from "../../../assets/Delete.svg";
import dicotLogo from "../../../assets/Logo.svg";
import AdminUserModal from './components/adminUserModal';
import { useToast } from '@chakra-ui/react';
import proxy from '../../../proxy';
import PlanModel from './components/PlanModel';

const AdminUser = () => {
    const [isModal, setIsModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState();
    const [modelTitle, setModelTitle] = useState('');
    const [userList, setUserList] = useState([]);
    const toast = useToast();
    const [customerId, setCustomerId] = useState('');
    const [operation, setOperation] = useState("");
    const [isRenewModal, setIsRenewModal] = useState(false);
    const [selectedUserEmail, setSelectedUserEmail] = useState("");


    useEffect(() => {
        const email = localStorage.getItem("adminEmail");
        const getUsers = async () => {
            if (email) {
                const response = await fetch(
                    `${proxy}/admin/getUsers?email=${email}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                const status = response.status;
                const responseBody = await response.json();

                if (status === 200) {
                    setUserList(responseBody.data);
                    setCustomerId(responseBody.customerId);
                }
            }
        };

        getUsers();
    }, []);


    const handleDeleteUser = async (e, id) => {
        e.preventDefault();

        const userTodelete = {
            id: id,
            email: localStorage.getItem("adminEmail"),
        };

        const response = await fetch(`${proxy}/admin/deleteUser`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(userTodelete),
        });

        const status = response.status;

        const responseBody = await response.json();

        if (status === 200) {
            toast({
                title: responseBody.message || "Successfully delete user",
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
            });
            setUserList(responseBody.data);
        } else {
            toast({
                title: responseBody.message || "Error while delete user",
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleAddModalOpen = () => {
        setIsModal(true);
        setModelTitle("Add")
    }

    const handleEditModalOpen = (e, user) => {
        e.preventDefault();
        setIsModal(true);
        setSelectedUser(user);
        setModelTitle("Edit")
    }

    const handleOpenRenewModal = (
        e,
        email,
        operationValue,
        user
    ) => {
        const discount = localStorage.getItem("discount");

        if (discount === "5%") {
            localStorage.setItem("Multiplier", "0.95");
        } else if (discount === "15%") {
            localStorage.setItem("Multiplier", "0.85");
        } else if (discount === "25%") {
            localStorage.setItem("Multiplier", "0.75");
        } else if (discount === "35%") {
            localStorage.setItem("Multiplier", "0.65");
        } else if (discount === "45%") {
            localStorage.setItem("Multiplier", "0.55");
        }
        setIsRenewModal(true);
        setSelectedUserEmail(email);
        setOperation(operationValue);
        setSelectedUser(user);
    };

    return (
        <>
            <div className={styles.page}>
                <div className={styles.topWrapper}>
                    <div className={styles.logoWrapper}>
                        <img src={dicotLogo} alt="Logo" className={styles.dicotLogo} />
                        <label className={styles.logoLabel}>Vision-Web</label>
                    </div>
                    <button
                        className={styles.addUserBtn}
                        onClick={handleAddModalOpen}
                    >
                        Add user
                    </button>
                </div>
                <div className={styles.container}>
                    <div className={styles.infoWrapper}>

                        <div className={styles.headerInfo}>
                            <div className={styles.labelInfo}>Full Name</div>
                            <div className={styles.labelInfo}>Email</div>
                        </div>
                        {userList?.map((user, index) => (
                            <div className={styles.userList} key={user._id}>
                                <div className={styles.userInfoText}>{user.name}</div>
                                <div className={styles.userInfoText}>
                                    {user.userEmail}
                                </div>
                                <button
                                    className={styles.infoBtn}
                                    onClick={(e) => handleEditModalOpen(e, user)}
                                >
                                    <img src={Edit} alt="Edit Icon" srcset="" />
                                </button>
                                <button
                                    className={styles.infoBtn}
                                    onClick={(e) => handleDeleteUser(e, user._id)}

                                >
                                    <img src={deleteIcon} alt="Delete Icon" srcset="" />
                                </button>
                                <button
                                    className={styles.renewBtn}
                                    onClick={(e) =>
                                        handleOpenRenewModal(
                                            e,
                                            user.userEmail,
                                            "renew",
                                            user
                                        )
                                    }
                                >
                                    Renew
                                </button>
                                <button
                                    className={styles.renewBtn}
                                    onClick={(e) =>
                                        handleOpenRenewModal(
                                            e,
                                            user.userEmail,
                                            "buy",
                                            user
                                        )
                                    }
                                >
                                    Buy
                                </button>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
            {
                isModal && (
                    <AdminUserModal
                        setIsModal={setIsModal}
                        selectedUser={selectedUser}
                        modelTitle={modelTitle}
                        setUserList={setUserList}
                    />
                )
            }
            {
                isRenewModal && (
                    <PlanModel
                        setIsRenewModal={setIsRenewModal}
                        selectedUserEmail={selectedUserEmail}
                        operation={operation}
                        selectedUser={selectedUser}
                        customerId={customerId}
                    />
                )
            }
        </>
    )
}

export default AdminUser