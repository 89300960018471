import React from "react";
import styles from "./PictureModel.module.css";
import close from "assets/close.png";
import { useSelector } from "react-redux";

const PictureModel = ({
  setPictureModel,
  selectedImg,
  setNewPicture,
  setSelectedImg,
  data,
}) => {
  const profileData = useSelector((state) => state.profile.profileData);
  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onload = () => {
      const base64Image = reader.result;
      const imageUrl = URL.createObjectURL(file);
      setSelectedImg(imageUrl);
      // Send the base64Image to the Express server
    };

    reader.readAsDataURL(file);
  };

  const handleSaveImg = async () => {
    try {
      const fileInput = document.getElementById("fileInput");
      if (fileInput.files.length > 0) {
        const file = fileInput.files[0];
        const reader = new FileReader();

        reader.onload = () => {
          const base64Image = reader.result;
          // Send the base64Image to the Express server
          setNewPicture(base64Image);
          localStorage.setItem("selectedImage", selectedImg);
        };

        reader.readAsDataURL(file);

        setPictureModel(false);
      } else {
        console.error("No file selected.");
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className={styles.pictureModel}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.headerText}>Update Photo</h2>
          <button
            className={styles.closeBtn}
            onClick={() => setPictureModel(false)}
          >
            <img src={close} alt="" srcset="" />
          </button>
        </div>
        <div className={styles.imgWrapper}>
          <img
            src={selectedImg || profileData.avatar}
            alt="Profile Picture"
            srcset=""
            className={styles.profileImage}
          />
        </div>
        <div className={styles.btnWrapper}>
          <label htmlFor="fileInput" className={styles.uploadPhotoBtn}>
            Upload Photo
          </label>
          <input
            type="file"
            id="fileInput"
            onChange={handleImageUpload}
            className={styles.uploadFile}
          />
          <button className={styles.saveBtn} onClick={handleSaveImg}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default PictureModel;
