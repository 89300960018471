import { useState, useEffect } from "react";
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-moment';

function GraphData({ viewData, historical, res }) {
    const [xMin, setXMin] = useState(0);
    const [xMax, setXMax] = useState(100);

    Chart.register(zoomPlugin);

    const scaleOptions = historical ? {
            y: {
                suggestedMin: xMin,
                suggestedMax: xMax
            },
        } :
        {
            x: {
                type: 'time',
            },
            y: {
                suggestedMin: xMin,
                suggestedMax: xMax
            },
        }

    const samples = res !== undefined ? res : 1;
    
    const options = {
        scales: scaleOptions,
        animation: false,
        plugins: {
            decimation: {
                enabled: true,
                algorithm: 'lttb',
                samples: samples,
                threshold: 200,
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'y',
                    modifierKey: 'ctrl',
                },
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true,
                    },
                    
                    mode: 'y',
                }
            },
            legend: {
                align: 'left', // Set the alignment to 'left'
                labels: {
                    boxWidth: 5, // Adjust the width of the legend color box
                    font: {
                        size: 10 // Set the font size
                    }
                },
            },
        },
    }

    return (
        <div id='graphdiv'>
            {viewData && viewData.labels ? (
                <Line data={viewData} options={options} />
            ) : (
                <p>Loading chart data...</p>
            )}
        </div>
    )
}

export default GraphData;
