import React, { useState } from "react";
import styles from "./Confirm.module.css";
import proxy from "../../../../../proxy";
import { useToast } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { updateUserRole } from "../../../../../redux/slices/organizationSlice.js";
import openEye from "../../../../../assets/openEye.png";
import closeEye from "../../../../../assets/closeEye.png";

const Confirm = ({
  setConfirmModal,
  permissionEdit,
  role,
  updatedPermissions,
}) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleConfirm = () => {
    const inputPassword = document.getElementById("password").value;
    if (permissionEdit) {
      const apiBody = {
        organizationEmail: localStorage.getItem("organizationEmail"),
        roles: {
          [role]: updatedPermissions,
        },
        email: localStorage.getItem("email"),
        password: inputPassword,
      };
      dispatch(updateUserRole(apiBody)).then((response) => {
        if (response.payload.status === 200) {
          toast({
            title: "Permission Modified",
            description: `Permission modified successfully`,
            status: "success",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Permission couldn't be modified",
            description: response.payload.message,
            status: "error",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
        }
      });
    }
    setConfirmModal(false);
  };

  return (
    <>
      <div className={styles.confirmModal}>
        <div className={styles.modalWrapper}>
          <div className={styles.infoContainer}>
            <h2 className={styles.header}>Confirm Role</h2>
            <div className={styles.inputWrapper}>
              <input
                className={styles.inputField}
                type={isPasswordVisible ? "text" : "password"}
                id="password"
                placeholder="Enter your password"
              />
              {isPasswordVisible ? (
                <img src={openEye} alt="open eye" className={styles.eyeImg} onClick={() => setIsPasswordVisible(!isPasswordVisible)} />
              ) : (
                <img src={closeEye} alt="close eye" className={styles.eyeImg} onClick={() => setIsPasswordVisible(!isPasswordVisible)} />
              )}
            </div>
          </div>
          <div className={styles.btnContainer}>
            <button
              className={styles.greyBtn}
              onClick={() => setConfirmModal(false)}
            >
              Discard
            </button>
            <button className={styles.blueBtn} onClick={() => handleConfirm()}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Confirm;
