import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import proxy from "proxy";

const initialState = {
    isLoading: false,
    isError: false,
    auditTrailsData: [],
    actions: [],
    totalPage: 0
}

export const getAuditTrails = createAsyncThunk("getAuditTrails", async (data) => {
    const { token, auditTrailsBody } = data;
    const response = await fetch(`${proxy}/auditLog/getAuditLog`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(auditTrailsBody)
    })

    const responseBody = await response.json();
    return responseBody;
})

const auditTrailsSlice = createSlice({
    name: "auditTrails",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(getAuditTrails.pending, (state, action) => {
            state.isLoading = true;
            state.isError = false;
        });

        builder.addCase(getAuditTrails.fulfilled, (state, action) => {
            if (action.payload.data) {

                const actions = action.payload.data.map(item => item.action);
                state.auditTrailsData = action.payload.data;
                state.totalPage = action.payload.totalPage
                state.actions = [...state.actions, ...actions]
                state.isError = false;
                state.isLoading = false;
            }
        });

        builder.addCase(getAuditTrails.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = false;
        })
    }
})

export default auditTrailsSlice.reducer;