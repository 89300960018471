import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import proxy from "proxy";

const initialState = {
    isLoading : false,
    isError : false,
    suspendMessage : null,
    deleteMessage : null,
    transferMessage : null 
}

// suspend project action
export const suspendProject = createAsyncThunk("suspendProject", async(data) => {
    const response = await fetch(`${proxy}/dangerzone/suspend-project`,{
        method : "POST",
        headers : {
            Authorization: `Bearer ${data.token}`,
            projectid: data.projectId,
            "Content-Type": "application/json",
        },
        body : JSON.stringify({
            Project_id : data.projectId
        })
    })
    const responseBody = await response.json();
    return responseBody;
});

// delete project action
export const deleteProject = createAsyncThunk("deleteProject", async(data) => {
    const response = await fetch(`${proxy}/dangerzone/delete-project`,{
        method : "POST",
        headers : {
            Authorization: `Bearer ${data.token}`,
            PID: data.projectId,
            "Content-Type": "application/json",
        },
        body : JSON.stringify({
            Project_id : data.projectId
        })
    })
    const responseBody = await response.json();
    return responseBody;
});

// Transfer Project
export const transferProject = createAsyncThunk("transferProject", async(data) => {
    const response = await fetch(`${proxy}/dangerzone/transfer-project`,{
        method : "POST",
        headers : {
            'Authorization': `Bearer ${data.token}`,
            "Content-Type": "application/json",
        },
        body : JSON.stringify({
            email_: data.email,
            token: data.projectId,
            Project_id : data.projectId
        })
    })
    const responseBody = await response.json();
    return responseBody;
});

const dangerZoneSlice = createSlice({
    name : "dangerZone",
    initialState,
    extraReducers : (builder) => {
        // suspend project
        builder.addCase(suspendProject.pending , (state, action) => {
            state.isLoading = true;
            state.isError = false; 
        });
        builder.addCase(suspendProject.fulfilled , (state, action) => {
            state.suspendMessage = action.payload;
            state.isLoading = false;
            state.isError = false; 
        });
        builder.addCase(suspendProject.rejected , (state, action) => {
            state.isLoading = false;
            state.isError = true; 
        });

        // delete project
        builder.addCase(deleteProject.pending , (state, action) => {
            state.isLoading = true;
            state.isError = false; 
        });
        builder.addCase(deleteProject.fulfilled , (state, action) => {
            state.deleteMessage = action.payload;
            state.isLoading = false;
            state.isError = false; 
        });
        builder.addCase(deleteProject.rejected , (state, action) => {
            state.isLoading = false;
            state.isError = true; 
        });  

        // transfer project
        builder.addCase(transferProject.pending , (state, action) => {
            state.isLoading = true;
            state.isError = false; 
        });
        builder.addCase(transferProject.fulfilled , (state, action) => {
            state.transferMessage = action.payload;
            state.isLoading = false;
            state.isError = false; 
        });
        builder.addCase(transferProject.rejected , (state, action) => {
            state.isLoading = false;
            state.isError = true; 
        });    
    }
});

export default dangerZoneSlice.reducer;