import React from "react";
import styles from "./SecurityData.module.css";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import proxy from "proxy";
import { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { saveSecurityUpdate, setEncryptionKey } from "../../../../../../redux/slices/projectSetting";
function SecurityAndData({ setPage }, props) {
  const toast = useToast();
  const [currentRole, setCurrentRole] = useState(null);
  const [searchParams] = useSearchParams();
  const [webhookselected, setWebhookselected] = useState(null);
  const [log, setLog] = useState(null);
  const [databaseUrl, setDatabaseUrl] = useState("");
  const [isDisableEncryption, setIsDisableEncryption] = useState(false);
  const [isDisableDatabaseUrl, setIsDisableDatabaseUrl] = useState(false);
  const [isToggleRedundancy, setIsToggleRedundancy] = useState(false);
  const [isToggleDataCollection, setIsToggleDataCollection] = useState(false);
  const ProjectID = searchParams.get("project");
  const settingsData = useSelector((state) => state.projectSetting.settingData);
  const dispatch = useDispatch();
  React.useEffect(() => {
    return () => {
      setPage("Security and Data");
    };
  }, []);

  useEffect(() => {

    if (settingsData?.data?.admin === settingsData?.currentEmail) {
      setCurrentRole("admin");
    }
    setIsToggleRedundancy(settingsData?.data?.metadata?.Settings?.security?.redundancy)
    setIsToggleDataCollection(settingsData?.data?.metadata?.Settings?.security?.dataCollection)
  }, [settingsData]);

  const [selected, setSelected] = useState(null);
  const [encryption, setEncryption] = useState("");
  const toggle = () => {
    if (selected === this) {
      return setSelected(null);
    }
    setSelected(this);
  };

  const saveEncryption = async () => {
    try {

      const apiBody = {
        projectID: ProjectID,
        encryptionKey: encryption.trim(),

      }
      if (encryption.trim() !== "") {

        dispatch(setEncryptionKey(apiBody)).then((response) => {
          if (response.payload.responseBody.status === 200) {
            toast({
              title: "Successfully Updated",
              description: response.payload.responseBody.message,
              status: "success",
              duration: 3000,
              isClosable: true,
              position: 'top-right'
            });
            setIsDisableEncryption(true)
          } else {
            toast({
              title: "Failed",
              description: response.payload.responseBody.message,
              status: "error",
              duration: 3000,
              isClosable: true,
              position: 'top-right'
            });
          }
        })
      }

    } catch (error) {
      console.error('Error occurred during encryption key save:', error);
      // Handle error, display a message, or retry the request
    }
  };

  const handleSaveDatabaseUrl = async () => {
    const apiBody = {
      Project_id: ProjectID,
      databaseURL: databaseUrl.trim(),
      datacollection: undefined,
      redundancy: undefined
    }
    if (databaseUrl.trim() !== "") {

      dispatch(saveSecurityUpdate(apiBody)).then((response) => {

        if (response.payload.responseBody.status === 200) {
          toast({
            title: "Successfully Updated",
            description: response.payload.responseBody.message,
            status: "success",
            duration: 3000,
            isClosable: true,
            position: 'top-right'
          });
          setIsDisableDatabaseUrl(true);
        } else {
          toast({
            title: "Failed",
            description: response.payload.responseBody.message,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: 'top-right'
          });
        }
      })
    }

  }

  const handleEnableRedundancy = () => {
    const apiBody = {
      Project_id: ProjectID,
      databaseURL: undefined,
      datacollection: undefined,
      redundancy: !isToggleRedundancy
    }
    dispatch(saveSecurityUpdate(apiBody)).then((response) => {

      if (response.payload.responseBody.status === 200) {
        toast({
          title: "Successfully Updated",
          description: response.payload.responseBody.message,
          status: "success",
          duration: 3000,
          isClosable: true,
          position: 'top-right'
        });
        setIsToggleRedundancy(!isToggleRedundancy);
      } else {
        toast({
          title: "Failed",
          description: response.payload.responseBody.message,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: 'top-right'
        });
      }
    })
  }


  const handleEnableDataCollection = () => {
    const apiBody = {
      Project_id: ProjectID,
      databaseURL: undefined,
      datacollection: !isToggleDataCollection,
      redundancy: undefined
    }
    dispatch(saveSecurityUpdate(apiBody)).then((response) => {

      if (response.payload.responseBody.status === 200) {
        toast({
          title: "Successfully Updated",
          description: response.payload.responseBody.message,
          status: "success",
          duration: 3000,
          isClosable: true,
          position: 'top-right'
        });
        setIsToggleDataCollection(!isToggleDataCollection);
      } else {
        toast({
          title: "Failed",
          description: response.payload.responseBody.message,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: 'top-right'
        });
      }
    })
  }

  const webtoggle = () => {
    if (webhookselected === this) {
      return setWebhookselected(null);
    }
    setWebhookselected(this);
  };

  const logtoggle = () => {
    if (log === this) {
      return setLog(null);
    }
    setLog(this);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.hero}>
        <div className={styles.page_hero}>
          <h3 className={styles.headerText}>Security and Data</h3>
          <div className={styles.wrapper}>
            <div className={styles.cover}></div>
            <div className={styles.security_options}>
              <div
                className={styles.security_option_heading}
                onClick={() => toggle()}
              >
                <div className={styles.h2Wrapper}>
                  <h2>Encryption :</h2>
                    <input
                      type="text"
                      placeholder="Encryption Key"
                      onChange={(e) => {
                        setEncryption(e.target.value.trim());
                      }}
                      style={{ background: "transparent", width : "80%" }}
                      value={settingsData?.data?.encryptionKey ?? encryption}
                      disabled={isDisableEncryption ? true : settingsData?.data?.encryptionKey ? true : false}
                    />
                </div>
                <button
                  onClick={saveEncryption}
                  className={styles.saveBtn}
                  disabled={isDisableEncryption ? true : settingsData?.data?.encryptionKey ? true : false}>
                  save
                </button>
              </div>
              <div
                className={
                  // selected === this
                  //   ? `${styles.content} ${styles.show}`
                  //   :
                  `${styles.content}`
                }
              >
                <div className="encryption_inputs">
                  <input type="text" placeholder="Encryption Key" />{" "}
                  <button className={styles.saveBtn}>Save</button>
                </div>
              </div>
            </div>

            <div className={styles.security_options}>
              <div
                className={styles.security_option_heading}
                onClick={() => webtoggle()}
              >
                <div className={styles.h2Wrapper}>
                  <h2 className={styles.h2WrapperHead}>Database URL :</h2>
                  <input
                    type="text"
                    style={{ background: "transparent" }}
                    placeholder="Database URL"
                    onChange={(e) => setDatabaseUrl(e.target.value.trim())}
                    value={settingsData?.data?.metadata?.Settings?.security?.databaseURL ?? databaseUrl}
                    disabled={isDisableDatabaseUrl ? true : settingsData?.data?.metadata?.Settings?.security?.databaseURL ? true : false}
                  />
                </div>
                <button
                  className={styles.saveBtn}
                  onClick={handleSaveDatabaseUrl}
                  disabled={isDisableDatabaseUrl ? true : settingsData?.data?.metadata?.Settings?.security?.databaseURL ? true : false}>
                  save
                </button>
              </div>
              <div
                className={
                  // webhookselected === this
                  //   ? `${styles.webhook_content} ${styles.webhook_show}`
                  //   :
                  `${styles.webhook_content}`
                }
              >
                <div className="encryption_inputs">
                  <input type="text" placeholder="Encryption Key" />{" "}
                  <button className={styles.saveBtn}>Save</button>
                </div>
              </div>
            </div>

            <div className={styles.security_options}>
              <div className={styles.security_option_heading}>
                <div className={styles.h2Wrapper}>
                  <h2 className={styles.h2WrapperHead}>Redundancy</h2>
                </div>
                <div className={`${styles.toggleBox} ${isToggleRedundancy ? styles.active : ""}`} onClick={handleEnableRedundancy} >
                  <span className={`${styles.toggle} ${isToggleRedundancy ? styles.active : ''}`} ></span>
                </div>
              </div>
            </div>

            <div className={styles.security_options}>
              <div className={styles.security_option_heading}>
                <div className={styles.h2Wrapper}>
                  <h2 className={styles.h2WrapperHead}>Data Collection By DICOT</h2>
                </div>
                <div className={`${styles.toggleBox} ${isToggleDataCollection ? styles.active : ""}`} onClick={handleEnableDataCollection} >
                  <span className={`${styles.toggle} ${isToggleDataCollection ? styles.active : ''}`} ></span>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
}

export default SecurityAndData;
