import React, { useEffect, useState } from 'react';
import styles from './Sidebar.module.css';
import DicotLogo from '../assets/Logo.svg';
import DicotMiniLogo from 'assets/logoMini.svg';
import NavMenuItem from '../components/navMenuItem';
import { ReactComponent as DashboardIcon } from '../assets/Category.svg';
import { ReactComponent as LogsIcon } from '../assets/Swap.svg';
// import { ReactComponent as OrganizationIconDark } from "assets/OrganizationDark.svg";
import { ReactComponent as OrganizationIcon } from 'assets/Organization.svg';
import { ReactComponent as NotificationIcon } from '../assets/Notification.svg';
import { ReactComponent as BillingIcon } from '../assets/Discount.svg';
import { ReactComponent as FeedbackIcon } from '../assets/Chat.svg';
import { ReactComponent as FaqIcon } from '../assets/Filter.svg';
import { ReactComponent as BurgerIcon } from '../assets/navigation.svg';
import { ReactComponent as Close } from '../assets/Close.svg';
import { HiOutlineLogout } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import proxy from '../proxy';
import { useSelector } from 'react-redux';
import { calcLength } from 'framer-motion';

const Sidebar = ({ pathName, isHovered, setIsHovered, toggleNav, page }) => {
  const [currentPageArray, setCurrentPageArray] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [profileActive, setProfileActive] = useState(false);
  const [isSmartphone, setIsSmartphone] = useState(false);
  const [menuTriggered, setMenuTriggered] = useState(false);
  const [sliderBarLocation, setSliderBarLocation] = useState(200);
  const userPermission = useSelector(
    (state) => state.organization.userPermission,
  );

  const navigate = useNavigate();
  const [data, setData] = useState({});
  const selectedImage = localStorage.getItem('selectedImage');
  const token = localStorage.getItem("token");

  const fetchProfileData = async () => {
    if (
      pathName !== '/auth/login' &&
      pathName !== '/auth/register' &&
      pathName !== '' &&
      pathName !== '/auth/forgotpassword' &&
      pathName !== '/2-Factor-Authentication' &&
      pathName !== '/confirmTransfer' && 
      pathName !== "/resetpassword" && token
    ) {
      
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/auth/login');
      }
      try {
        const response = await fetch(`${proxy}/api/profile`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Getter ${token}`,
          },
        });
        const responseBody = await response.json();
        if (responseBody.message === 'Token invalid') {
          localStorage.clear();
          navigate('/auth/login');
        }
        if (responseBody) {
          setData(responseBody);
          localStorage.setItem("avatar" , responseBody.avatar)
        
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsSmartphone(window.innerWidth <= 768); // Adjust the width as needed
    };

    handleResize(); // Call it initially
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    switch (page) {
      case 'dashboard':
        setCurrentPageArray([true, false, false, false, false, false, false]);
        setProfileActive(false);
        break;
      case 'auditTrail':
        setCurrentPageArray([false, true, false, false, false, false, false]);
        setProfileActive(false);
        break;
      case 'organization':
        setCurrentPageArray([false, false, true, false, false, false, false]);
        setProfileActive(false);
        break;
      case 'notification':
        setCurrentPageArray([false, false, false, true, false, false, false]);
        setProfileActive(false);
        break;
      case 'billing':
        setCurrentPageArray([false, false, false, false, true, false, false]);
        setProfileActive(false);
        break;
      case 'feedback':
        setCurrentPageArray([false, false, false, false, false, true, false]);
        setProfileActive(false);
        break;
      case 'faq':
        setCurrentPageArray([false, false, false, false, false, false, true]);
        setProfileActive(false);
        break;
      case 'profile':
        setCurrentPageArray([false, false, false, false, false, false, false]);
        setProfileActive(true);
        break;
      default:
        // Handle the default case here if needed
        setCurrentPageArray([false, false, false, false, false, false, false]);
        break;
    }
    setMenuTriggered(false);
  }, [page]);


  return (
    <div
      className={`${
        toggleNav ? styles.navContainerOpen : styles.navContainerClose
      } ${menuTriggered ? styles.mobileMenu : ''}`}
    >
      {/* Transition Effect in Navbar */}

      {/* <div className={styles.highlightContainer} style={{translate: (27, sliderBarLocation)}}>
        <div className={styles.highlight}></div>
      </div> */}
      {isSmartphone ? (
        <button
          className={styles.mobileNavIcon}
          onClick={() => {
            if (menuTriggered) {
              setIsHovered(false);
              setMenuTriggered(false);
            } else {
              setIsHovered(true);
              setMenuTriggered(true);
            }
          }}
        >
          {menuTriggered ? <Close /> : <BurgerIcon />}
        </button>
      ) : null}

      <div className={styles.menu}>
        <div className={styles.menuContainer}>
          <div
            className={`${
              toggleNav ? styles.logoContainer : styles.miniLogoContainer
            } `}
          >
            <a href="http://www.dicot.tech" target="_blank">
              {toggleNav ? (
                <img className={styles.DicotLogo} src={DicotLogo} alt="Logo" />
              ) : (
                <img
                  className={styles.DicotLogo}
                  src={DicotMiniLogo}
                  alt="MiniLogo"
                />
              )}
            </a>
          </div>
          <div className={styles.navItems}>
            <NavMenuItem
              text="Dashboard"
              iconOnly={isHovered}
              current={currentPageArray[0]}
              icon={<DashboardIcon />}
              setSliderBarLocation={setSliderBarLocation}
              goto="/"
              backgroundColor={page === 'dashboard' ? '#060623' : ''}
            />
            <NavMenuItem
              text="Audit Logs"
              iconOnly={isHovered}
              current={currentPageArray[1]}
              icon={<LogsIcon />}
              setSliderBarLocation={setSliderBarLocation}
              goto="/auditTrail"
            />
            {userPermission?.manageOrganization && (
              <NavMenuItem
                text="Organization"
                iconOnly={isHovered}
                current={currentPageArray[2]}
                icon={<OrganizationIcon />}
                setSliderBarLocation={setSliderBarLocation}
                goto="/organization"
              />
            )}
            {/* <NavMenuItem
            text="Notification"
            iconOnly={isHovered}
            current={currentPageArray[3]}
            icon={<NotificationIcon />}
            setSliderBarLocation={setSliderBarLocation}
            goto="/notification"
          /> */}
            <NavMenuItem
              text="Billing"
              iconOnly={isHovered}
              current={currentPageArray[4]}
              icon={<BillingIcon />}
              setSliderBarLocation={setSliderBarLocation}
              goto="/billing"
            />
            {userPermission?.writeFeedback && (
              <NavMenuItem
                text="Feedback"
                iconOnly={isHovered}
                current={currentPageArray[5]}
                icon={<FeedbackIcon />}
                setSliderBarLocation={setSliderBarLocation}
                goto="/feedback"
              />
            )}
            <NavMenuItem
              text="FAQs"
              iconOnly={isHovered}
              current={currentPageArray[6]}
              icon={<FaqIcon />}
              setSliderBarLocation={setSliderBarLocation}
              goto="/faq"
            />
          </div>
        </div>

        <button
          className={`${styles.profile} ${isHovered ? '' : styles.photoOnly} ${
            profileActive ? `${styles.profileActive}` : ''
          } }`}
          onClick={() => {
            setProfileActive(true);
            navigate(`/profile`);
          }}
        >
          <div
            className={`${styles.profilePhoto} ${
              profileActive ? styles.profilePhotoActive : ''
            } `}
          >
            <img src={localStorage.getItem("avatar")} alt="Profile Pic" />
          </div>
          {isHovered ? (
            <div className={styles.rightSide}>
              <div className={styles.info}>
                <div className={styles.name}>
                  {localStorage.getItem('email')}
                </div>
              </div>
              <HiOutlineLogout
                className={styles.logoutIcon}
                onClick={async (e) => {
                  // e.preventDefault();
                  if (window.confirm('Are you sure you want to logout?')) {
                    await fetch(
                      proxy +
                        '/auth/logout?token=' +
                        localStorage.getItem('token') +
                        '&&email=' +
                        localStorage.getItem('email'),
                      {
                        method: 'GET',
                        headers: {
                          'Content-Type': 'application/json',
                        },
                      },
                    );
                    // localStorage.clear("token");
                    // localStorage.clear("username");
                    // localStorage.clear("selectedImage");
                    // localStorage.clear("projects");
                    localStorage.clear();

                    navigate('/auth/login');
                  }
                }}
              />
            </div>
          ) : (
            ''
          )}
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
