import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import proxy from "proxy";

const initialState = {
  isLoading: false,
  isError: false,
  graphData: [],
  widgetData: {},
  updateWidgetMsg: null,
};

// get data action
export const getGraphData = createAsyncThunk("getGraphData", async (data) => {
  const response = await fetch(
    `${proxy}/db/get-data?token=${data.token}&projectID=${data.projectId}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${data.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Project_id: data.projectId,
        ind: data.ind,
      }),
    }
  );
  const responseBody = await response.json();
  return responseBody;
});

// get widget data

export const getWidgetData = createAsyncThunk("getWidgetData", async (data) => {
  const response = await fetch(`${proxy}/view/getViewData`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const responseBody = await response.json();
  return responseBody;
});

// update SV Value

export const updateSV = createAsyncThunk("updateSV", async (data) => {
  const response = await fetch(`${proxy}/devices/updateDevice`, {
    method : "POST",
    headers : {
      "Content-Type": "application/json",
    },
    body : JSON.stringify(data)
  });
  const responseBody = await response.json();
  return responseBody;
});

export const addWidget = createAsyncThunk("addWidget", async (data) => {
  const {
    filteredTempImages,
    tempTabNames,
    windowHeight,
    windowWidth,
    uniqueProjectId,
  } = data;
  const response = await fetch(`${proxy}/view/addViewData`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      projectID: uniqueProjectId,
      screenWidth: windowWidth,
      screenHeight: windowHeight,
      tabs: tempTabNames,
      widget: filteredTempImages,
    }),
  });
  const responseBody = await response.json();
  return { responseBody, data };
});

const viewDataSlice = createSlice({
  name: "viewData",
  initialState,
  extraReducers: (builder) => {
    // get data
    builder.addCase(getGraphData.pending, (state, action) => {
      state.isLoading = false;
      state.isError = false;
    });
    builder.addCase(getGraphData.fulfilled, (state, action) => {

      state.graphData = action.payload;
      state.isLoading = false;
      state.isError = false;
    });
    builder.addCase(getGraphData.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });

    // get widget data
    builder.addCase(getWidgetData.pending, (state, action) => {
      state.isError = false;
      state.isLoading = true;
    });

    builder.addCase(getWidgetData.fulfilled, (state, action) => {
      state.isError = false;
      state.isLoading = false;
      state.widgetData = action.payload;
    });

    builder.addCase(getWidgetData.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });

    // update Sv value

    builder.addCase(updateSV.pending, (state, action) => {
      state.isError = false;
      state.isLoading = true;
    });

    builder.addCase(updateSV.fulfilled, (state, action) => {
      state.isError = false;
      state.isLoading = false;
    });

    builder.addCase(updateSV.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });

    // add widget
    builder.addCase(addWidget.pending, (state, action) => {
      state.isError = false;
      state.isLoading = true;
    });

    builder.addCase(addWidget.fulfilled, (state, action) => {
      state.isError = false;
      state.isLoading = false;
      if (action.payload.status === 200) {
        state.widgetData = {
          ...state.widgetData,
          data: {
            ...state.widgetData.data,
            tabs: action.payload.data.tabs,
            widget: action.payload.data.widget,
          },
        };
      }
      
      state.updateWidgetMsg = action.payload.responseBody.status;
    });

    builder.addCase(addWidget.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });
  },
});

export default viewDataSlice.reducer;
