import { React, useState, useEffect } from "react";
import styles from "./Overview.module.css";
import WorkerBuilder from "Utils/builder.worker";
import tableInitWorker from "Utils/restructure.worker";
import valueExtractorWorker from "Utils/valueExtractor.worker";
import GraphData from "./GraphData";
import proxy from "proxy";
import OverviewInfo from "./OverviewComp/OverviewInfo";
import Draggable from "react-draggable";
import OverviewTable from "./OverviewComp/OverviewTable";
import { useSelector } from "react-redux";
const io = require('socket.io-client');

const tableInitializer = new WorkerBuilder(tableInitWorker);
const valueExtractor = new WorkerBuilder(valueExtractorWorker);

const Overview = ({
  uniqueProjectId,
  data,
  socketData,
  openNewPage,
  handleOverviewInfoDrop,
  tabIndexOverviewInfo,
  selectedTabIndex,
  handleOverviewGraphDrop,
  tabIndexOverviewGraph,
  handleOverviewGraphDimension,
  tempTabIndexOverviewInfo,
  tempTabIndexOverviewGraph,
  isMobile,
  scaleValue
}) => {

  const [project, setProject] = useState({});
  const [dateTimesLive, setDateTimesLive] = useState([]); // DateTime labels for y-axis
  const [channelNames, setChannelNames] = useState([]); //  Channel names
  const [init, setInit] = useState(false);
  const [chartDataLive, setChartDataLive] = useState({
    labels: [],
    datasets: [],
  }); // data ready for display
  const [isWorkerBusy, setIsWorkerBusy] = useState(false);
  const [dataArrLive, setDataArrLive] = useState([]); // Real-time data received for live
  const [tableData, setTableData] = useState([]); // Data formatted for Table
  const graphData = useSelector(state => state.viewData.graphData);

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Getting Data from the database
  useEffect(() => {
    const token = localStorage.getItem("token");
    fetch(`${proxy}/api/get-project-details?projectID=${uniqueProjectId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Project_id: uniqueProjectId,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {

        setProject({
          projectName: result.data.metadata.Project,
          uniqueProjectId: result.data.metadata.Project_id,
          location: result.data.metadata.Location,
          desc: result.data.metadata.Description,
          projectOnlineStatus: result.data.metadata.isVerified,
        });
      })
      .catch((error) => {
        console.error("Error fetching project:", error);
      });
  }, [uniqueProjectId]);


  useEffect(() => {
    // Parsing data from from get request in the parent component
    const fetchData = async () => {
      try {
        if (graphData.length > 0) {
          const copiedDataset = [...graphData];
          // Live Data Chart
          const currentTime = new Date();
          currentTime.setTime(currentTime.getTime() - 1 * 60 * 60 * 1000); // Subtracting 1 hour
          const liveDataFiltered = copiedDataset.filter(
            (dataset) => new Date(dataset.time) >= currentTime
          );

          // Restructure Data for Table
          const tableDataArrayGen = async () => {
            const dataPromise = new Promise((resolve) => {
              tableInitializer.onmessage = (e) => {

                resolve(e.data);
              };
            });
            tableInitializer.postMessage({ data: liveDataFiltered });
            return await dataPromise;
          };
          const tableDataArray = await tableDataArrayGen();

          let gotHeaders = [];
          if (tableDataArray.data) {
            gotHeaders = tableDataArray.headers;
            const arrayOfValueArrays = tableDataArray.channelWiseData;
            const getNewDataArr = () =>
              gotHeaders.map((header, index) => ({
                label: header,
                data: arrayOfValueArrays[index],
                borderColor: getRandomColor(),
                fill: false,
              }));
            const newDataArr = getNewDataArr();

            setChannelNames(gotHeaders);
            setTableData(tableDataArray.data);
            setDataArrLive(newDataArr);
            setDateTimesLive(tableDataArray.dateTimeArray);
            setInit(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [graphData]);

  useEffect(() => {
    try {
      const updateData = async () => {

        if (socketData && init && !isWorkerBusy) {
          setIsWorkerBusy(true);
          const structuredData = async () => {
            const dataPromise = new Promise((resolve) => {
              valueExtractor.onmessage = (e) => {
                resolve(e.data);
              };
            });
            valueExtractor.postMessage({
              socketData: socketData,
              currentTable: tableData,
              dateTimeArray: dateTimesLive,
              dataArray: dataArrLive,
              headers: channelNames,
              duration: 1,
            });
            return await dataPromise;
          };
          const workerResponse = await structuredData();
          if (workerResponse.updatedTable) {
            setChannelNames(workerResponse.updatedHeaders);
            setTableData(workerResponse.updatedTable);
            setDataArrLive(workerResponse.updatedLiveArray);
            setDateTimesLive(workerResponse.updatedDateTimeArray);
          }
        }
      };

      updateData();
    } catch (error) {
      console.log(error);
    }
    setIsWorkerBusy(false);
  }, [socketData]);

  useEffect(() => {
    setChartDataLive({
      labels: dateTimesLive.map((item) => {
        const date = new Date(item);
        if (isNaN(date)) {
          return 0;
        }
        return date.getTime();
      }),
      datasets: dataArrLive,
    });
  }, [dateTimesLive]);

  const generateOverviewInfo = () => {

    const selectedTabDevices = tabIndexOverviewInfo[selectedTabIndex];

    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices?.map((device, index) => {
        const { x, y } = device;
        const deviceStyle = {
          position: "absolute",
          top: y !== null ? y + "px" : "70px",
          left: x !== null ? x + "px" : "20px",
          display: "flex",
          gap: '1.5rem',
          flexDirection: "column",
          width: isMobile ? "100%" : "40%",
          scale: isMobile ? scaleValue.toString() : "1",
          maxHeight: "50vh"
        };

        return (
          <>
            <div style={deviceStyle} key={index} >
              <OverviewInfo project={project} />
              <OverviewTable tableData={tableData} />
            </div>
          </>
        );
      });
    } else {
      const deviceStyle = {
        position: "absolute",
        top: "70px",
        left: "20px",
        display: "flex",
        gap: '1.5rem',
        flexDirection: "column",
        width: isMobile ? "100%" : "40%",
        scale: isMobile ? scaleValue.toString() : "1",
        maxHeight: "50vh"
      };
      return (
        <>
          <div style={deviceStyle} >
            <OverviewInfo project={project} />
            <OverviewTable tableData={tableData} />
          </div>
        </>
      );
    }
  };


  const tempGenerateOverviewInfo = () => {
    const selectedTabDevices = tempTabIndexOverviewInfo[selectedTabIndex];

    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices?.map((device, index) => {
        const { x, y, id } = device;
        const deviceStyle = {
          position: "absolute",
          top: y !== null ? y + "px" : "70px",
          left: x !== null ? x + "px" : "20px",
          display: "flex",
          gap: '1.5rem',
          flexDirection: "column",
          width: isMobile ? "100%" : "40%",
          scale: isMobile ? scaleValue.toString() : "1",
          maxHeight: "50vh"
        };

        return (
          <>
            <Draggable bounds="parent"
              onStop={(e, data) => handleOverviewInfoDrop(e, data, id, x, y)}
            >
              <div style={deviceStyle} key={index}>
                <OverviewInfo project={project} />
                <OverviewTable tableData={tableData} />
              </div>
            </Draggable>
          </>
        );
      });
    } else {
      const deviceStyle = {
        position: "absolute",
        top: "70px",
        left: "20px",
        display: "flex",
        gap: '1.5rem',
        flexDirection: "column",
        width: "40%",
        scale: isMobile ? scaleValue : "1",
        maxHeight: "50vh"
      };
      return (
        <>
          <Draggable bounds="parent"
            onStop={(e, data) => handleOverviewInfoDrop(e, data)}>
            <div style={deviceStyle}>
              <OverviewInfo project={project} />
              <OverviewTable tableData={tableData} />
            </div>
          </Draggable>
        </>
      );
    }
  };

  const generateOverviewGraph = () => {
    const selectedTabDevices = tabIndexOverviewGraph[selectedTabIndex];

    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices?.map((device, index) => {
        const { x, y, width, height } = device;
        const deviceStyle = {
          position: "absolute",
          top: y !== null ? y + "px" : "10%",
          left: x !== null ? x + "px" : "43%",
          width: isMobile ? "150%" : width !== null ? width : "46vw",
          height: height !== null ? height : "",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          resize: openNewPage ? "both" : "",
          scale: isMobile ? scaleValue.toString() : "1"
        };


        return (
          <>
            <div style={deviceStyle} id="overviewGraph">
              <div className={styles.heading}>Live</div>
              <GraphData
                viewData={chartDataLive}
                historical={false}
                res={4}
              />
            </div>

          </>
        );
      });
    } else {
      const deviceStyle = {
        position: "absolute",
        top: "10%",
        left: "43%",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        width: isMobile ? "150%" : "46vw",
        resize: openNewPage ? "both" : "",
        scale: isMobile ? scaleValue.toString() : "1"
      };
      return (
        <>
          <div style={deviceStyle}>
            <div className={styles.heading}>Live</div>
            <GraphData viewData={chartDataLive} historical={false} res={4} />
          </div>
        </>
      );
    }
  };


  const tempGenerateOverviewGraph = () => {
    const selectedTabDevices = tempTabIndexOverviewGraph[selectedTabIndex];

    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices?.map((device, index) => {
        const { x, y, id, width, height } = device;
        const deviceStyle = {
          position: "absolute",
          top: y !== null ? y + "px" : "10%",
          left: x !== null ? x + "px" : "43%",
          width: isMobile ? "150%" : width !== null ? width : "46vw",
          height: height !== null ? height : "",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          resize: openNewPage ? "both" : "",
          scale: isMobile ? scaleValue.toString() : "1"
        };


        return (
          <>
            <Draggable bounds="parent"
              onStop={(e, data) => handleOverviewGraphDrop(e, data, id, x, y)}
            >
              <div
                style={deviceStyle}
                id="overviewGraph"
                onContextMenu={(e) => handleOverviewGraphDimension(e, id)}
              >
                <div className={styles.heading}>Live</div>
                <GraphData
                  viewData={chartDataLive}
                  historical={false}
                  res={4}
                />
              </div>
            </Draggable>
          </>
        );
      });
    } else {
      const deviceStyle = {
        position: "absolute",
        top: "10%",
        left: "43%",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        width: isMobile ? "150%" : "46vw",
        resize: openNewPage ? "both" : "",
        scale: isMobile ? scaleValue.toString() : "1"
      };
      return (
        <>
          <Draggable
            bounds="parent"
            onStop={(e, data) => handleOverviewGraphDrop(e, data)}>
            <div
              style={deviceStyle}
              id="overviewGraph"
              onContextMenu={(e) => handleOverviewGraphDimension(e)}
            >
              <div className={styles.heading}>Live</div>
              <GraphData
                viewData={chartDataLive}
                historical={false}
                res={4}
              />
            </div>
          </Draggable>
        </>
      );
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        {/* <Box w="100%"></Box> */}
        {
          openNewPage ? (
            <>
              {tempGenerateOverviewGraph()}
              {tempGenerateOverviewInfo()}
            </>
          ) : (
            <>
              {generateOverviewGraph()}
              {generateOverviewInfo()}
            </>
          )
        }
      </div>
    </>
  );
};

export default Overview;
