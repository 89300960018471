import React, { useEffect } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import styles from "./Suspend.module.css";
import { useDispatch, useSelector } from "react-redux";
import { suspendProject } from "../../../../../../../redux/slices/dangerZoneSlice.js";

function Suspend() {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const searchParams = new URLSearchParams(location.search);
  const uniqueProjectId = searchParams.get("project");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const dispatch = useDispatch();
  const suspendMessage = useSelector(
    (state) => state.dangerZone.suspendMessage
  );
  const token = localStorage.getItem("token");
  const userPermission = useSelector(state => state.organization.userPermission);

  const handleSuspendProject = () => {
    const projectData = {
      projectId: uniqueProjectId,
      token: token,
    };
    dispatch(suspendProject(projectData)).then((response) => {
      if(response.payload.code === 200){
        toast({
          title: `Project Suspended`,
          description: `Project has been suspended, visit settings to un-suspend the project`,
          status: "warning",
          position: "top-right",
          duration: 7000,
          isClosable: true,
        });
        navigate("/");
    }})
  };

  

  return (
    <>
      <div className={styles.suspendProject}>
        <label htmlFor="">Suspend Project</label>
        {userPermission.suspendProject && (
        <button onClick={onOpen} className={styles.suspendBtn}>
          Suspend
        </button>
        )}
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Suspend Project
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure you want to suspend the project?
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button
                  className="cancel-button"
                  ref={cancelRef}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  className="confirm-button"
                  onClick={handleSuspendProject}
                  ml={3}
                >
                  Suspend
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </div>
    </>
  );
}

export default Suspend;
