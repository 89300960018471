import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import proxy from "proxy";
const initialState = {
  isLoading: false,
  isError: false,
  userRole: "",
  userPermission: {},
  allMemeberPermission: [],
  orgMembers: [],
  plansAboutToExpire : 0
};

// getUsers action
export const getUsers = createAsyncThunk("getUsers", async (data) => {
  const response = await fetch(`${proxy}/org/getUsers`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });



  const responseBody = await response.json();
  return { responseBody, data };
});

// getRoles Action
export const getRoles = createAsyncThunk("getRoles", async (data) => {
  const response = await fetch(`${proxy}/org/getRoles`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const responseBody = await response.json();
  return { responseBody, data };
});

// Update Roles Action
export const updateRoles = createAsyncThunk("updateRoles", async (data) => {
  const response = await fetch(`${proxy}/org/updateRoles`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const responseBody = await response.json();
  return responseBody;
});

export const inviteMember = createAsyncThunk("inviteMemeber", async (data) => {
  const response = await fetch(`${proxy}/org/inviteMembers`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    redirect: "follow",
  });
  const responseBody = await response.json();
  return responseBody;
});

export const deleteRoles = createAsyncThunk("deleteRoles", async (data) => {
  const response = await fetch(`${proxy}/org/deleteRoles`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const responseBody = await response.json();
  return responseBody;
});

export const createRoleOrg = createAsyncThunk("createRole", async (data) => {
  const response = await fetch(`${proxy}/org/createRole`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  });

  const responseBody = await response.json();
  return responseBody;
});

export const deleteOrganization = createAsyncThunk(
  "deleteOrg",
  async (data) => {
    const response = await fetch(`${proxy}/org/DeleteOrg`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const responseBody = await response.json();
    return responseBody;
  }
);

export const transferOrganization = createAsyncThunk(
  "transferOrg",
  async (data) => {
    const response = await fetch(`${proxy}/org/transferOrganization`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      redirect: "follow",
    });
    const responseBody = await response.json();
    return responseBody;
  }
);

export const removePeople = createAsyncThunk("removePeople", async (data) => {
  const response = await fetch(`${proxy}/org/deleteUser`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  const responseBody = await response.json();
  return responseBody;
});

export const updateUserRole = createAsyncThunk(
  "updateUserRole",
  async (data) => {
    const response = await fetch(`${proxy}/org/updatePermission`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const responseBody = await response.json();
    return responseBody;
  }
);

export const getUserPermission = createAsyncThunk(
  "getUserPermission",
  async (data) => {

    const response = await fetch(`${proxy}/org/getManageOrganization`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const responseBody = await response.json();

    return responseBody;
  }
);

const organizationSlice = createSlice({
  name: "organization",
  initialState,
  extraReducers: (builder) => {
    // get users
    builder.addCase(getUsers.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      const userRole = action.payload.responseBody.users && action.payload.responseBody.users.find(
        (user) => user.email === action.payload.data.userEmail
      );
      state.userRole = userRole && userRole.role;
      state.orgMembers = action.payload.responseBody.users;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });

    // get roles
    builder.addCase(getRoles.pending, (state, action) => {
      state.isError = false;
      state.isLoading = true;
    });

    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.isError = false;
      state.isLoading = false;
      state.allMemeberPermission = action.payload.responseBody.permission;
    });

    builder.addCase(getRoles.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });

    //updateRoles
    builder.addCase(updateRoles.pending, (state, action) => {
      state.isError = false;
      state.isLoading = true;
    });

    builder.addCase(updateRoles.fulfilled, (state, action) => {
      state.isError = false;
      state.isLoading = false;
    });

    builder.addCase(updateRoles.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });

    //Invite Member

    builder.addCase(inviteMember.pending, (state, action) => {
      state.isError = false;
      state.isLoading = true;
    });

    builder.addCase(inviteMember.fulfilled, (state, action) => {
      state.isError = false;
      state.isLoading = true;
    });

    builder.addCase(inviteMember.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });

    //delete Roles

    builder.addCase(deleteRoles.pending, (state, action) => {
      state.isError = false;
      state.isLoading = true;
    });

    builder.addCase(deleteRoles.fulfilled, (state, action) => {
      state.isError = false;
      state.isLoading = false;
    });

    builder.addCase(deleteRoles.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });

    // create role

    builder.addCase(createRoleOrg.pending, (state, action) => {
      state.isLoading = false;
      state.isError = false;
    });

    builder.addCase(createRoleOrg.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
    });

    builder.addCase(createRoleOrg.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });

    // delete Org
    builder.addCase(deleteOrganization.pending, (state, action) => {
      state.isLoading = false;
      state.isError = false;
    });

    builder.addCase(deleteOrganization.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
    });

    builder.addCase(deleteOrganization.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });

    // Transfer Org
    builder.addCase(transferOrganization.pending, (state, action) => {
      state.isLoading = false;
      state.isError = false;
    });

    builder.addCase(transferOrganization.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
    });

    builder.addCase(transferOrganization.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });

    // remove people org
    builder.addCase(removePeople.pending, (state, action) => {
      state.isLoading = false;
      state.isError = false;
    });

    builder.addCase(removePeople.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
    });

    builder.addCase(removePeople.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });

    // remove people org

    builder.addCase(updateUserRole.pending, (state, action) => {
      state.isLoading = false;
      state.isError = false;
    });

    builder.addCase(updateUserRole.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
    });

    builder.addCase(updateUserRole.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });

    // get user permission

    builder.addCase(getUserPermission.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });

    builder.addCase(getUserPermission.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.userPermission = action.payload.permission;
      state.plansAboutToExpire = action.payload.plansAboutToExpire;

    });

    builder.addCase(getUserPermission.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export default organizationSlice.reducer;
