import React from "react";
import styles from "./Faq.module.css";
import { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'

function FAQPage({setPage, setPageTitle}) {
  setPage("faq");
  setPageTitle(`FAQ's`);
  const [selected, setSelected] = useState(null)
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null)
    }
    setSelected(i)
}

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.rightWrapper}>
          <div className={styles.accordianWrapper}>
            <div className={styles.accordion}>
              {data.map((item, i) => (
                <div className={styles.item}>
                  <div className={styles.title} onClick={() => toggle(i)}>
                    <h2>{item.title}</h2>
                    <span>{selected === i ? <ChevronUpIcon /> : <ChevronDownIcon />}</span>
                  </div>
                  <div className={selected === i ? `${styles.content} ${styles.show}` : `${styles.content}`}>{item.content}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const data = [


  {
    title: "Why I am unable to register?",
    content: `You may encounter issues while trying to register and seek assistance. Common reasons for registration
     failure could include incorrect email format, password requirements not met, or missing information.
     Seeking guidance on registration requirements and troubleshooting tips can help address such queries.`,
  },
  {
    title: "Is my registration information secure?",
    content:`Absolutely! Your registration information is secure with us.
     We have implemented comprehensive security measures to ensure the safety and confidentiality of your data.`,
  },
  {
    title: "Can I change my registered email address later?",
    content: ` No, you will have to signup again. `,
  },
  {
    title: "Can I register using social media accounts?",
    content: ` No, you will have to compulsorily register by email. `,
  },
  {
    title: "What does the status indicate?",
    content: `The status indicates whether the batch is currently running or stopped.
     A green status indicates that the batch is running, while a red status indicates that it's stopped.`,
  },
  {
    title: "Can I schedule batch processing tasks to run at specific times?",
    content: `No, as of now we don't provide this feature.`,
  },
  {
    title: "What if I accidentally delete a batch?",
    content: `It gets removed from the system permanently. The you will have to create a new batch. `,
  },
  {
    title: "Can I start multiple batches simultaneously?",
    content: ` Yes, you can start multiple batches simultaneously by creating separate batch entries
     with unique names and selected channels. Each batch operates independently of the others. `,
  },
  {
    title: "What happens if I close the batch creation or editing window without saving?",
    content: ` If you close the batch create/edit window without saving, any changes you made will be discarded.
     The window will close without affecting the existing batch data. `,
  },
  {
    title: "Is there a limit to the number of channels I can select for a batch?",
    content: ` There is no predefined limit to the number of channels you can select for a batch. However,
     it's essential to consider factors such as processing capacity and resource availability when selecting channels for large batches. `,
  },
  {
    title: "What actions can I perform on a batch?",
    content: ` You can start, stop, edit, or delete a batch. Starting a batch initiates the data collection process,
     while stopping a batch halts the process. Edit allows you to modify batch details, and delete removes the batch entirely.
     Apart from this you can watch real-time status as well! `,
  },
  {
    title: "Can I export or save the graph for further analysis or sharing?",
    content: ` Yes, depending on the application's functionality, you may have options to export the graph as an image or data file,
     or save it for future reference. These features may vary depending on the application's capabilities. `,
  },
  {
    title: "How frequently does the live graph update with new data?",
    content: ` The live graph updates continuously as new data is received from the project. 
    The frequency of updates depends on the rate at which data is transmitted and the processing speed of the system. `,
  },
  {
    title: "Can I see specific details about the data points?",
    content: ` Yes, you can hover over the data points on the graph to see specific details such as the timestamp and value of each data point.
     This allows for detailed analysis and monitoring of the project's parameters. `,
  },
  {
    title: "What actions are taken when an alarm is triggered?",
    content: ` Customers will receive notifications & data will be saved in logs with the specific value. `,
  },
  {
    title: "What actions can I perform in the 'Danger Zone'?",
    content: ` Tasks such as suspension, deletion, or transfer of resources can be carried out in the "Danger Zone". `,
  },
  {
    title: "What if I accidentally decline a project invitation?",
    content: `Oops! You will have to ask inviter to send it again.`,
  },
  {
    title: "How can I learn more about the project or its data sources?",
    content: `For detailed answers about the project or its data sources, you may refer to the project's documentation or contact the project administrators for more information.
     They will be able to provide detailed insights and explanations as required.`,
  }, 
  {
    title: "Is there a way to view alarm history?",
    content: `Yes, you can access it in "Alarm Logs".`,
  },
  {
    title: "How do I download the data associated with a batch?",
    content: `To download the data associated with a batch, click on the button labelled "Download". 
    This will initiate the download of a CSV file containing the batch's data.`,
  },
  {
    title: "Is there a way to customize the appearance of the live graph?",
    content: `At the moment, customization options aren't available for live graph's appearance.
     However, future updates may include features to customize colors, line styles, and other visual elements.`,
  },
  {
    title: "How can I troubleshoot issues with the live data display?",
    content: `If you encounter any issues with the live data display, such as missing data or incorrect visualizations,
     you can check the data sources, review the data processing logic, or reach out to the support team for assistance and troubleshooting.`,
  },  
  {
    title: "How do I upgrade my plan?",
    content: `Visit the "Plans and Billing" page. You will find an upgrade option.`,
  },
  {
    title: "Can I undo the transfer after confirming it?",
    content: `No, it is not possible for security reasons.`,
  },
  {
    title: "Is there a notification sent to the recipient upon confirmation?",
    content: `Definitely, the recipient will get a mail in which he/she has to "Accept" or "Decline" the same.`,
  },
  {
    title: "What is the package version?",
    content: <p>Current version is 1.2.1.</p>,
  },

]


export default FAQPage;