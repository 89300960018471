import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import proxy from '../../proxy';

const initialState = {
  isLoading: false,
  isError: false,
  settingData: {},
  updatePermissionMessage: null,
  addRoleMessage: null,
  mqttData: {},
};
// Actions

// Get Project Setting

export const getProjectSetting = createAsyncThunk(
  'getProjectSetting',
  async (data) => {
    const { token, ProjectID } = data;
    const response = await fetch(
      `${proxy}/api/get-settings?project=${ProjectID}&token=${token}`,
      {
        method: 'POST',
        headers: {
          "Authorization": `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Project_id: ProjectID,
        }),
      },
    );
    const responseBody = await response.json();
    return responseBody;
  },
);

// update role permission action

export const updateRolePermission = createAsyncThunk(
  'updateRolePermission',
  async (data) => {
    const { token, updatedPermissions } = data;
    const response = await fetch(`${proxy}/api/update-role-permissions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updatedPermissions),
    });
    const responseBody = await response.json();
    return responseBody;
  },
);

//add role action

export const addRole = createAsyncThunk('addRole', async (data) => {
  const { token, newRoleData } = data;
  const response = await fetch(`${proxy}/api/add-role`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newRoleData),
  });
  const responseBody = await response.json();
  return responseBody;
});

export const updateProjectInfo = createAsyncThunk(
  'updateProjectInfo',
  async (data) => {
    const { token, updatedProjectData } = data;
    const response = await fetch(`${proxy}/api/update-project`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedProjectData),
    });
    const responseBody = await response.json();
    return { responseBody, updatedProjectData };
  },
);

export const setEncryptionKey = createAsyncThunk(
  'setEncryptionKey',
  async (data) => {
    const response = await fetch(`${proxy}/api/Encryption`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const responseBody = await response.json();

    return { responseBody, data };
  },
);

export const saveSecurityUpdate = createAsyncThunk(
  'setDatabaseUrl',
  async (data) => {
    const response = await fetch(`${proxy}/api/toggleConnection`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const responseBody = await response.json();
    return { responseBody, data };
  },
);

export const setVnetCredentials = createAsyncThunk(
  'setVnetCredentials',
  async (data) => {
    const response = await fetch(`${proxy}/api/VnetLogin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const responseBody = await response.json();
    return { responseBody, data };
  },
);

export const changeConnectionMethod = createAsyncThunk(
  'changeConnectionMethod',
  async (data) => {
    const response = await fetch(`${proxy}/api/toggleConnection`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const responseBody = await response.json();
    return { responseBody, data };
  },
);

export const addTopic = createAsyncThunk('addTopic', async (data) => {
  const response = await fetch(`${proxy}/api/addTopic`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const responseBody = await response.json();
  return { responseBody, data };
});

export const deleteTopic = createAsyncThunk('deleteTopic', async (data) => {
  const response = await fetch(`${proxy}/api/deleteTopic`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const responseBody = await response.json();
  return { responseBody, data };
});

export const getMqttCredentials = createAsyncThunk(
  'getMqttCredentials',
  async (email) => {
    const response = await fetch(
      `${proxy}/api/mqttCredentials?email=${email}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    const responseBody = await response.json();
    return responseBody;
  },
);

export const addMqttCredentials = createAsyncThunk(
  'addMqttCredentials',
  async (data) => {
    const response = await fetch(`${proxy}/api/addMqttCredentials`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const responseBody = await response.json();
    return { responseBody, data };
  },
);

export const updateMqttCredentials = createAsyncThunk(
  'updateMqttCredentials',
  async (data) => {
    const response = await fetch(`${proxy}/api/updateMqttCredentials`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const responseBody = await response.json();
    return { responseBody, data };
  },
);

export const projectSettingSlice = createSlice({
  name: 'projectSetting',
  initialState,
  extraReducers: (builder) => {
    // Get Project Setting
    builder.addCase(getProjectSetting.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });

    builder.addCase(getProjectSetting.fulfilled, (state, action) => {
      if (action.payload.status === 200) {
        state.settingData = action.payload;
        state.isError = false;
      } else {
        state.isError = true;
      }
      state.isLoading = false;
    });

    builder.addCase(getProjectSetting.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });

    //update role permission

    builder.addCase(updateRolePermission.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });

    builder.addCase(updateRolePermission.fulfilled, (state, action) => {
      state.updatePermissionMessage = action.payload;
      state.isLoading = false;
      state.isError = false;
    });

    builder.addCase(updateRolePermission.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });

    // add new role

    builder.addCase(addRole.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });

    builder.addCase(addRole.fulfilled, (state, action) => {
      state.addRoleMessage = action.payload;
      state.isLoading = false;
      state.isError = false;
    });

    builder.addCase(addRole.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });

    builder.addCase(updateProjectInfo.pending, (state, action) => {
      state.isError = false;
      state.isLoading = true;
    });

    builder.addCase(updateProjectInfo.fulfilled, (state, action) => {
      if (action.payload.responseBody.status === 200) {
        state.settingData = {
          ...state.settingData,
          data: {
            ...state.settingData.data,
            metadata: {
              ...state.settingData.data.metadata,
              Project: action.payload.updatedProjectData.projectName,
              Description: action.payload.updatedProjectData.projectDesc,
              Location: action.payload.updatedProjectData.projectLocation,
            },
          },
        };
        state.isError = false;
      } else {
        state.isError = true;
      }
    });

    builder.addCase(updateProjectInfo.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });

    // set Encryption key
    builder.addCase(setEncryptionKey.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
    });

    // set DataBaseurl/redundancy/datacollection
    builder.addCase(saveSecurityUpdate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
    });

    //set vnet credentials
    builder.addCase(setVnetCredentials.fulfilled, (state, action) => {
      if (action.payload.responseBody.status === 200) {
        state.settingData = {
          ...state.settingData,
          data: {
            ...state.settingData.data,
            metadata: {
              ...state.settingData.data.metadata,
              vnetUsername: action.payload.data.v_username,
            },
          },
        };
      }
      state.isLoading = false;
      state.isError = false;
    });

    builder.addCase(changeConnectionMethod.fulfilled, (state, action) => {
      if (action.payload.responseBody.status === 200) {
        state.settingData = {
          ...state.settingData,
          data: {
            ...state.settingData.data,
            metadata: {
              ...state.settingData.data.metadata,
              Settings: {
                ...state.settingData.data.metadata.Settings,
                connection: {
                  ...state.settingData.data?.metadata.Settings.connection,
                  MQTT: action.payload.data.value,
                },
              },
            },
          },
        };
      }
      state.isLoading = false;
      state.isError = false;
      // console.log(state.settingData)
    });

    builder.addCase(addTopic.fulfilled, (state, action) => {
      if (action.payload.responseBody.status === 200) {
        state.settingData = {
          ...state.settingData,
          data: {
            ...state.settingData.data,
            metadata: {
              ...state.settingData.data.metadata,
              Settings: {
                ...state.settingData.data.metadata.Settings,
                connection: {
                  ...state.settingData.data?.metadata.Settings.connection,
                  topics: action.payload.responseBody.data,
                },
              },
            },
          },
        };
      }
      state.isLoading = false;
      state.isError = false;
    });

    builder.addCase(deleteTopic.fulfilled, (state, action) => {
      if (action.payload.responseBody.status === 200) {
        state.settingData = {
          ...state.settingData,
          data: {
            ...state.settingData.data,
            metadata: {
              ...state.settingData.data.metadata,
              Settings: {
                ...state.settingData.data.metadata.Settings,
                connection: {
                  ...state.settingData.data?.metadata.Settings.connection,
                  topics: action.payload.responseBody.data,
                },
              },
            },
          },
        };
      }
      state.isLoading = false;
      state.isError = false;
    });

    builder.addCase(getMqttCredentials.fulfilled, (state, action) => {
      if (action.payload.status === 200) {
        state.mqttData = action.payload.data;
        state.isError = false;
      } else {
        state.isError = true;
      }
      state.isLoading = false;
    });

    builder.addCase(addMqttCredentials.fulfilled, (state, action) => {
      if (action.payload.responseBody.status === 200) {
        state.mqttData = action.payload.responseBody.data;
        state.isError = false;
      } else {
        state.isError = true;
      }
      state.isLoading = false;
    });

    builder.addCase(updateMqttCredentials.fulfilled, (state, action) => {
      if (action.payload.responseBody.status === 200) {
        state.mqttData = action.payload.responseBody.data;
        state.isError = false;
      } else {
        state.isError = true;
      }
      state.isLoading = false;
    });

    //
  },
});

export default projectSettingSlice.reducer;
