import React, { useState } from "react";
import styles from "./ContextMenu.module.css";

const ContextMenu = ({ x, y , handleRenameTab,handleDeleteTab, clickedTabIndex }) => {
  return (
    <div
      className={styles.ContextMenu}
      style={{ top: y, left: x, boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)" }}
    >
      <button className={styles.renameBtn} onClick={handleRenameTab} >Rename</button>
      <button className={styles.deleteBtn} onClick={clickedTabIndex > 3 ? handleDeleteTab : null} >Delete</button>
    </div>
  );
};

export default ContextMenu;
