import React, { useEffect, useState, useRef } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

const PieChart = ({
  openNewPage,
  dateTimesLive,
  channelNames,
  dataArrLive,
  getRandomColor,
  chartChannel,
  tabIndexPieChart,
  selectedTabIndex,
  selectedDevice,
  clickedDeviceIndex,
  id,
}) => {
  const [pieChartData, setPieChartData] = useState([]);
  ChartJS.register(ArcElement, Tooltip, Legend);
  useEffect(() => {
    // availableChannels which last value in array is not null
    const availableChannels = dataArrLive.filter(
      (data, index) => data.data.slice(-1)[0] !== null
    );

    //available channel names
    const availableChannelsNames = availableChannels.map((data) => data.label);

    let lastValues = [];

    if (availableChannelsNames.length > 0) {
      availableChannelsNames.forEach((data) => {
        if (availableChannels?.length > 0) {
          lastValues = availableChannels?.map(
            (chart, index) => chart?.data.slice(-1)[0]
          );
        }
      });
    } else {
      lastValues = [50, 50];
    }

    const matchedPairs = [];
    chartChannel?.forEach((label) => {
      const matchedObj = dataArrLive.find((data) => data.label === label);
      if (matchedObj) {
        matchedPairs.push(matchedObj);
      }
    });

    let channelsLastValues;
    channelsLastValues =
      matchedPairs?.length > 0
        ? matchedPairs?.map((chart) => chart?.data.slice(-1)[0])
        : lastValues;

    const newPieChartData = {
      labels:
        chartChannel?.length > 0
          ? chartChannel
          : availableChannelsNames.length > 0
          ? availableChannelsNames
          : ["channel1", "channel2"],
      datasets: [
        {
          label: "channel",
          data: channelsLastValues,
          backgroundColor:
            dataArrLive.length > 0
              ? dataArrLive.map(() => getRandomColor())
              : ["#00e4ec", "#501754"],
        },
      ],
    };

    setPieChartData(newPieChartData);
  }, [dateTimesLive, selectedTabIndex, chartChannel]);

  return (
    <div>
      {pieChartData && pieChartData.labels ? (
        <Pie data={pieChartData} />
      ) : (
        <p>Loading chart data...</p>
      )}
    </div>
  );
};

const NewPieChart = ({
  dateTimesLive,
  channelNames,
  dataArrLive,
  getRandomColor,
  chartChannel,
  selectedTabIndex,
}) => {
  const [newChartData, setNewChartData] = useState([]);
  const chartInstance = useRef(null);
  ChartJS.register(ArcElement, Tooltip, Legend);
  useEffect(() => {
    
    // availableChannels which last value in array is not null
    const availableChannels = dataArrLive.filter(
      (data, index) => data.data.slice(-1)[0] !== null
    );
    //available channel names
    const availableChannelsNames = availableChannels.map((data) => data.label);

    const lastValues =
      chartChannel !== undefined
        ? chartChannel.map(
            (header, index) => dataArrLive[index].data.slice(-1)[0]
          )
        : availableChannels.length > 0
        ? availableChannels.map((header, index) => header.data.slice(-1)[0])
        : [50, 50];

    let chartLabels;
    chartLabels =
      chartChannel !== undefined
        ? chartChannel?.map((label) =>
            availableChannelsNames?.find((dataset) => dataset === label)
          )
        : availableChannelsNames.length > 0
        ? availableChannelsNames
        : ["channel1, channel2"];

         const chart = chartInstance.current;
         if(chart){
          chart.update()
         }

    const newPieChartData = {
      labels: chartLabels,
      datasets: [
        {
          label: "channel",
          data: lastValues,
          backgroundColor:
            dataArrLive.length > 0
              ? dataArrLive.map(() => getRandomColor())
              : ["#00e4ec", "#501754"],
        },
      ],
    };

    // #51f9f4

    setNewChartData(newPieChartData);
  }, [dateTimesLive, selectedTabIndex, chartChannel]);

  return (
    <div>
      {newChartData && newChartData.labels ? (
        <Pie data={newChartData} />
      ) : (
        <p>Loading chart data...</p>
      )}
    </div>
  );
};

export { PieChart, NewPieChart };
