import { ReactComponent as Logo } from '../assets/Logo.svg';

const Loader = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(255, 255, 255, 0.5)",
                backdropFilter: "blur(3px)",
                zIndex: 999,
            }}
        >
            <div>
                <Logo/>
            </div>
        </div>
    );
}

export default Loader;