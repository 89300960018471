import proxy from "proxy";
import styles from "./Auth2Toggle.module.css";
import DeleteModelProfile from "../Others/DeleteConfirmationModel";
import React, { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  logoutAllDevice,
  toggleTwoStep,
} from "../../../../redux/slices/profileSlice.js";

function Auth2StepModel({ data, setModel }) {
  const profileData = useSelector(state => state.profile.profileData);
  const [isToggle, setIsToggle] = useState();
  const [confirmPwd, setConfirmPwd] = useState(false);
  const [confirmPwdLogOut, setConfirmPwdLogOut] = useState(false);
  const [logOutBody, setLogOutBody] = useState({});
  const [toggleAuth, setToggleAuth] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toggleTwoStepMsg = useSelector(
    (state) => state.profile.toggleTwoStepMsg
  );

  const logoutAllDeviceMsg = useSelector(
    (state) => state.profile.logoutAllDeviceMsg
  );

  useEffect(() => {
    setIsToggle(profileData?.Step2Auth);
  }, [profileData]);

  const toast = useToast();
  const handleToggle = async () => {
    setConfirmPwd(true);

    const apiBody = {
      step2: !isToggle,
      email: localStorage.getItem("email"),
    };
    setToggleAuth(apiBody);
  };

  async function DeleteAccount(event) {
    setModel(true);
  }

  const logoutFromALlDevice = async () => {
    const logoutApiBody = {
      step2: !isToggle,
      email: localStorage.getItem("email"),
    };
    setLogOutBody(logoutApiBody);

    setConfirmPwdLogOut(true);
  }



  return (
    <>
      <div className={styles.Auth2Step}>
        <div className={styles.toggleWrapper}>
          <label className={styles.label}> 2 Step-Authentication</label>
          <div
            className={`${styles.toggleBox} ${isToggle ? styles.active : ""}`}
            onClick={handleToggle}
          >
            <span
              className={`${styles.toggle} ${isToggle ? styles.active : ""}`}
            ></span>
          </div>
        </div>
        <button className={styles.deleteAccountBtn} onClick={logoutFromALlDevice}>Logout from All Devices</button>
        <button className={styles.deleteAccountBtn} onClick={DeleteAccount}>Delete Account</button>
      </div>
      {confirmPwd && <DeleteModelProfile setConfirmPwd={setConfirmPwd} isToggle={isToggle} setIsToggle={setIsToggle} toggleAuth={toggleAuth} type="toggle" header="Confirm password to activate 2 step-authentication" />}
      {confirmPwdLogOut && <DeleteModelProfile setConfirmPwdLogOut={setConfirmPwdLogOut} logOutBody={logOutBody} type="logout" header="Confirm password to logout from all devices" />}
    </>
  );
}

export default Auth2StepModel;
