import React, { useEffect, useState } from 'react'
import styles from "./CurrentPlanDetails.module.css";
import { useDispatch, useSelector } from 'react-redux';
import { getActivePlans } from '../../../redux/slices/billingSlice.js';

const CurrentPlanDetails = () => {

  const dispatch = useDispatch();

  const activePlans = useSelector((state) => state.billing.activePlans);

  const [currentPlans, setCurrentPlans] = useState([]);

  useEffect(() => {
    const email = localStorage.getItem("email");
    dispatch(getActivePlans(email))
  }, [])

  useEffect(() => {
    if (activePlans.length > 0) {
      let parsedData = activePlans.map(bill => {
        let parsedBill = { ...bill };

        // Create a shallow copy of the bill object
        const parsedDate = (date) => {
          const dateObj = new Date(date);
          const day = dateObj.getDate();
          const month = dateObj.getMonth() + 1;
          const year = dateObj.getFullYear();
          const hour = dateObj.getHours();
          const minutes = dateObj.getMinutes();
          const seconds = dateObj.getSeconds();
  
          return`${day}-${month}-${year}  ${hour}:${minutes}:${seconds}`;
        }

        const paymentDate =  parsedDate(parsedBill.paymentDate);
        const dueDate = parsedDate(parsedBill.dueDate);
        parsedBill.paymentDate = paymentDate;
        parsedBill.dueDate = dueDate;

        if (parsedBill.currentPlan === "S") {
          parsedBill.currentPlan = "Starter";
        } else if (parsedBill.currentPlan === "L") {
          parsedBill.currentPlan = "Lite";
        } else if (parsedBill.currentPlan === "P") {
          parsedBill.currentPlan = "Pro";
        } else if (parsedBill.currentPlan === "AOU") {
          parsedBill.currentPlan = "Add-on User";
        } else {
          parsedBill.currentPlan = "Add-on Project";
        }
        return parsedBill;
      });

      setCurrentPlans(parsedData)
    }
  }, [activePlans]);


  return (
    <div className={styles.currentPlanDetails}>
      <div className={styles.headers}>
        <div className={styles.header}>Sr.NO</div>
        <div className={styles.header}>Plan</div>
        <div className={styles.header}>Amount</div>
        <div className={styles.header}>Payment Date</div>
        <div className={styles.header}>Due Date</div>

      </div>
      <div className={styles.billsContainer}>
        {currentPlans.length > 0 && (
          currentPlans?.map((bill, index) => (
            
            <div className={styles.headerValues}>
              <div className={styles.headerValue}>{index+1}</div>
              <div className={styles.headerValue}>{bill.currentPlan}</div>
              <div className={styles.headerValue}>₹{bill.amount}</div>
              <div className={styles.headerValue}>{bill.paymentDate}</div>
              <div className={styles.headerValue}>{bill.dueDate}</div> 
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export default CurrentPlanDetails