import React, { useState, useMemo } from 'react';
import styles from './modelPDFDetails.module.css';
import close from 'assets/close.png';
import { jsPDF } from 'jspdf';
import "jspdf-autotable";
import autoTable from "jspdf-autotable";


const ModelPDFDetails = ({ setOpenModel, data, header }) => {
  const [error, setError] = useState(null);
  const [selectedImg, setSelectedImg] = useState();

  const handleImgChange = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageUrl = URL.createObjectURL(file);
        setSelectedImg(imageUrl);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleSave = () => {
    if (!error) {
      setOpenModel(false);
    } else {
      setOpenModel(true);
    }

    const doc = new jsPDF();
    const fileName = document.getElementById('fileName').value;
    const fileTitle = document.getElementById('fileTitle').value;
    const fileLogo = document.getElementById('fileLogo').files[0];
    let width = document.getElementById('fileLogo').clientWidth;
    while (width > 20) {
      width /= 2;
    }
    doc.setFontSize(25);
    if (fileLogo) {
      if (fileLogo.type === 'image/jpeg') {
        doc.addImage(selectedImg, 'JPEG', 5, 15, 17, 20);
        doc.text(fileTitle, 35, 45);
      } else {
        doc.addImage(selectedImg, 'PNG', 25, 15, width + 16, width);

        doc.text(fileTitle, 35, 45);
      }
    }
 
    autoTable(doc, {
      head: [header],
      body: data, // Exclude headers from body
   });
    doc.save(fileName + '.pdf');
  };
  const handleCloseModel = () => {
    setOpenModel(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.modelWrapper}>
        <div className={styles.modelHeader}>
          <h3>PDF Details</h3>
          <button className={styles.closeBtn} onClick={handleCloseModel}>
            <img src={close} alt="" />
          </button>
        </div>
        <div className={styles.inputWrapper}>
          <input type="text" placeholder="File Name" id="fileName" />
          <input type="text" placeholder="File Title" id="fileTitle" />
          <label>PDF Logo:</label>
          <input
            className={styles.fileAlign}
            type="file"
            placeholder="Upload Logo"
            id="fileLogo"
            onChange={handleImgChange}
            accept=".jpg, .jpeg, .png"
          />
        </div>
        <button className={styles.saveBtn} onClick={handleSave}>
          Save
        </button>

        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>
    </div>
  );
};

export default ModelPDFDetails;
