import React from 'react'
import styles from './AlarmLogs.module.css'
import LogCollection from './components/LogCollection'

const AlarmLogs = ({setPageTitle}) => {
  return (
    <div className={styles.alarmLogs}>
        <div className={styles.wrapper}>
          <div className={styles.bodyWrapper}>
            <LogCollection/>
          </div>
        </div>
    </div>
  )
}

export default AlarmLogs