import React, { useEffect, useState } from "react";
import styles from "./SettingsModel.module.css";
import close from "../../../assets/Close.svg";
import Toggle from "./Toggle";

const NotificationsettingsModel = ({ setIsSettingModel }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [isToggle, setIsToggle] = useState(false);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleToggle = () => {
    setIsToggle(!isToggle);
  };

  return (
    <>
      <div className={styles.modelOverlay}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <h1 className={styles.setting}>Settings</h1>
            <button
              className={styles.closeBtn}
              onClick={() => setIsSettingModel(false)}
            >
              <img src={close} alt="" className={styles.closeBtnImg} />
            </button>
          </div>
          <div className={styles.modalContent}>
            <div className={styles.inbox}>Status</div>
            <div className={styles.radioBtn}>
              <div className={styles.btns}>
                <input
                  type="radio"
                  id="option1"
                  name="options"
                  value="option1"
                  onChange={handleOptionChange}
                />
                <label>Default</label>
              </div>
              <div className={styles.btns}>
                <input
                  type="radio"
                  id="option2"
                  name="options"
                  value="option2"
                  onChange={handleOptionChange}
                />
                <label>Important</label>
              </div>
              <div className={styles.btns}>
                <input
                  type="radio"
                  id="option3"
                  name="options"
                  value="option3"
                  onChange={handleOptionChange}
                />
                <label>Unread</label>
              </div>
            </div>
            <div className={styles.inbox}>Notification Type</div>
            <div className={styles.radioBtn}>
              <div className={styles.btns}>
                <input
                  type="radio"
                  id="option4"
                  name="option"
                  value="option4"
                  onChange={handleOptionChange}
                />
                <label>Email</label>
              </div>
              <div className={styles.btns}>
                <input
                  type="radio"
                  id="option5"
                  name="option"
                  value="option5"
                  onChange={handleOptionChange}
                />
                <label>Push notifications</label>
              </div>
              <div className={styles.btns}>
                <input
                  type="radio"
                  id="option6"
                  name="option"
                  value="option6"
                  onChange={handleOptionChange}
                />
                <label>In-app notifications</label>
              </div>
            </div>
            <div className={styles.inbox}>
              <span>Turn Off Notification</span>
              <Toggle
                setIsToggle={setIsToggle}
                isToggle={isToggle}
                handleToggle={handleToggle}
              />
            </div>
            <div className={styles.radioBtn}>
              <div
                className={styles.btns}
                style={{
                  color: isToggle ? "#000000" : "rgba(174, 174, 174, 1)",
                }}
              >
                <input
                  type="radio"
                  id="option7"
                  name="opt"
                  onChange={handleOptionChange}
                  disabled={!isToggle}
                />
                <label>8 Hours</label>
              </div>
              <div
                className={styles.btns}
                style={{
                  color: isToggle ? "#000000" : "rgba(174, 174, 174, 1)",
                }}
              >
                <input
                  type="radio"
                  id="option8"
                  name="opt"
                  onChange={handleOptionChange}
                  disabled={!isToggle}
                />
                <label>1 Day</label>
              </div>
              <div
                className={styles.btns}
                style={{
                  color: isToggle ? "#000000" : "rgba(174, 174, 174, 1)",
                }}
              >
                <input
                  type="radio"
                  id="option9"
                  name="opt"
                  onChange={handleOptionChange}
                  disabled={!isToggle}
                />
                <label>Until I Change It</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationsettingsModel;
