import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import proxy from 'proxy';

const initialState = {
  isLoading: false,
  isError: false,
  profileData: {},
  changePassMsg: null,
  toggleTwoStepMsg: null,
  logoutAllDeviceMsg: null,
  deleteAccMsg: null,
  isVerifyOTP: false,
  isOTP: false,
};

// get profile action
export const getUserProfile = createAsyncThunk(
  'getUserProfile',
  async (token) => {
    const response = await fetch(`${proxy}/api/profile`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Getter ${token}`,
      },
    });
    const responseBody = await response.json();
    return responseBody;
  },
);

// update profile action
export const updateProfile = createAsyncThunk('updateProfile', async (data) => {
  let { token, valuesToUpdate } = data;

  const response = await fetch(`${proxy}/api/update`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Getter ${token}`,
    },
    body: JSON.stringify(valuesToUpdate),
  });
  const status = response.status;
  valuesToUpdate.status = status;
  await response.json();
  return valuesToUpdate;
});

// profile picture update action

export const updateProfilePicture = createAsyncThunk(
  'updateProfilePicture',
  async (data) => {
    const { email, newProfilePicture } = data;
    const response = await fetch(
      `${proxy}/api/updateProfilePicture?email=${email}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': `application/json`,
        },
        body: JSON.stringify(newProfilePicture),
      },
    );
    const status = response.status;
    await response.json();
    return {newProfilePicture, status};
  },
);

// change password action
export const chnagePassword = createAsyncThunk(
  'chnagePassword',
  async (passwordValues) => {
    const response = await fetch(`${proxy}/api/changepassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(passwordValues),
    });
    const responseBody = await response.json();
    return responseBody;
  },
);

//Toggle two step auth action

export const toggleTwoStep = createAsyncThunk('toggleTwoStep', async (data) => {
  const response = await fetch(`${proxy}/api/twoStepFactorCheck`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  const responseBody = await response.json();
  return responseBody;
});

// delete account action

export const logoutAllDevice = createAsyncThunk(
  'logoutAllDevice',
  async (data) => {
    const response = await fetch(`${proxy}/auth/logoutFromAllDevice`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const responseBody = await response.json();
    return responseBody;
  },
);

//delete account action

export const deleteAccount = createAsyncThunk('deleteAccount', async (data) => {
  const response = await fetch(`${proxy}/users/deleteUser`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const responseBody = await response.json();
  return responseBody;
});

// verify otp action
export const verifyOTP = createAsyncThunk('verifyOTP', async (data) => {
  const response = await fetch(`${proxy}/api/EmailOTP2Factor`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const responseBody = await response.json();
  return responseBody;
});

// get otp action
export const getOTP = createAsyncThunk('getOTP', async (data) => {
  const response = await fetch(`${proxy}/api/email2factorAuth`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const responseBody = await response.json();
  return responseBody;
});

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  extraReducers: (builder) => {
    // get profile
    builder.addCase(getUserProfile.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });

    builder.addCase(getUserProfile.fulfilled, (state, action) => {
      state.profileData = action.payload;
      state.isLoading = false;
      state.isError = false;
    });

    builder.addCase(getUserProfile.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });

    //update profile
    builder.addCase(updateProfile.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });

    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.profileData = {
        ...state.profileData,
        city: action.payload.city,
        country: action.payload.country,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        fullAddress: action.payload.fullAddress,
        gstNumber: action.payload.gstNumber,
        name: action.payload.name,
        phoneNumeber: action.payload.phoneNumeber,
        pincode: action.payload.pincode,
        state: action.payload.state,
      };
      state.isLoading = false;
      state.isError = false;
    });

    builder.addCase(updateProfile.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });

    // update profile picture

    builder.addCase(updateProfilePicture.pending, (state, action) => {
      state.isError = false;
      state.isLoading = true;
    });

    builder.addCase(updateProfilePicture.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.profileData = {
        ...state.profileData,
        avatar: action.payload.newProfilePicture.ProfilePhoto,
      };
    });

    builder.addCase(updateProfilePicture.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });

    // change password

    builder.addCase(chnagePassword.pending, (state, action) => {
      state.isError = false;
      state.isLoading = false;
    });

    builder.addCase(chnagePassword.fulfilled, (state, action) => {
      state.isError = false;
      state.isLoading = false;
      state.changePassMsg = action.payload;
    });

    builder.addCase(chnagePassword.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });

    //auth two step toggle

    builder.addCase(toggleTwoStep.pending, (state, action) => {
      state.isError = false;
      state.isLoading = true;
    });

    builder.addCase(toggleTwoStep.fulfilled, (state, action) => {
      state.isError = false;
      state.isLoading = false;
      state.toggleTwoStepMsg = action.payload;
    });

    builder.addCase(toggleTwoStep.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });

    // log out from all device

    builder.addCase(logoutAllDevice.pending, (state, action) => {
      state.isError = false;
      state.isLoading = true;
    });

    builder.addCase(logoutAllDevice.fulfilled, (state, action) => {
      state.isError = false;
      state.isLoading = false;
      state.logoutAllDeviceMsg = action.payload;
    });

    builder.addCase(logoutAllDevice.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });

    // delete account
    builder.addCase(deleteAccount.pending, (state, action) => {
      state.isError = false;
      state.isLoading = true;
    });

    builder.addCase(deleteAccount.fulfilled, (state, action) => {
      state.isError = false;
      state.isLoading = false;
      state.deleteAccMsg = action.payload;
    });

    builder.addCase(deleteAccount.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });

    // verify otp
    builder.addCase(verifyOTP.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
      state.isVerifyOTP = false;
    });

    builder.addCase(verifyOTP.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isVerifyOTP = true;
    });

    builder.addCase(verifyOTP.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
      state.isVerifyOTP = false;
    });

    // get auth otp
    builder.addCase(getOTP.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
      state.isOTP = false;
    });

    builder.addCase(getOTP.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isOTP = true;
    });

    builder.addCase(getOTP.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
      state.isOTP = false;
    });
  },
});

export default profileSlice.reducer;
