import React, { useEffect, useState } from 'react'
import styles from "./PlanModel.module.css"
import proxy from '../../../../proxy';
import close from "../../../../assets/Close.svg";
import { useDispatch, useSelector } from 'react-redux';
import { createOrder, getPlanDetails } from '../../../../redux/slices/billingSlice';
import { useToast } from '@chakra-ui/react';


const loadScript = (src) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => resolve();
        script.onerror = (error) => reject(error);
        document.body.appendChild(script);
    });
};

const PlanModel = ({ setIsRenewModal, selectedUserEmail, operation, customerId }) => {
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState("Plans");
    const [activeTimeOption, setActiveTimeOption] = useState("Yearly");
    const [clickedCardIndex, setClickedCardIndex] = useState(null);
    const [plans, setPlans] = useState([])
    const [addOnPlans, setAddonPlans] = useState([]);
    const toast = useToast();

    const planDetails = useSelector((state) => state.billing.planDetails);


    const CLIENT_ID = "1000.JEJXGPR11407VRA6Y58PZIF0CLFHYC";
    const REDIRECTED_URL = "http://localhost:3000/adminUsers";
    const CLIENT_SECRET = "e9dc494b1ca2b387c2abdf7d47f923657f6aa5b7b1";

    const [multiplier, setMultiplier] = useState(1);
    const [payLater, setPayLater] = useState("");

    const [discountInt, setDiscountInt] = useState("");



    useEffect(() => {

        const multiplierString = localStorage.getItem("Multiplier");

        if (multiplierString) {
            setMultiplier(parseFloat(multiplierString));
        }

        dispatch(getPlanDetails())
    }, []);

    useEffect(() => {
        const payLaterVal = localStorage.getItem("payLater");

        if (payLaterVal) {
            setPayLater(payLaterVal);
        }

        const discount = localStorage.getItem("discount");

        if (discount) {
            setDiscountInt(discount);
        }

    }, []);


    useEffect(() => {
        console.log(planDetails);

        setPlans(planDetails.slice(0, 3))
        setAddonPlans(planDetails.slice(3, 5));

    }, [planDetails]);


    const handleBuyPlan = async (index) => {

        setClickedCardIndex(null);

        try {
            await loadScript("https://checkout.razorpay.com/v1/checkout.js");

            const planName = document.getElementById(`planName${index}`).innerHTML.charAt(0).toUpperCase();

            const planPrice = document.getElementById(`planPrice${index}`).innerHTML;
            const userEmail = localStorage.getItem("adminEmail");

            const orderBody = {
                amount: Number(planPrice),
                email: selectedUserEmail,
                planId: planName,
                // previousPlanAmount: currentPlanAmount
            }
            await dispatch(createOrder(orderBody)).then((response) => {
                const orderData = response.payload;
                if (response.payload.status === 409) {
                    toast({
                        title: "You cannot change plan before expire the plan",
                        status: "error",
                        position: "top-right",
                        duration: 5000,
                        isClosable: true,
                    });
                }

                const options = {
                    key: process.env.KEY_ID,
                    amount: orderData.amount,
                    currency: orderData.currency,
                    name: 'Dicot Innovation Private Limited',
                    description: 'Test Transaction',
                    image: 'https://dicot.tech/Assets/images/Logo.svg',
                    order_id: orderData.orderId,
                    prefill: {
                        name: "test",
                        email: selectedUserEmail,
                    },
                    notes: {
                        address: 'Razorpay Corporate Office',
                    },
                    theme: {
                        color: '#060623',
                    },
                    handler: async function (response) {
                        await fetch(`${proxy}/bill/payment/verify`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${localStorage.getItem("token")}`
                            },
                            body: JSON.stringify({
                                razorpay_payment_id: response.razorpay_payment_id,
                                order_id: orderData.orderId,
                                razorpay_signature: response.razorpay_signature,
                                planId: planName,
                                amount: orderData.amount / 100,
                                payCycle: activeTimeOption.toLowerCase(),
                                noOfDays: activeTimeOption === "Yearly" ? 365 : 28,
                                email: selectedUserEmail
                            })
                        }).then((responseBody) => {
                            if (responseBody.status === 200) {
                                toast({
                                    title: "Payment Successful",
                                    status: "success",
                                    position: "top-right",
                                    duration: 5000,
                                    isClosable: true,
                                });
                            } else {
                                toast({
                                    title: "Payment Unsuccessful",
                                    status: "error",
                                    position: "top-right",
                                    duration: 5000,
                                    isClosable: true,
                                });
                            }
                        });

                    },
                };

                const rzp = new window.Razorpay(options);
                // Open Razorpay modal
                rzp.open();
            })

        } catch (error) {
            console.log("Error :", error)
        }


    }

    const handlePlanPayLater = async (
        e,
        index,
        itemId,

    ) => {
        e.preventDefault();
        console.log(itemId);
        const planName = document.getElementById(`planName${index}`).innerHTML.charAt(0).toUpperCase();

        const planPrice = document.getElementById(`planPrice${index}`).innerHTML;

        const response = await fetch(`${proxy}/bill/createInvoice`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                planId: planName,
                adminEmail: localStorage.getItem("adminEmail"),
                userEmail: selectedUserEmail,
                itemId: itemId,
                duration: 1,
                amount: planPrice,
                customerId: customerId,
                discount: discountInt,
                payCycle: activeTimeOption,
                operation: "new"
            }),
        });
        const status = response.status;
        const responseBody = await response.json();

        if (status === 200) {
            toast({
                title: responseBody.message || "Invoice created successfully",
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
            });
        } else {
            toast({
                title: responseBody.message || "Something went wrong",
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
            });
        }
        setIsRenewModal(false);
    };



    return (
        <div className={styles.modalPage}>
            <div className={styles.modalContainer}>
                <img src={close} alt="close" srcset="" className={styles.closeImg} onClick={() => setIsRenewModal(false)} />
                <div className={styles.explorePlan}>
                    <div className={styles.optionsWrapper}>

                        <div className={styles.options}>
                            <button className={styles.optionName} style={{ fontWeight: `${activeTab === "Plans" ? "700" : "400"}` }} onClick={() => setActiveTab("Plans")}>Plans</button>
                        </div>

                        <div className={styles.timeOptions}>
                            <button className={`${styles.timeOptionName} ${activeTimeOption === "Monthly" && styles.active}`} onClick={() => setActiveTimeOption("Monthly")}>Monthly</button>
                            <button className={`${styles.timeOptionName} ${activeTimeOption === "Yearly" && styles.active}`} onClick={() => setActiveTimeOption("Yearly")}>Yearly</button>
                        </div>
                    </div>

                    {activeTab === "Plans" && (

                        <div className={styles.cardsWrapper}>
                            {plans?.map((card, index) => (

                                <div className={`${index === 1 ? styles.mainCard : styles.card}`} >
                                    <div className={styles.upperPart}>
                                        <div className={`${index === 1 ? styles.mainCardTitle : styles.cardTitle}`} id={`planName${index}`}>{card.planName}</div>
                                        <div className={`${index === 1 ? styles.mainCardTitlePrice : styles.cardPrice}`} > <span id={`planPrice${index}`}>{activeTimeOption === "Yearly" ? (card.yearlyPrice * multiplier * 1.18).toFixed(2) : (card.monthlyPrice * multiplier * 1.18).toFixed(2)}</span><span className={styles.slash} style={{ color: `${index === 1 && "#000"}` }}>{activeTimeOption === "Yearly" ? "/Year" : "/Month"}</span></div>

                                    </div>
                                    <div className={styles.paymentBtns}>

                                        <button
                                            className={`${index === 1 ? styles.mainCardBtn : styles.cardBtn}`} onClick={() => handleBuyPlan(index, card.itemId)} >
                                            Buy Now
                                        </button>
                                        {payLater === "true" && (
                                            <button className={`${index === 1 ? styles.mainCardBtn : styles.cardBtn}`} onClick={(e) => handlePlanPayLater(e, index, card.itemId)} >Pay Later</button>
                                            // item id : 2048159000000031013
                                        )}
                                    </div>
                                </div>
                            ))}

                        </div>
                    )}

                </div>

            </div>
        </div>
    )
}

export default PlanModel