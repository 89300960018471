import React from "react";
import styles from "./ConfirmPage.module.css";
import stair from "assets/stair.svg";
import Star from "assets/Star.svg";
import Tube from "assets/Tube.svg";
import UTube from "assets/UTube.svg";
import Pill from "assets/Pill.svg";
import Semicircle from "assets/Semicircle.svg";
import Periwinkle from "assets/Periwinkle.svg";
import Periwinkle2 from "assets/Periwinkle-2.svg";
import Periwinkle3 from "assets/Periwinkle-3.svg";
import Periwinkle4 from "assets/Periwinkle4.svg";
import zigZag from "assets/zig-zag.svg";
import Star2 from "assets/Star2.svg";
import Tube2 from "assets/Tube2.svg";
import Star3 from "assets/Star3.svg";
import Star4 from "assets/Star4.svg";
import logo from "assets/Logo.svg";

const ConfirmPage = () => {
  return (
    <div className={styles.lastPage}>
      <img src={Periwinkle4} alt="" srcset="" className={styles.periwinkle4} />
      <img src={Pill} alt="" srcset="" className={styles.pill} />
      <img src={stair} alt="" srcset="" className={styles.stair} />
      <img src={Star4} alt="" srcset="" className={styles.star4} />
      <img src={Tube2} alt="" srcset="" className={styles.tube2} />
      <img src={Star} alt="" srcset="" className={styles.star} />
      <img src={Tube} alt="" srcset="" className={styles.tube} />
      <img src={Periwinkle} alt="" srcset="" className={styles.periwinkle} />
      <img src={Periwinkle2} alt="" srcset="" className={styles.periwinkle2} />
      <img src={Semicircle} alt="" srcset="" className={styles.semicircle} />
      <img src={UTube} alt="" srcset="" className={styles.UTube} />
      <img src={Star2} alt="" srcset="" className={styles.star2} />
      <img src={Periwinkle3} alt="" srcset="" className={styles.periwinkle3} />
      <img src={zigZag} alt="" srcset="" className={styles.zigZag} />
      <img src={Star3} alt="" srcset="" className={styles.star3} />
      <div className={styles.textContainer}>
        <div className={styles.textWrapper}>
          <img src={logo} alt="" srcset="" className={styles.logo} />
          <div className={styles.bodyText}>
          <div className={styles.headText}>Yay, You are in!</div>
          
            <div className={styles.paraText}>
              Check your email for sign up confirmation link. You will be able
              to login once you have confirmed your account. Be sure to check
              your spam if you can’t find it. It should be from
              no-reply@dicot.tech
            </div>

            <div className={styles.footerText}>
              <p>Didn’t Receive the link?</p>
              <button className={styles.resendBtn}>Resend</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPage;
