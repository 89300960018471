import React, { useEffect, useState } from "react";
import styles from "./Organization.module.css";
import Management from "./components/Management/Management";
import AccessControl from "./components/Access Control/AccessControl";
import { useDispatch, useSelector } from "react-redux";
import { getRoles } from "../../redux/slices/organizationSlice.js";
import { fetchProjectData } from "../../redux/slices/projectSlice.js";

const Organization = ({ setPage, setPageTitle }) => {
  setPage("organization");
  setPageTitle("Organization");

  const [managementTab, setManagementTab] = useState(true);
  const [accessControlTab, setAccessControlTab] = useState(false);

  const [removeRoleModel, setRemoveRoleModel] = useState(false);

  const [createRole, setCreateRole] = useState([]);
  const [deleteRole, setDeleteRole] = useState([]);

  const [roleList, setRoleList] = useState([]);
  const [updatedPermissions, setUpdatedPermissions] = useState({});

  const [rolePermissions, setRolePermissions] = useState([]);

  const organizationEmail = localStorage.getItem(`organizationEmail`);
  const userEmail = localStorage.getItem("email");
  const dispatch = useDispatch();


  useEffect(() => {
    const token = localStorage.getItem("token");
    if(token){
      dispatch(fetchProjectData({ token: token , orgEmail : organizationEmail }));
    }
  }, []);

  const handleManagementTab = () => {
    setManagementTab(true);
    setAccessControlTab(false);
  };

  const handleAccessControlTab = () => {
    setManagementTab(false);
    setAccessControlTab(true);
  };

  const userPermission = useSelector(
    (state) => state.organization.userPermission
  );
  const allMemeberPermission = useSelector(
    (state) => state.organization.allMemeberPermission
  );

  //Fetch Roles (Used in Management & AccessControl Components)

  useEffect(() => {
    const data = {
      organizationEmail: organizationEmail,
      userEmail: userEmail,
    };
    dispatch(getRoles(data));
  }, [createRole, removeRoleModel, updatedPermissions]);

  useEffect(() => {
    setRolePermissions(allMemeberPermission);
    setRoleList(
      allMemeberPermission?.flatMap((roleDetails, index) => {
        return Object.keys(roleDetails).filter(
          (roleName) => roleName !== "_id"
        );
      })
    );
  }, [allMemeberPermission]);


  return (
    <div className={styles.organizationContainer}>
      <div className={styles.org}>
        <div className={styles.orgContent}>
          <button
            className={
              managementTab
                ? `${styles.managementTabBtn} ${styles.active}`
                : styles.managementTabBtn
            }
            onClick={handleManagementTab}
          >
            Management
          </button>
          <button
            className={
              accessControlTab
                ? `${styles.accessControlTabBtn} ${styles.active}`
                : styles.accessControlTabBtn
            }
            onClick={handleAccessControlTab}
          >
            Access Control
          </button>
        </div>
        {managementTab && (
          <Management
            deleteRole={deleteRole}
            setDeleteRole={setDeleteRole}
            roleList={roleList}
            createRole={createRole}
            setCreateRole={setCreateRole}
            removeRoleModel={removeRoleModel}
            setRemoveRoleModel={setRemoveRoleModel}
          />
        )}
        {userPermission.updatePermissions ? (
          accessControlTab ? (
            <AccessControl
              roleList={roleList}
              createRole={createRole}
              setCreateRole={setCreateRole}
              rolePermissions={rolePermissions}
              setRolePermissions={setRolePermissions}
              updatedPermissions={updatedPermissions}
              setUpdatedPermissions={setUpdatedPermissions}
            />
          ) : null
        ) : null}
      </div>
    </div>
  );
};

export default Organization;
