import React, { useState } from "react";
import styles from "./TransferOrg.module.css";
import { useToast } from "@chakra-ui/react";
import { transferOrganization } from "../../../../../redux/slices/organizationSlice.js";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import openEye from "../../../../../assets/openEye.png";
import closeEye from "../../../../../assets/closeEye.png"

const TransferOrganization = ({ setTransfer }) => {
  const toast = useToast();
  const [owner, setOwner] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  //Transfer Organization
  const transferOrg = async () => {
    const apiBody = {
      userEmail: localStorage.getItem(`email`),
      newOwnerEmail: owner,
      password: password.trim(),
    };

    dispatch(transferOrganization(apiBody)).then((response) => {
      if (response.payload.status === 200) {
        toast({
          title: "Successful",
          description: response.payload.message,
          status: "success",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
        setTransfer(false);
        localStorage.clear();
        navigate("/auth/login");
      } else {
        toast({
          title: "Unsuccessful",
          description: response.payload.message,
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      }
    });
  };

  return (
    <div className={styles.transferModel}>
      <div className={styles.modelWrapper}>
        <div className={styles.infoContainer}>
          <h2 className={styles.header}>Transfer Organization</h2>
          <p className={styles.para}>
            Enter the Email to transfer the organization.
          </p>
          <input
            className={styles.inputField}
            type="text"
            onChange={(e) => setOwner(e.target.value)}
            placeholder="Enter Email"
          />
          <div className={styles.inputWrapper}>
            <input
              className={styles.inputField}
              type={isPasswordVisible ? "text" : "password"}
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
            />
                {isPasswordVisible ? (
              <img src={openEye} alt="open eye" className={styles.eyeImg} onClick={() => setIsPasswordVisible(!isPasswordVisible)} />
            ) : (
              <img src={closeEye} alt="close eye" className={styles.eyeImg} onClick={() => setIsPasswordVisible(!isPasswordVisible)} />
            )}
          </div>
        </div>
        <div className={styles.btnContainer}>
          <button className={styles.greyBtn} onClick={() => setTransfer(false)}>
            Cancel
          </button>
          <button className={styles.blueBtn} onClick={transferOrg}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default TransferOrganization;
