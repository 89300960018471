import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const proxy = 'http://api.eu.v-box.net/box-data/api/we-data';

const initialState = {
  vNetSid: '',
};

export const vNetLogin = createAsyncThunk('vNetLogin', async (data) => {
  const { vNetUsername, vNetPassword, comid, compvtkey, ts, sign } = data;

  const commonHeaders = {
    comid: comid,
    compvtkey: compvtkey,
    ts: ts,
    sign: sign,
  };

  try {
    const response = await fetch(
      `${proxy}/login?alias=${vNetUsername}&password=${vNetPassword}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          common: JSON.stringify(commonHeaders),
        }
      },
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const responseBody = await response.json();
    return responseBody;
  } catch (error) {
    console.error('Error during login:', error);
  }
});

export const getVnetBoxes = createAsyncThunk('getVnetBoxes', async (data) => {
  const {  sid, comid, compvtkey, ts, sign } = data;

  const commonHeaders = {
    comid: comid,
    compvtkey: compvtkey,
    ts: ts,
    sign: sign,
    sid : sid
  };

  try {
    const response = await fetch(
      `${proxy}/boxs`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          common: JSON.stringify(commonHeaders),
        }
      },
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const responseBody = await response.json();
    return responseBody;
  } catch (error) {
    console.error('Error during login:', error);
  }
});

export const getVnetRealGroups = createAsyncThunk('getVnetRealGroups', async (data) => {
  const {  sid, comid, compvtkey, ts, sign, boxId } = data;

  const commonHeaders = {
    comid: comid,
    compvtkey: compvtkey,
    ts: ts,
    sign: sign,
    sid : sid,
    boxId: boxId
  };

  try {
    const response = await fetch(
      `${proxy}/realgroups?boxId=${boxId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          common: JSON.stringify(commonHeaders),
        }
      },
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const responseBody = await response.json();
    return responseBody;
  } catch (error) {
    console.error('Error during login:', error);
  }
});

export const getRealTimeTags = createAsyncThunk('getRealTimeTags', async (data) => {
  const { boxId, groupId, pageSize, pageIndex, sid, comid, compvtkey, ts, sign } = data;

  const commonHeaders = {
    boxId : boxId,
    groupId : groupId,
    pageSize : pageSize,
    pageIndex : pageIndex,
    comid: comid,
    compvtkey: compvtkey,
    ts: ts,
    sign: sign,
    sid : sid
  };

  try {
    const response = await fetch(
      `${proxy}/realdata?boxId=${boxId}&groupId=${groupId}&pageSize=${pageSize}&pageIndex=${pageIndex}`,
      {
        method: 'POST',
        // mode : "no-cors",
        headers: {
          'Content-Type': 'application/json',
          common: JSON.stringify(commonHeaders),
        }
      },
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const responseBody = await response.json();
    return responseBody;
  } catch (error) {
    console.error('Error during login:', error);
  }
});

export const updateTagValue = createAsyncThunk('updateTagValue', async (data) => {
  const {  monitorId, value,  sid, comid, compvtkey, ts, sign } = data;

  const commonHeaders = {
    monitorId : monitorId,
    value : value,
    comid: comid,
    compvtkey: compvtkey,
    ts: ts,
    sign: sign,
    sid : sid
  };

  try {
    const response = await fetch(
      `${proxy}/updrealdata?monitorId=${monitorId}&value=${value}`,
      {
        method: 'POST',
        // mode : "no-cors",
        headers: {
          'Content-Type': 'application/json',
          common: JSON.stringify(commonHeaders),
        }
      },
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const responseBody = await response.json();
    return responseBody;
  } catch (error) {
    console.error('Error during login:', error);
  }
});


const vNetSlice = createSlice({
  name: 'vNetSlice',
  initialState,
});

export default vNetSlice.reducer;
