import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import proxy from "proxy";

const initialState = {
    isLoading : false,
    isError : false,
    alarmLogData : [],
    alarmNames : []
}

export const getAlarmLog = createAsyncThunk("getAlarmLog", async(uniqueProjectId) => {
    const response = await fetch(`${proxy}/api/getAlarmLog?projectId=${uniqueProjectId}`,{
        method : "GET",
        headers : {
            "Content-Type": "application/json",
        },
    })
    const responseBody = await response.json();
    return responseBody;
})

export const alarmLogSlice = createSlice({
    name : "alarmLog",
    initialState,
    extraReducers : (builder) => {
        builder.addCase(getAlarmLog.pending , (state, action) => {
            state.isLoading = true;
            state.isError = false;
        });
        builder.addCase(getAlarmLog.fulfilled , (state, action) => {
            if(action.payload.status === 200) {
                state.alarmLogData = action.payload.alarmLogs;
                state.alarmNames = action.payload.alarmLogs.map((log, index) => log.alarmName);
                state.isError = false;
            }else{
                state.isError = true;
            }
            state.isLoading = false
        });
        builder.addCase(getAlarmLog.rejected , (state, action) => {
            state.isLoading = false;
            state.isError = true;
        })
    }
})

export default alarmLogSlice.reducer;
