/* eslint-disable import/no-anonymous-default-export */
export default () => {
    /*eslint-disable-next-line no-restricted-globals*/
    self.onmessage = function (e) {
        if (e.data.data.length > 0 && e.data.channelNames.length > 0) {
            const allData = e.data.data;
            const channelNames = e.data.channelNames;
            const timeArray = allData.map(dataPoint => {
                const date = new Date(dataPoint.time);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                const hour = String(date.getHours()).padStart(2, '0');
                const minute = String(date.getMinutes()).padStart(2, '0');
                
                return `${day}/${month}/${year} ${hour}:${minute}`;
            });
            const headers = [];
            allData.forEach(dataset => {
                const datasetData = dataset.data;
                datasetData.forEach(element => {
                    const header = element.Header;
                    if (headers.indexOf(header) === -1) {
                        headers.push(header);
                    }
                });
            });

            const removedHeaderIndex = [];
            headers.forEach((header, index) => {
                if(channelNames.indexOf(header) === -1){
                    removedHeaderIndex.push(index);
                }
            })
            const rowMajorArrayOfValues = allData.map((dataPoint) => {
                const rowHeaders = dataPoint.data.map(item => item.Header);
                return headers.map(header => {
                    const headerIndex = rowHeaders.indexOf(header);
                    if (headerIndex > -1) {
                        return parseFloat(dataPoint.data[headerIndex].value)
                    }
                    else {
                        return null;
                    }
                });
            });
            const rowMajorTableArray = rowMajorArrayOfValues.map((dataPoint, index) => {
                const newRow = [timeArray[index]];
                dataPoint.forEach((dataPointValue) => newRow.push(dataPointValue))
                return newRow;
            });
            const filteredTable = [];
            rowMajorTableArray.forEach((dataPoint) => {
                const newRow = [];
                dataPoint.forEach((dataPointValue, index) => {
                    if(removedHeaderIndex.indexOf(index - 1) === -1){
                        newRow.push(dataPointValue)
                    }
                });
                filteredTable.push(newRow);
            });

            postMessage({ tableArray: filteredTable });
        }
    }
};