import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

const LineChart = ({
  dateTimesLive,
  dataArrLive,
  getRandomColor,
  chartChannel,
  selectedTabIndex,
  data,
  hour,
  minute,
  oneDayDataTs,
}) => {
  const [chartOptions, setChartOptions] = useState([]);
  const [linechartData, setLineChartData] = useState([]);
  ChartJS.register(ArcElement, Tooltip, Legend);

  useEffect(() => {

    const setData = async () => {
      const timeArr = await dateTimesLive.map((item) => {
        const date = new Date(item);
        return isNaN(date) ? 0 : date.getTime();
      });
    const currentTime = new Date();
    let endTime = new Date(currentTime);
    endTime.setHours(currentTime.getHours() - hour);
    endTime.setMinutes(currentTime.getMinutes() - minute);

    let newEndTime;

    if (isNaN(endTime.getTime())) {
      newEndTime = new Date();
    } else {
      newEndTime = endTime;
    }
    const formattedCurrentTime = currentTime.toISOString();
    const formattedEndTime = newEndTime.toISOString();

    const filteredTimeArr = oneDayDataTs.filter((time) => {
      return time >= formattedEndTime && time <= formattedCurrentTime;
    });
   

      let channelsLastValues = [];

      const availableChannels = dataArrLive.filter(
        (data, index) => data.data.slice(-1)[0] !== null
      );
      
      //available channel names

      const matchedDatasets =
        (await chartChannel?.length) > 0
          ? chartChannel?.map((label) =>
          availableChannels?.find((dataset) => dataset.label === label)
            )
          : availableChannels;

      if (availableChannels.length !== 0 && matchedDatasets[0] !== undefined) {
        await matchedDatasets?.map((data) =>
          channelsLastValues?.push({
            label: data.label,
            data: data.data,
            backgroundColor: getRandomColor(),
          })
        );
      }

      const newChartData = {
        labels: filteredTimeArr.length > 0 ? filteredTimeArr : timeArr,
        datasets: channelsLastValues,
      };

      const options = {
        scales: {
          x: {
            type: "time",
          },
          y: {
            beginAtZero: true,
          },
        },
        plugins: {
          decimation: {
            enabled: true,
            algorithm: "lttb",
            threshold: 200,
          },
          zoom: {
            pan: {
              enabled: true,
              mode: "y",
              modifierKey: "ctrl",
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },

              mode: "y",
            },
          },
          legend: {
            align: "left", // Set the alignment to 'left'
            labels: {
              boxWidth: 5, // Adjust the width of the legend color box
              font: {
                size: 10, // Set the font size
              },
            },
          },
        },
      };
      setChartOptions(options);
      setLineChartData(newChartData);
    };
    setData();
  }, [dateTimesLive, selectedTabIndex, chartChannel, hour, minute]);

  return (
    <div>
      {linechartData && linechartData.labels ? (
        <Line data={linechartData} options={chartOptions} />
      ) : (
        <p>Loading chart data...</p>
      )}
    </div>
  );
};

const NewLineChart = ({
  dateTimesLive,
  dataArrLive,
  chartChannel,
  selectedTabIndex,
  getRandomColor,
  endHours,
  endMinutes,
  oneDayDataTs,
}) => {
  const [newChartData, setNewChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState([]);

  ChartJS.register(ArcElement, Tooltip, Legend);

  useEffect(() => {
    const timeArr = dateTimesLive.map((item) => {
      const date = new Date(item);
      return isNaN(date) ? 0 : date.getTime();
    });
    const currentTime = new Date();

    let endTime = new Date(currentTime);
    endTime.setHours(currentTime.getHours() - endHours);
    endTime.setMinutes(currentTime.getMinutes() - endMinutes);

    let newEndTime;

    if (isNaN(endTime.getTime())) {
      newEndTime = new Date();
    } else {
      newEndTime = endTime;
    }
    const formattedCurrentTime = currentTime.toISOString();
    const formattedEndTime = newEndTime.toISOString();

    const filteredTimeArr = oneDayDataTs.filter((time) => {
      return time >= formattedEndTime && time <= formattedCurrentTime;
    });



    const availableChannels = dataArrLive.filter(
      (data, index) => data.data.slice(-1)[0] !== null
    );
    

    let channelsLastValues = [];
    const matchedDatasets =
      chartChannel !== undefined
        ? chartChannel?.map((label) =>
        availableChannels?.find((dataset) => dataset.label === label)
          )
        : availableChannels;

    if (availableChannels.length !== 0) {
      matchedDatasets?.map((data) =>
        channelsLastValues?.push({
          label: data.label,
          data: data.data,
          backgroundColor: getRandomColor(),
        })
      );
    }

    const newChartData = {
      labels: filteredTimeArr.length > 0 ? filteredTimeArr : timeArr,
      datasets: channelsLastValues,
    };

    const options = {
      scales: {
        x: {
          type: "time",
        },
        y: {
          beginAtZero: true,
        },
      },
      animation: false,
      plugins: {
        decimation: {
          enabled: true,
          algorithm: "lttb",
          threshold: 200,
        },
        zoom: {
          pan: {
            enabled: true,
            mode: "y",
            modifierKey: "ctrl",
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },

            mode: "y",
          },
        },
        legend: {
          align: "left", // Set the alignment to 'left'
          labels: {
            boxWidth: 5, // Adjust the width of the legend color box
            font: {
              size: 10, // Set the font size
            },
          },
        },
      },
    };
    setChartOptions(options);
    setNewChartData(newChartData);
  }, [dateTimesLive, selectedTabIndex, chartChannel, endHours, endMinutes]);

  return (
    <div>
      {newChartData && newChartData.labels ? (
        <Line data={newChartData} options={chartOptions} />
      ) : (
        <p>Loading chart data...</p>
      )}
    </div>
  );
};

export { LineChart, NewLineChart };
