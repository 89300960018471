import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Connection from './components/Connection/Connection';
import ManageMembers from './components/Manage Members/ManageMembers';
import DerivedChannels from './components/DerivedChannel/DerivedChannels';
import SecurityAndData from './components/Security And Data/SecurityData';
import DangerZone from './components/Danger Zone/DangerZone';
import Info from './components/Information/Information';
import styles from './Settings.module.css';
import Navbar from './components/Navbar';
import { useSearchParams } from 'react-router-dom';
import Historical from '../View Data/components/Historical';
import ReportAutomation from "../Settings/components/Report Automation/ReportAutomation"
import { getProjectSetting } from '../../../../redux/slices/projectSetting.js';
import { useDispatch, useSelector } from 'react-redux';

const ProjectSettings = ({ setPage }) => {
  setPage('dashboard');
  const [currentRole, setCurrentRole] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [page, setpage] = useState('');
  const [searchParams] = useSearchParams();
  const [isPath, setIsPath] = useState('Connection');
  const dispatch = useDispatch();
  const settingsData = useSelector((state) => state.projectSetting.settingData);

  const ProjectID = searchParams.get('project');
  useEffect(() => {
    const token = localStorage.getItem('token');
    dispatch(getProjectSetting({ ProjectID, token }));
  }, []);

  useEffect(() => {
    if (Object.keys(settingsData).length > 0) {
      if (settingsData.data.admin === settingsData.currentEmail) {
        setCurrentRole('admin');
      }
    }
  }, [settingsData]);

  useEffect(() => {
    document.title = 'Settings';
    if (localStorage.getItem('token') === null) {
      navigate('/auth/login');
    }
    setLoading(false);
  }, [navigate]);

  return (
    <div className={styles.superWrapper}>
      <div className={styles.wrap}> <a href='/'>Dashboard</a> &gt; Settings &gt; {isPath}</div>
      <div className={styles.app}>
        <div className={styles.settingsWrapper}>
          <Navbar currentRole={currentRole} setIsPath={setIsPath} />
          <div className={styles.optionsWrapper}>
            <Routes>
              <Route
                path="/"
                element={<Connection setPage={setpage} isPath={isPath} />}
              />
              <Route
                path="/connection"
                element={<Connection setPage={setpage} isPath={isPath} />}
              />
              <Route
                path="/info"
                element={<Info setPage={setpage} isPath={isPath} />}
              />
              <Route
                path="/managemembers"
                element={<ManageMembers setPage={setpage} isPath={isPath} currentRole = {currentRole}/>}
              />
              <Route
                path="/securityanddata"
                element={<SecurityAndData setPage={setpage} isPath={isPath} />}
              />
              <Route
                path="/dangerzone"
                element={<DangerZone setPage={setpage} isPath={isPath} />}
              />
              <Route
                path="/history"
                element={
                  <Historical
                    setPage={setpage}
                    isPath={isPath}
                    uniqueProjectId={ProjectID}
                  />
                }
              />
              <Route
                path="/derivedchannels"
                element={
                  <DerivedChannels
                    setPage={setpage}
                    isPath={isPath}
                    uniqueProjectId={ProjectID}
                  />
                }
              />
                <Route
                path="/virtualchannel"
                element={
                  <DerivedChannels
                    setPage={setpage}
                    isPath={isPath}
                    uniqueProjectId={ProjectID}
                  />
                }
              />

              <Route
                  path="/reportautomation"
                  element={
                    <ReportAutomation
                      setPage={setpage}
                      isPath={isPath}
                      uniqueProjectId={ProjectID}
                    />
                  }
                /> 
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectSettings;
