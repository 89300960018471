import React, { useState, useEffect } from "react";
import styles from "./RemoveRole.module.css";
import { useToast } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { deleteRoles } from "../../../../../redux/slices/organizationSlice.js";
import openEye from "../../../../../assets/openEye.png";
import closeEye from "../../../../../assets/closeEye.png"

const RemoveRole = ({
  deleteRole,
  setDeleteRole,
  roleList,
  setRemoveRoleModel,
}) => {
  const toast = useToast();

  const dispatch = useDispatch();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [password, setPassword] = useState("");
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  useEffect(() => {
    // Reset state when the modal is closed
    return () => {
      setDeleteRole([]);
      setSelectAllChecked(false);
    };
  }, [setDeleteRole, setSelectAllChecked]);

  const handleSelectAll = () => {
    setSelectAllChecked(!selectAllChecked);
    // If select all is checked, set deleteRole to all roles,
    // otherwise, set deleteRole to an empty array
    if (!selectAllChecked) {
      setDeleteRole([...roleList]);
    } else {
      setDeleteRole([]);
    }
  };

  const handleCheckboxChange = (roleName) => {
    let updatedDeleteRole = [];
    if (deleteRole.includes(roleName)) {
      // If the roleName is already in deleteRole, remove it
      updatedDeleteRole = deleteRole.filter((role) => role !== roleName);
    } else {
      // If the roleName is not in deleteRole, add it
      updatedDeleteRole = [...deleteRole, roleName];
    }
    // Update deleteRole state
    setDeleteRole(updatedDeleteRole);
    // Update selectAllChecked state
    setSelectAllChecked(updatedDeleteRole.length === roleList.length);
  };

  const handleRemove = async () => {
    const apiBody = {
      role: deleteRole,
      organizationEmail: localStorage.getItem(`organizationEmail`),
      password: password.trim(),
      userEmail: localStorage.getItem(`email`),
    };

    dispatch(deleteRoles(apiBody)).then((response) => {
      if (response.payload.status === 200) {
        if (response.payload.message.fail.length > 0) {
          toast({
            title: "Unsuccessful",
            description: `${response.payload.message.fail.toString()} could not be deleted. They are assigned to a person.`,
            status: "error",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
        }
        if (response.payload.message.success.length > 0) {
          toast({
            title: "Successful",
            description: `${response.payload.message.success.toString()} is succesfully deleted.`,
            status: "success",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        toast({
          title: "Unsuccessful",
          description: response.payload.message,
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      }
      setRemoveRoleModel(false);
    });
  };

  return (
    <div className={styles.removeRoleModel}>
      <div className={styles.modelWrapper}>
        <div className={styles.infoContainer}>
          <h2 className={styles.header}>Remove Role</h2>
          <div className={styles.roleList}>
            <div className={styles.selectAll}>
              <input
                type="checkbox"
                checked={selectAllChecked}
                onChange={handleSelectAll}
              />
              <div>Select All</div>
            </div>
            {roleList.map((name, key) => (
              <div className={styles.roleSubList}>
                <input
                  id={key}
                  type="checkbox"
                  onChange={() => handleCheckboxChange(name)}
                  checked={deleteRole.includes(name)}
                />
                <div>{name}</div>
              </div>
            ))}
          </div>
          <hr />
          <div className={styles.inputWrapper}>

            <input
              className={styles.inputField}
              type={isPasswordVisible ? "text" : "password"}
              id="password"
              placeholder="Enter your password"
              onChange={(e) => setPassword(e.target.value)}
            />
            {isPasswordVisible ? (
              <img src={openEye} alt="open eye" className={styles.eyeImg} onClick={() => setIsPasswordVisible(!isPasswordVisible)} />
            ) : (
              <img src={closeEye} alt="close eye" className={styles.eyeImg} onClick={() => setIsPasswordVisible(!isPasswordVisible)} />
            )}
          </div>
        </div>
        <div className={styles.btnContainer}>
          <button
            className={styles.greyBtn}
            onClick={() => setRemoveRoleModel(false)}
          >
            Discard
          </button>
          <button className={styles.blueBtn} onClick={handleRemove}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default RemoveRole;
