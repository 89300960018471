import React from "react";
import styles from "./UpdateProjectModel.module.css";
import Close from "assets/close.png";
import { mappls } from "mappls-web-maps";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function MapComponent({ location, setNewLocation }) {
  useEffect(() => {
    var current;
    var currentLocationString;

    try {
      currentLocationString = location.trim().split(",");
    } catch {
      current = [23.0225, 72.5714];
    }

    if (currentLocationString.length < 2) {
      current = [23.0225, 72.5714];
    } else if (
      isNaN(currentLocationString[0]) ||
      isNaN(currentLocationString[1])
    ) {
      current = [23.0225, 72.5714];
    } else {
      current = currentLocationString.map(Number);
    }
    try {
      // Map Properties
      const mapProps = {
        center: current,
        traffic: false,
        zoom: 7,
        geolocation: false,
        clickableIcons: false,
      };

      // New Map Variable
      var mapplsClassObject = new mappls();
      // Map marker
      var marker;

      // Checking if the object exists
      if (mapplsClassObject && current) {
        // Inintializing with API key
        mapplsClassObject.initialize("4b5d031b33d1a6ecb0ddbc322dc81ab4", () => {
          const map = mapplsClassObject.Map({
            id: "map_",
            properties: mapProps,
          });
          if (map) {
            marker = mapplsClassObject.Marker({
              map: map,
              position: current,
            });
            map.on("click", async function (e) {
              const currentL = e.lngLat;
              const regex = /\d+\.\d+/g;
              const matches = currentL.toString().match(regex);
              setNewLocation(`${matches[1]}, ${matches[0]}`);
              if (marker) {
                marker.setPosition(currentL);
              } else {
                marker = mapplsClassObject.Marker({
                  map: map,
                  position: currentL,
                  clusters: false,
                });
              }
            });
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, [location]);
  return <></>;
}

const ProjectModel = ({
  setOpenModel,
  ProjectID,
  setInputValues,
  data,
  setLocation,
  location,
}) => {
  const settingsData = useSelector((state) => state.projectSetting.settingData);

  const HandleSave = async () => {
    const modifiedValues = {
      Project_id: ProjectID,
      projectName: document.getElementById("projectName").value,
      projectLocation: location,
      projectDesc: document.getElementById("desc").value,
    };
    setInputValues(modifiedValues);
    setOpenModel(false);
  };

  const setNewLocation = (newLocation) => {
    setLocation(newLocation);
  };

  return (
    <div className={styles.projectModel}>
      <div className={styles.container}>
        <div className={styles.closeBtnWrapper}>
          <h2 className={styles.titleText}>Update Project</h2>
          <button
            className={styles.closeBtn}
            onClick={() => setOpenModel(false)}
          >
            <img src={Close} alt="close" className={styles.closePng} />
          </button>
        </div>

        <div className={styles.inputWrapper}>
          <input
            type="text"
            placeholder="Project Name"
            id="projectName"
            defaultValue={settingsData.data.metadata.Project}
            className={styles.projectModalInput}
          />
          <input
            type="text"
            placeholder="Descripation"
            id="desc"
            defaultValue={settingsData.data.metadata.Description}
            className={styles.projectModalInput}
          />
          <div className={styles.mapStyle} id="map_">
            <MapComponent
              location={settingsData?.data?.metadata?.Location}
              setNewLocation={setNewLocation}
            />
          </div>
        </div>
        <button className={styles.saveBtn} onClick={HandleSave}>
          Save
        </button>
      </div>
    </div>
  );
};

export default ProjectModel;
