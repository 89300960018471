import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import proxy from "proxy";

// Initial States
const initialState = {
  isLoading: false,
  isError: false,
  alarmData: [],
  addAlarmMeassage: null,
  deleteAlarmMeassage: null,
  updateAlarmMesaage: null,
};

//Actions

// Get Alarm Action
export const fetchAlarmData = createAsyncThunk(
  "fetchAlarmData",
  async (data) => {
    const response = await fetch(
      `${proxy}/api/getAlarms?projectID=${data.Project_id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const responseBody = await response.json();
    return responseBody;
  }
);

// Add Alarm Action
export const addAlarm = createAsyncThunk("addAlarm", async (data) => {
  const response = await fetch(
    `${proxy}/api/addAlarm?projectID=${data.Project_id}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${data.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const responseBody = await response.json();
  return { responseBody, data };
});

// Delete Alarm Action
export const deleteAlarmAction = createAsyncThunk(
  "deleteAlarm",
  async (data) => {
    const response = await fetch(
      `${proxy}/api/deleteAlarm?projectID=${data.Project_id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const responseBody = await response.json();
    return { responseBody, data };
  }
);

// Update Alarm

export const updateAlarm = createAsyncThunk("updateAlarm", async (data) => {
  const { token, newData } = data;
  const response = await fetch(`${proxy}/api/editAlarm`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      Project_id: newData.Project_id,
      alarmId: newData.alarmId,
      alarmName: newData.alarmName,
      channel: newData.channel,
      value: newData.value,
      medium: newData.medium,
      operation: newData.operation,   
      triggerType: newData.triggerType,
      triggerValue: newData.triggerValue,
      scope: "visionWeb.alarm.update",
    }),
  });
  const responseBody = await response.json();
  return { responseBody, newData };
});

export const setTrigger = createAsyncThunk("setTrigger", async(data) => {
  const response = await fetch(
    `${proxy}/api/setTrigger`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${data.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const responseBody = await response.json();
  return { responseBody, data };
})

export const alarmSlice = createSlice({
  name: "alarmData",
  initialState,
  extraReducers: (builder) => {
    // Get Alarm Data
    builder.addCase(fetchAlarmData.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(fetchAlarmData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.alarmData = action.payload.alertList;
    });
    builder.addCase(fetchAlarmData.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });

    //Add alarm
    builder.addCase(addAlarm.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(addAlarm.fulfilled, (state, action) => {
      const data = {
        Project_id: action.payload.data.Project_id,
        alarmId: action.payload.data.Project_id,
        alarmName: action.payload.data.alarmName,
        channel: action.payload.data.channel,
        value: action.payload.data.value,
        medium: action.payload.data.medium,
        operation: action.payload.data.operation,
        triggerValue : action.payload.data.triggerValue,
        triggerType : action.payload.data.triggerType
      };
      state.isError = false;
      if (action.payload.responseBody.status === 200) {
        if(state.alarmData !== undefined){
          state.alarmData.push(data);
        } else {
          state.alarmData = [data]
        }
      }
      state.isLoading = false;
      state.addAlarmMeassage = action.payload.responseBody;
    });
    builder.addCase(addAlarm.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.addAlarmMeassage = action.payload.responseBody;
    });

    //Delete Alarm
    builder.addCase(deleteAlarmAction.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(deleteAlarmAction.fulfilled, (state, action) => {
      state.isError = false;
      if (action.payload.responseBody.status === 200) {
     
        state.alarmData = state.alarmData.filter(
          (alarm) => alarm.alarmId !== action.payload.data.alarmId
        );
        state.isError = true;
      } else {
        state.isError = false;
      }
      state.isLoading = false;
      state.deleteAlarmMeassage = action.payload.responseBody;
    });
    builder.addCase(deleteAlarmAction.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.deleteAlarmMeassage = action.payload.responseBody;
    });

    // Update Alarm
    builder.addCase(updateAlarm.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });

    builder.addCase(updateAlarm.fulfilled, (state, action) => {
      const updatedAlarmIndex = state.alarmData.findIndex(
        (alarm) => alarm.alarmId === action.payload.newData.alarmId
      );
      if (action.payload.responseBody.status === 200) {
        if (updatedAlarmIndex !== -1) {
          state.alarmData[updatedAlarmIndex] = action.payload.newData;
        }
      }
    });

    builder.addCase(updateAlarm.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      // state.updateAlarmMesaage = action.payload.responseBody;
    });

    // set Trigger Alarm
    builder.addCase(setTrigger.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });

    builder.addCase(setTrigger.fulfilled, (state, action) => {
      console.log(action.payload);
      const updatedAlarmIndex = state.alarmData.findIndex(
        (alarm) => alarm.alarmId === action.payload.data.alarmId
      );
      if (action.payload.responseBody.status === 200) {
        if (updatedAlarmIndex !== -1) {
          state.alarmData[updatedAlarmIndex].triggerValue = action.payload.data.value;
        }
      }
    });

    builder.addCase(setTrigger.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      // state.updateAlarmMesaage = action.payload.responseBody;
    });
  },
});

export default alarmSlice.reducer;
