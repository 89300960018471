import React, { useState } from "react";
import styles from "./NotificationCard.module.css";
import Notification from "../../../assets/Notifications.svg";

function NotificationCard() {
  const [checkedItems, setCheckedItems] = useState({});

  const handleCheckboxChange = (event, index) => {
    const { name, checked } = event.target;
    setCheckedItems({
      ...checkedItems,
      [index]: checked,
    });
  };

  return (
    <>
      <div className={styles.wholeNotification}>
        <div className={styles.notification}>
          <div className={styles.notificationRow}>
            <input
              type="checkbox"
              className={styles.checkbox}
              onChange={(event) => handleCheckboxChange(event, 0)}
              checked={checkedItems[0]}
            />
            <input type="text" className={styles.message} />
            <img src={Notification} alt="" className={styles.notificationImg} />
          </div>
          <div className={styles.notificationRow}>
            <input
              type="checkbox"
              className={styles.checkbox}
              onChange={(event) => handleCheckboxChange(event, 1)}
              checked={checkedItems[1]}
            />
            <input type="text" className={styles.message} />
            <img src={Notification} alt="" className={styles.notificationImg} />
          </div>
          <div className={styles.notificationRow}>
            <input
              type="checkbox"
              className={styles.checkbox}
              onChange={(event) => handleCheckboxChange(event, 2)}
              checked={checkedItems[2]}
            />
            <input type="text" className={styles.message} />
            <img src={Notification} alt="" className={styles.notificationImg} />
          </div>
          <div className={styles.notificationRow}>
            <input
              type="checkbox"
              className={styles.checkbox}
              onChange={(event) => handleCheckboxChange(event, 3)}
              checked={checkedItems[3]}
            />
            <input type="text" className={styles.message} />
            <img src={Notification} alt="" className={styles.notificationImg} />
          </div>
          <div className={styles.notificationRow}>
            <input
              type="checkbox"
              className={styles.checkbox}
              onChange={(event) => handleCheckboxChange(event, 4)}
              checked={checkedItems[4]}
            />
            <input type="text" className={styles.message} />
            <img src={Notification} alt="" className={styles.notificationImg} />
          </div>
          <div className={styles.notificationRow}>
            <input
              type="checkbox"
              className={styles.checkbox}
              onChange={(event) => handleCheckboxChange(event, 5)}
              checked={checkedItems[5]}
            />
            <input type="text" className={styles.message} />
            <img src={Notification} alt="" className={styles.notificationImg} />
          </div>
          <div className={styles.notificationRow}>
            <input
              type="checkbox"
              className={styles.checkbox}
              onChange={(event) => handleCheckboxChange(event, 6)}
              checked={checkedItems[6]}
            />
            <input type="text" className={styles.message} />
            <img src={Notification} alt="" className={styles.notificationImg} />
          </div>
          <div className={styles.notificationRow}>
            <input
              type="checkbox"
              className={styles.checkbox}
              onChange={(event) => handleCheckboxChange(event, 7)}
              checked={checkedItems[7]}
            />
            <input type="text" className={styles.message} />
            <img src={Notification} alt="" className={styles.notificationImg} />
          </div>
          <div className={styles.notificationRow}>
            <input
              type="checkbox"
              className={styles.checkbox}
              onChange={(event) => handleCheckboxChange(event, 8)}
              checked={checkedItems[8]}
            />
            <input type="text" className={styles.message} />
            <img src={Notification} alt="" className={styles.notificationImg} />
          </div>
          <div className={styles.notificationRow}>
            <input
              type="checkbox"
              className={styles.checkbox}
              onChange={(event) => handleCheckboxChange(event, 9)}
              checked={checkedItems[9]}
            />
            <input type="text" className={styles.message} />
            <img src={Notification} alt="" className={styles.notificationImg} />
          </div>
          <div className={styles.notificationRow}>
            <input
              type="checkbox"
              className={styles.checkbox}
              onChange={(event) => handleCheckboxChange(event, 10)}
              checked={checkedItems[10]}
            />
            <input type="text" className={styles.message} />
            <img src={Notification} alt="" className={styles.notificationImg} />
          </div>
        </div>
      </div>
    </>
  );
}
export default NotificationCard;
