import React from "react";
import styles from "./Livedata.module.css";
import { useState, useEffect } from "react";
import GraphData from "./GraphData";
import WorkerBuilder from "Utils/builder.worker";
import tableInitWorker from "Utils/restructure.worker";
import valueExtractorWorker from "Utils/valueExtractor.worker";
import Draggable from "react-draggable";
import { useSelector } from "react-redux";

const tableInitializer = new WorkerBuilder(tableInitWorker);
const valueExtractor = new WorkerBuilder(valueExtractorWorker);

const LiveData = ({
  data,
  socketData,
  tabIndexLiveDataGraph,
  setTabIndexLiveDataGraph,
  selectedTabIndex,
  openNewPage,
  handleLiveDataGraphDrop,
  handleLiveDataDimension,
  tempTabIndexLiveDataGraph,
  isMobile,
  scaleValue
}) => {
  const [dateTimesLive, setDateTimesLive] = useState([]); // DateTime labels for y-axis
  const [channelNames, setChannelNames] = useState([]); //  Channel names
  const [init, setInit] = useState(false);
  const [chartDataLive, setChartDataLive] = useState({
    labels: [],
    datasets: [],
  }); // data ready for display

  let graphCoordinates = { x: 0, y: 0 };

  const [dataArrLive, setDataArrLive] = useState([]); // Real-time data received for live
  const [tableData, setTableData] = useState([]); // Data formatted for Table

  const graphData = useSelector((state) => state.viewData.graphData);

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  useEffect(() => {
    // Parsing data from from get request in the parent component
    const fetchData = async () => {
      try {
        if (graphData.length > 0) {
          const copiedDataset = [...graphData];
          // Live Data Chart
          const currentTime = new Date();
          currentTime.setTime(currentTime.getTime() - 1 * 60 * 60 * 1000); // Subtracting 1 hour
          const liveDataFiltered = copiedDataset.filter(
            (dataset) => new Date(dataset.time) >= currentTime
          );

          // Restructure Data for Table
          const tableDataArrayGen = async () => {
            const dataPromise = new Promise((resolve) => {
              tableInitializer.onmessage = (e) => {
                resolve(e.data);
              };
            });
            tableInitializer.postMessage({ data: liveDataFiltered });
            return await dataPromise;
          };
          const tableDataArray = await tableDataArrayGen();
          let gotHeaders = [];
          if (tableDataArray.data) {
            gotHeaders = tableDataArray.headers;
            const arrayOfValueArrays = tableDataArray.channelWiseData;

            const getNewDataArr = () =>
              gotHeaders.map((header, index) => ({
                label: header,
                data: arrayOfValueArrays[index],
                borderColor: getRandomColor(),
                fill: false,
              }));

            const newDataArr = getNewDataArr();

            setChannelNames(gotHeaders);
            setTableData(tableDataArray.data);
            setDataArrLive(newDataArr);
            setDateTimesLive(tableDataArray.dateTimeArray);

            setInit(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [graphData]);

  useEffect(() => {
    try {
      const updateData = async () => {
        if (socketData && init) {
          const structuredData = async () => {
            const dataPromise = new Promise((resolve) => {
              valueExtractor.onmessage = (e) => {
                resolve(e.data);
              };
            });
            valueExtractor.postMessage({
              socketData: socketData,
              currentTable: tableData,
              dateTimeArray: dateTimesLive,
              dataArray: dataArrLive,
              headers: channelNames,
              duration: 1,
            });
            return await dataPromise;
          };

          const workerResponse = await structuredData();
          if (workerResponse.updatedTable) {
            setChannelNames(workerResponse.updatedHeaders);
            setTableData(workerResponse.updatedTable);
            setDataArrLive(workerResponse.updatedLiveArray);
            setDateTimesLive(workerResponse.updatedDateTimeArray);
          }
        }
      };
      updateData();
    } catch (error) {
      console.log(error);
    }
  }, [socketData]);

  useEffect(() => {
    setChartDataLive({
      labels: dateTimesLive.map((item) => {
        const date = new Date(item);
        if (isNaN(date)) {
          return 0;
        }
        return date.getTime();
      }),
      datasets: dataArrLive,
    });
  }, [dateTimesLive]);

  const generateLiveDataGraph = () => {
    const selectedTabDevices = tabIndexLiveDataGraph[selectedTabIndex];
    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices?.map((device, index) => {
        const { x, y, id, width, height } = device;

        let leftValue;
        let topValue;

        if (!openNewPage) {
          leftValue = x !== null ? x + "px" : "21%"
          topValue = y !== null ? y + "px" : "15%"
        } else {
          leftValue = "21%"
          topValue = "15%"
        }

        const deviceStyle = {
          position: "absolute",
          left: leftValue,
          top: topValue,
          width: width ? width : "60%",
          height: height ? height : "",
          resize: openNewPage ? "both" : "",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          scale: isMobile ? scaleValue.toString() : "1"
        };
        return (
          <>
            <div style={deviceStyle} id="liveDataGraph">
              <div className={styles.graph_container}>
                <GraphData viewData={chartDataLive} />
              </div>
            </div>
          </>
        );
      });
    } else {
      const deviceStyle = {
        position: "absolute",
        left: "21%",
        top: "15%",
        width: "60%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        resize: openNewPage ? "both" : "",
        scale: isMobile ? scaleValue.toString() : "1"
      };
      return (
        <>
          <div style={deviceStyle}>
            <div className={styles.graph_container}>
              <GraphData viewData={chartDataLive} />
            </div>
          </div>
        </>
      );
    }
  };


  const tempGenerateLiveDataGraph = () => {

    const selectedTabDevices = tempTabIndexLiveDataGraph[selectedTabIndex];

    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices?.map((device, index) => {
        
        const { x, y, id, width, height } = device;

        const deviceStyle = {
          position: "absolute",
          left: x !== null ? x + "px" : "21%",
          top: y !== null ? y + "px" : "15%",
          width: width ? width : "60%",
          height: height ? height : "",
          resize: openNewPage ? "both" : "",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          scale: isMobile ? scaleValue.toString() : "1"
        };

        return (
          <>
            <Draggable
              bounds="parent"
              onStop={(e, data) => handleLiveDataGraphDrop(e, data, id, x, y)}
            >
              <div
                style={deviceStyle}
                onContextMenu={(e) => handleLiveDataDimension(e, id)}
                id="liveDataGraph"
              >
                <div className={styles.graph_container}>
                  <GraphData viewData={chartDataLive} />
                </div>
              </div>
            </Draggable>
          </>
        );
      });
    } else {
      const deviceStyle = {
        position: "absolute",
        left: "21%",
        top: "15%",
        width: "60%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        resize: openNewPage ? "both" : "",
        scale: isMobile ? scaleValue.toString() : "1"
      };
      return (
        <>
          <Draggable bounds="parent">
            <div
              style={deviceStyle}
              onContextMenu={(e) => handleLiveDataDimension(e)}
              id="liveDataGraph"
            >
              <div className={styles.graph_container}>
                <GraphData viewData={chartDataLive} />
              </div>
            </div>
          </Draggable>
        </>
      );
    }
  };


  return (
    <>
      {
        openNewPage ? (
          tempGenerateLiveDataGraph()
        ) : (
          generateLiveDataGraph()
        )
      }
    </>
  )
};

export default LiveData;
