import React from "react";
import styles from "./ResetPass.module.css";
import dicotLogo from "assets/Logo.svg";
import ResetPassForm from "./ResetPassForm.jsx";

function ResetPass({ setPageTitle }) {
  React.useLayoutEffect(() => {
    document.title = "Reset Password";
  }, []);
  return (
    <div className={styles.mainDiv}>
      <div className={styles.mainLogo}>
        <img className={styles.logoImage} src={dicotLogo} alt="Dicot Logo" />
      </div>
      <ResetPassForm />
    </div>
  );
}

export default ResetPass;
