import React from "react";
import styles from "./Day1Table.module.css";
import {
  Table,
  Thead,
  Tbody,
  TabPanel,
  Tr,
  Th,
  Td,

  TableContainer,
} from "@chakra-ui/react";

const Day1Table = ({ channelNames, oneDayDataTs }) => {
  return (
        <TabPanel>
          <TableContainer
            className={styles.headerWrapper}
            overflowX="auto"
            overflowY="auto"
            maxHeight="58vh"
          >
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Timestamp</Th>
                  {channelNames.map((header, index) => (
                    <Th key={index}>{header}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {oneDayDataTs.map((column, columnIndex) => {
                  return (
                    <Tr key={columnIndex}>
                      {column.map((value, rowIndex) => {
                        return <Td key={rowIndex}>{value}</Td>;
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </TabPanel>
  );
};

export default Day1Table;
