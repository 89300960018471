import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import proxy from 'proxy';

const initialState = {
  channelData: [],
  isLoading: false,
  isError: false,
};

// Get Channel Action
export const fetchChannelData = createAsyncThunk(
  'fetchChannelData',
  async (data) => {
    const response = await fetch(`${proxy}/derivedChannel/getDerivedChannel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const status = response.status;
    const responseBody = await response.json();
    responseBody.status = status;
    return responseBody;
  },
);

// Add Channel Action
export const addChannel = createAsyncThunk('addChannel', async (data) => {
  const response = await fetch(`${proxy}/derivedChannel/addDerivedChannel`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const status = response.status;
  const responseMessage = await response.json();
  responseMessage.status = status;
  return responseMessage;
});

// Delete Channel Action
export const deleteChannel = createAsyncThunk('deleteChannel', async (data) => {
  const response = await fetch(`${proxy}/derivedChannel/deleteDerivedChannel`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const status = response.status;
  const responseBody = await response.json();
  data.status = status;
  return data;
});

// Update Channel
export const updateChannel = createAsyncThunk('updateChannel', async (data) => {
  const response = await fetch(`${proxy}/derivedChannel/updateDerivedChannel`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const status = response.status;
  const responseBody = await response.json();
  data.status = status;
  return data;
});

const derivedChannelSlice = createSlice({
  name: 'derivedChannel',
  initialState,
  extraReducers: (builder) => {
    // fetch channel
    builder.addCase(fetchChannelData.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(fetchChannelData.fulfilled, (state, action) => {
      state.channelData = action.payload.data;
      state.isLoading = false;
      state.isError = false;
    });
    builder.addCase(fetchChannelData.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });

    // Add Channel
    builder.addCase(addChannel.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(addChannel.fulfilled, (state, action) => {
      if (action.payload.status === 201) {
        if (state.channelData !== undefined) {
          state.channelData = [...state.channelData, action.payload.newChannel];
        } else {
          state.channelData = [action.payload.newChannel];
        }
      }
      state.isLoading = false;
      state.isError = false;
    });
    builder.addCase(addChannel.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });

    // Update Channel
    builder.addCase(updateChannel.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(updateChannel.fulfilled, (state, action) => {
      if (action.payload.status === 200) {
        const updateIndex = state.channelData.findIndex(
          (channel) => channel._id === action.payload._id,
        );
        state.channelData[updateIndex] = action.payload;
        state.isLoading = false;
        state.isError = false;
      }
    });
    builder.addCase(updateChannel.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });

    // Delete Channel
    builder.addCase(deleteChannel.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(deleteChannel.fulfilled, (state, action) => {
      const channelData = [...state.channelData];
      state.channelData = channelData.filter(
        (channel) => channel._id !== action.payload._id,
      );
      state.isLoading = false;
      state.isError = false;
    });
    builder.addCase(deleteChannel.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export default derivedChannelSlice.reducer;
