import React, { useEffect } from "react";
import styles from "./Titlebar.module.css";
import { ReactComponent as BurgerIcon } from "../assets/navigation.svg";
import { ReactComponent as VisionWebLogo } from "../assets/VisionWeb.svg";
import { useSelector } from "react-redux";

const TitleBar = ({isHovered, setIsHovered, toggleNav, setToggleNav, pageTitle }) => {

  const plansAboutToExpire = useSelector((state) => state.organization.plansAboutToExpire);

  return (
    <div>
      <div className={styles.titleContainer}>
        <div className={styles.logoSection}>
          <button
            className={styles.burgerIcon}
            onClick={() => {
              setToggleNav(!toggleNav);
              setIsHovered(!isHovered);
            }}
          >
            <BurgerIcon />
          </button>
          <div className={styles.logoContainer}>
            <VisionWebLogo className={styles.logo} />
          </div>
        </div>
        <div className={styles.titleTextWrapper}>

        <h1 className={styles.title}>{pageTitle}</h1>
        {plansAboutToExpire > 0 && <p className={styles.planExpireText}>{"Few of your plans will expire within 30 days."}</p>}
        </div>
      </div>
    </div>
  );
};

export default TitleBar;
