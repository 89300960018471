import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import favicon from './assets/favicon.ico';
import { Provider } from "react-redux";
import {store} from './redux/store.js'

const theme = extendTheme({
  colors: {
    brand: {
      50: "#1D3557",
    },
  },
});

const faviconLink = document.createElement("link");
faviconLink.rel = "icon";
faviconLink.href = favicon;

const rootElement = document.getElementById("root");

rootElement.style.display = 'flex';

document.head.appendChild(faviconLink);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
      <ChakraProvider theme={theme}>
      <Provider store={store}>

        <App />
      </Provider>
      </ChakraProvider>
  </BrowserRouter>
);
