import React, { useEffect } from "react";
import styles from "./CardCollection.module.css";
import CardData from "./CardData";
import { useSelector } from "react-redux";
import requets from "../../../assets/requests.svg";
import groups from "../../../assets/groups.svg";
import upload from "../../../assets/upload.svg"

const Cards = (props) => {
const billingInfo = useSelector(state => state.billing.billingInfo);


  var dt = 0;
  var unit = "";

  if (billingInfo?.DataAmount < 0.001) {
    dt = 1024 * parseFloat(billingInfo?.DataAmount);
    unit = "kb";
  } else {
    dt = billingInfo?.DataAmount;
    unit = "mb";
  }

  useEffect(() => {
    const percentageDaysLeft  = (billingInfo.daysLeft /billingInfo.totalDays ) * 100;
    let progressValue = document.getElementById("outer");
    progressValue.style.background = `conic-gradient(#FBE736 ${percentageDaysLeft * 3.6}deg, #fff 0deg)`

  },[billingInfo])

  



  return (
    <div className={styles.cardsContainer}>
      <CardData cardTitle="Requests" amount={billingInfo.Request} unit="reqs" icon = {requets} />
      <CardData cardTitle="Data Transfer" amount={dt?.toFixed(3)} unit={unit} icon = {upload}/>
      <CardData cardTitle="Users" amount={billingInfo.User} unit="members" icon = {groups} />
      <div className={styles.progressBarContainer}>

      <div className={styles.outer} id="outer">
        <div className={styles.inner}>
          <span className={styles.progressValue}>{billingInfo.daysLeft >= 0 ? billingInfo.daysLeft : 0 } Days <div>left</div></span>
        </div>
      </div>
      </div>
</div>
  );
};

export default Cards;
