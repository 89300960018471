import React from "react";
import styles from "./ManageMembers.module.css";
import { useState, useEffect } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import Loader from "components/Loader";
import proxy from "proxy";
import { useLocation } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import MemberIcons from "assets/MemberIcons.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  addRole,
  inviteMemeber,
  updateRolePermission,
} from "../../../../../../redux/slices/projectSetting.js";

import { getUsers } from "../../../../../../redux/slices/organizationSlice.js";

function ManageMembers({ setPage }, props) {
  const [currentRole, setCurrentRole] = useState(null);
  const location = useLocation();
  const toast = useToast();
  const searchParams = new URLSearchParams(location.search);
  const uniqueProjectId = searchParams.get("project");
  const [loading, setLoading] = useState(true);
  const [permissionEdit, setPermissionEdit] = useState(false);
  const [adminEmail, setAdminEmail] = useState("");
  const [roles, setRoles] = useState(null);
  const [selectedRole, setSelectedRole] = useState("");
  const [customRole, setCustomRole] = useState("");
  const [roleData, setRoleData] = useState(null);
  const [inviteEmail, setInviteEmail] = useState("");
  const [permissions, setPermissions] = useState(null);
  // const [memberList, setMemberList] = useState([]);
  const [roleInput, setRoleInput] = useState(false);
  const [memberModel, setMemberModel] = useState(false);
  const [members, setMembersList] = useState();
  const [newRole, setNewRole] = useState("");
  const [inviteIP, setInviteIp] = useState();
  const settingsData = useSelector((state) => state.projectSetting.settingData);
  const memberList = useSelector((state) => state.organization.orgMembers);
  const updatePermissionMessage = useSelector(
    (state) => state.projectSetting.updatePermissionMessage
  );
  const addRoleMessage = useSelector(
    (state) => state.projectSetting.addRoleMessage
  );

  const inviteMessage = useSelector(
    (state) => state.projectSetting.inviteMessage
  );
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  React.useEffect(() => {
    return () => {
      setPage("Manage Members");
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // role creation section toggle
  const [roleDropDown, setRoleDropDown] = useState(null);
  const customRoleSectionToggle = () => {
    if (roleDropDown === this) {
      return setRoleDropDown(null);
    }
    setRoleDropDown(this);
  };

  // invite members section toggle
  const [selected, setSelected] = useState(null);



  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleCreateRole = () => {
    setRoleInput(true);
  };

  useEffect(() => {
    if (Object.keys(settingsData).length > 0) {
      if (settingsData.data.admin === settingsData.currentEmail) {
        setCurrentRole("admin");
        setAdminEmail(settingsData.data.admin);
      }
     
      setRoles(settingsData.roles.map((data) => data.role));
      setRoleData(settingsData.roles);

      if (settingsData.roles.length > 0) {
        setSelectedRole(settingsData.roles[0].role);
      }
    }
  }, [settingsData]);

  useEffect(() => {
    if (roleData) {
      if (permissions) {
        const updatePermissionsState = (data) => {
          const updatedPermissions = { ...permissions }; // Create a copy of the current state

          // Loop through the received data and update the corresponding role in the updatedPermissions
          data.forEach((item) => {
            const roleKey =
              item.role.charAt(0).toUpperCase() + item.role.slice(1); // Convert role name to capitalize first letter
            const roleData = item.privilege;

            if (updatedPermissions[roleKey]) {
              // Update each permission for the role
              updatedPermissions[roleKey].alarmDelete =
                roleData.alarmDelete || false;
              updatedPermissions[roleKey].alarmEdit =
                roleData.alarmEdit || false;
              updatedPermissions[roleKey].alarmRead =
                roleData.alarmRead || false;
              updatedPermissions[roleKey].billingRead =
                roleData.billingRead || false;
              updatedPermissions[roleKey].projectRead =
                roleData.projectRead || false;
              updatedPermissions[roleKey].dangerZone =
                roleData.dangerZone || false;
              updatedPermissions[roleKey].editConnectionSetting =
                roleData.editConnectionSetting || false;
              updatedPermissions[roleKey].editDeviceSetting =
                roleData.editDeviceSetting || false;
              updatedPermissions[roleKey].exportAndDevices =
                roleData.exportAndDevices || false;
              updatedPermissions[roleKey].memberGet =
                roleData.memberGet || false;
              updatedPermissions[roleKey].informationRead =
                roleData.informationRead || false;
              updatedPermissions[roleKey].manipulateDevices =
                roleData.manipulateDevices || false;
              updatedPermissions[roleKey].permissionAdd =
                roleData.permissionAdd || false;
              updatedPermissions[roleKey].permissionUpdate =
                roleData.permissionUpdate || false;
              updatedPermissions[roleKey].settingRead =
                roleData.settingRead || false;
              updatedPermissions[roleKey].viewAndManipulate =
                roleData.viewAndManipulate || false;
              updatedPermissions[roleKey].feedbackWrite =
                roleData.feedbackWrite || false;
              updatedPermissions[roleKey].alarmAdd = roleData.alarmAdd || false;
              updatedPermissions[roleKey].projectDelete =
                roleData.projectDelete || false;
              updatedPermissions[roleKey].projectTransfer =
                roleData.projectTransfer || false;
              updatedPermissions[roleKey].projectSuspend =
                roleData.getData || false;
              updatedPermissions[roleKey].alarmSwitch =
                roleData.alarmSwitch || false;
              updatedPermissions[roleKey].projectUpdate =
                roleData.projectUpdate || false;
            }
          });

          // Update the state with the new permissions
          setPermissions(updatedPermissions);
        };
        updatePermissionsState(roleData);
      } else {
        const newPerms = roleData.map((roleItem) => ({
          [roleItem.role]: roleItem.privilege,
        }));
        const initialPermissions = {};
        newPerms.forEach((item) => {
          const roleKey = Object.keys(item)[0];
          const roleData = item[roleKey];

          initialPermissions[roleKey] = {
            alarmDelete: roleData.alarmDelete || false,
            alarmUpdate: roleData.alarmUpdate || false,
            alarmRead: roleData.alarmRead || false,
            billingRead: roleData.billingRead || false,
            projectRead: roleData.projectRead || false,
            dangerZone: roleData.dangerZone || false,
            editConnectionSetting: roleData.editConnectionSetting || false,
            editDeviceSetting: roleData.editDeviceSetting || false,
            exportAndDevices: roleData.exportAndDevices || false,
            informationRead: roleData.informationRead || false,
            manipulateDevices: roleData.manipulateDevices || false,
            memberGet: roleData.memberGet || false,
            permissionAdd: roleData.permissionAdd || false,
            permissionUpdate: roleData.permissionUpdate || false,
            settingRead: roleData.settingRead || false,
            viewAndManipulate: roleData.viewAndManipulate || false,
            feedbackWrite: roleData.feedbackWrite || false,
            alarmAdd: roleData.alarmAdd || false,
            projectDelete: roleData.projectDelete || false,
            projectTransfer: roleData.projectTransfer || false,
            projectSuspend: roleData.projectSuspend || false,
            getData: roleData.getData || false,
            alarmSwitch: roleData.alarmSwitch || false,
            projectUpdate: roleData.projectUpdate || false,
          };
        });
        setSelectedRole(roles[0]);
        setPermissions(initialPermissions);
        setLoading(false);
      }
    }
  }, [roleData]);

  useEffect(() => {
    if (permissionEdit) {
      const updatedPermissions = {
        role: selectedRole,
        Project_id: uniqueProjectId,
        permissions: permissions[selectedRole],
      };

      dispatch(
        updateRolePermission({
          token: token,
          updatedPermissions: updatedPermissions,
        })
      );
      setPermissionEdit(false);
    }
  }, [permissions]);

  useEffect(() => {
    if (updatePermissionMessage) {
      if (updatePermissionMessage.status === 200) {
        toast({
          title: "Role Modified",
          description: `${selectedRole} modified successfully`,
          status: "success",
          position: "top-right",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Role couldn't be modified",
          description: updatePermissionMessage.message,
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  }, [updatePermissionMessage]);

  const handlePermissionChange = (event) => {
    if (permissions !== null && permissions[selectedRole] && !loading) {
      setPermissionEdit(true);
      setPermissions({
        ...permissions,
        [selectedRole]: {
          ...permissions[selectedRole],
          [event.target.name]: event.target.checked,
        },
      });
    }
  };

  const handleAddCustomRole = () => {
    const customRoleValue = document.getElementById("customRole").value;
    setNewRole(customRoleValue);
    const newRoleData = {
      role: customRoleValue,
      Project_id: uniqueProjectId,
    };

    if (!roles.includes(customRoleValue)) {
      dispatch(addRole({ token: token, newRoleData: newRoleData }));
    } else {
      toast({
        title: "New role couldn't be added",
        description: "Role is already exists",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (!roles?.includes(newRole) && newRole.length > 0) {
      if (addRoleMessage) {
        if (addRoleMessage.status === 200) {
          toast({
            title: "New role added successfully",
            description: "Yey, You successfully created a new role",
            status: "success",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
          setRoles([...roles, newRole]);
          setPermissions({
            ...permissions,
            [newRole]: {
              alarmRead: false,
              alarmDelete: false,
              alarmUpdate: false,
              billingRead: false,
              projectRead: false,
              dangerZone: false,
              editConnectionSetting: false,
              editDeviceSetting: false,
              exportAndDevices: false,
              informationRead: false,
              manipulateDevices: false,
              memberGet: false,
              permissionAdd: false,
              permissionUpdate: false,
              settingRead: false,
              viewAndManipulate: false,
              feedbackWrite: false,
              alarmAdd: false,
              projectDelete: false,
              projectTransfer: false,
              projectSuspend: false,
              getData: false,
              alarmSwitch: false,
              projectUpdate: false,
            },
          });
          document.getElementById("customRole").value = "";
        } else {
          toast({
            title: "New role couldn't be added",
            description: addRoleMessage.message,
            status: "error",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    }
  }, [addRoleMessage, handleAddCustomRole]);

  const handleInputChange = (event) => {
    setInviteEmail(event.target.value);
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };



  return (
    <>
      {loading && currentRole !== "admin" ? (
        <Loader />
      ) : (
        <div className={styles.page_wrapper}>
          <div className={styles.hero}>
            <div className={styles.page_hero}>
              <div className={styles.roles}>
                <div className={styles.manage_wrapper}>
                  <div className={styles.headings}>
                    <h2 className={styles.headerText}>Role Permissions</h2>
                    <div className={styles.role_heading}>
                      <h3 className={styles.roleHeader}>Role: </h3>
                      <select
                        value={selectedRole}
                        className={styles.roleDropdown}
                        onChange={handleRoleChange}
                      >
                        <option value="" disabled>
                          Select a role
                        </option>

                        {roles?.map((role, roleIndex) => (
                          <option key={roleIndex} value={role}>
                            {role}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className={styles.roleWrapper}>
                    {selectedRole &&
                      permissions !== null &&
                      permissions[selectedRole] && (
                        <div className={styles.input}>
                          <div className={styles.permissions}>
                            Get Alarms
                            <div className={styles.radio}>
                              <input
                                type="checkbox"
                                name="alarmRead"
                                checked={permissions[selectedRole].alarmRead}
                                onChange={handlePermissionChange}
                                className={styles.radioInput}
                              ></input>
                            </div>
                          </div>
                          <div className={styles.permissions}>
                            Update Alarm
                            <div className={styles.radio}>
                              <input
                                type="checkbox"
                                name="alarmUpdate"
                                checked={permissions[selectedRole].alarmUpdate}
                                onChange={handlePermissionChange}
                                className={styles.radioInput}
                              ></input>
                            </div>
                          </div>
                          <div className={styles.permissions}>
                            Delete Alarm
                            <div className={styles.radio}>
                              <input
                                type="checkbox"
                                name="alarmDelete"
                                checked={permissions[selectedRole].alarmDelete}
                                onChange={handlePermissionChange}
                                className={styles.radioInput}
                              ></input>
                            </div>
                          </div>
                          <div className={styles.permissions}>
                            Billing Info
                            <div className={styles.radio}>
                              <input
                                type="checkbox"
                                name="billingRead"
                                checked={permissions[selectedRole].billingRead}
                                onChange={handlePermissionChange}
                                className={styles.radioInput}
                              ></input>
                            </div>
                          </div>
                          <div className={styles.permissions}>
                            Project Data
                            <div className={styles.radio}>
                              <input
                                type="checkbox"
                                name="projectRead"
                                checked={permissions[selectedRole].projectRead}
                                className={styles.radioInput}
                                onChange={handlePermissionChange}
                              ></input>
                            </div>
                          </div>
                          <div className={styles.permissions}>
                            dangerZone
                            <div className={styles.radio}>
                              <input
                                type="checkbox"
                                name="dangerZone"
                                checked={permissions[selectedRole].dangerZone}
                                className={styles.radioInput}
                                onChange={handlePermissionChange}
                              ></input>
                            </div>
                          </div>
                          <div className={styles.permissions}>
                            editConnectionSetting
                            <div className={styles.radio}>
                              <input
                                type="checkbox"
                                name="editConnectionSetting"
                                checked={
                                  permissions[selectedRole]
                                    .editConnectionSetting
                                }
                                className={styles.radioInput}
                                onChange={handlePermissionChange}
                              ></input>
                            </div>
                          </div>
                          <div className={styles.permissions}>
                            editDeviceSetting
                            <div className={styles.radio}>
                              <input
                                type="checkbox"
                                name="editDeviceSetting"
                                checked={
                                  permissions[selectedRole].editDeviceSetting
                                }
                                className={styles.radioInput}
                                onChange={handlePermissionChange}
                              ></input>
                            </div>
                          </div>
                          <div className={styles.permissions}>
                            exportAndDevices
                            <div className={styles.radio}>
                              <input
                                type="checkbox"
                                name="exportAndDevices"
                                checked={
                                  permissions[selectedRole].exportAndDevices
                                }
                                className={styles.radioInput}
                                onChange={handlePermissionChange}
                              ></input>
                            </div>
                          </div>
                          <div className={styles.permissions}>
                            informationRead
                            <div className={styles.radio}>
                              <input
                                type="checkbox"
                                name="informationRead"
                                checked={
                                  permissions[selectedRole].informationRead
                                }
                                className={styles.radioInput}
                                onChange={handlePermissionChange}
                              ></input>
                            </div>
                          </div>
                          <div className={styles.permissions}>
                            manipulateDevices
                            <div className={styles.radio}>
                              <input
                                type="checkbox"
                                name="manipulateDevices"
                                checked={
                                  permissions[selectedRole].manipulateDevices
                                }
                                className={styles.radioInput}
                                onChange={handlePermissionChange}
                              ></input>
                            </div>
                          </div>
                          <div className={styles.permissions}>
                            memberGet
                            <div className={styles.radio}>
                              <input
                                type="checkbox"
                                name="memberGet"
                                checked={permissions[selectedRole].memberGet}
                                className={styles.radioInput}
                                onChange={handlePermissionChange}
                              ></input>
                            </div>
                          </div>
                          <div className={styles.permissions}>
                            permissionAdd
                            <div className={styles.radio}>
                              <input
                                type="checkbox"
                                name="permissionAdd"
                                checked={
                                  permissions[selectedRole].permissionAdd
                                }
                                className={styles.radioInput}
                                onChange={handlePermissionChange}
                              ></input>
                            </div>
                          </div>
                          <div className={styles.permissions}>
                            permissionUpdate
                            <div className={styles.radio}>
                              <input
                                type="checkbox"
                                name="permissionUpdate"
                                checked={
                                  permissions[selectedRole].permissionUpdate
                                }
                                className={styles.radioInput}
                                onChange={handlePermissionChange}
                              ></input>
                            </div>
                          </div>
                          <div className={styles.permissions}>
                            feedbackWrite
                            <div className={styles.radio}>
                              <input
                                type="checkbox"
                                name="feedbackWrite"
                                checked={
                                  permissions[selectedRole].feedbackWrite
                                }
                                className={styles.radioInput}
                                onChange={handlePermissionChange}
                              ></input>
                            </div>
                          </div>
                          <div className={styles.permissions}>
                            alarmAdd
                            <div className={styles.radio}>
                              <input
                                type="checkbox"
                                name="alarmAdd"
                                checked={permissions[selectedRole].alarmAdd}
                                className={styles.radioInput}
                                onChange={handlePermissionChange}
                              ></input>
                            </div>
                          </div>
                          <div className={styles.permissions}>
                            projectDelete
                            <div className={styles.radio}>
                              <input
                                type="checkbox"
                                name="projectDelete"
                                checked={
                                  permissions[selectedRole].projectDelete
                                }
                                className={styles.radioInput}
                                onChange={handlePermissionChange}
                              ></input>
                            </div>
                          </div>
                          <div className={styles.permissions}>
                            projectTransfer
                            <div className={styles.radio}>
                              <input
                                type="checkbox"
                                name="projectTransfer"
                                checked={
                                  permissions[selectedRole].projectTransfer
                                }
                                className={styles.radioInput}
                                onChange={handlePermissionChange}
                              ></input>
                            </div>
                          </div>
                          <div className={styles.permissions}>
                            projectSuspend
                            <div className={styles.radio}>
                              <input
                                type="checkbox"
                                name="projectSuspend"
                                checked={
                                  permissions[selectedRole].projectSuspend
                                }
                                className={styles.radioInput}
                                onChange={handlePermissionChange}
                              ></input>
                            </div>
                          </div>
                          <div className={styles.permissions}>
                            settingRead
                            <div className={styles.radio}>
                              <input
                                type="checkbox"
                                name="settingRead"
                                checked={permissions[selectedRole].settingRead}
                                className={styles.radioInput}
                                onChange={handlePermissionChange}
                              ></input>
                            </div>
                          </div>
                          <div className={styles.permissions}>
                            viewAndManipulate
                            <div className={styles.radio}>
                              <input
                                type="checkbox"
                                name="viewAndManipulate"
                                checked={
                                  permissions[selectedRole].viewAndManipulate
                                }
                                className={styles.radioInput}
                                onChange={handlePermissionChange}
                              ></input>
                            </div>
                          </div>
                          <div className={styles.permissions}>
                            Get Data
                            <div className={styles.radio}>
                              <input
                                type="checkbox"
                                name="getData"
                                checked={permissions[selectedRole].getData}
                                className={styles.radioInput}
                                onChange={handlePermissionChange}
                              ></input>
                            </div>
                          </div>
                          <div className={styles.permissions}>
                            Alarm Switch
                            <div className={styles.radio}>
                              <input
                                type="checkbox"
                                name="alarmSwitch"
                                checked={permissions[selectedRole].alarmSwitch}
                                className={styles.radioInput}
                                onChange={handlePermissionChange}
                              ></input>
                            </div>
                          </div>
                          <div className={styles.permissions}>
                            projectUpdate
                            <div className={styles.radio}>
                              <input
                                type="checkbox"
                                name="projectUpdate"
                                checked={
                                  permissions[selectedRole].projectUpdate
                                }
                                className={styles.radioInput}
                                onChange={handlePermissionChange}
                              ></input>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className={styles.newMember}>
                <div className={styles.btnWrapper}>
                  <button
                    className={styles.discard}
                    onClick={() => setRoleInput(false)}
                  >
                    Discard
                  </button>
                  <button className={styles.create} onClick={handleCreateRole}>
                    Create
                  </button>
                </div>
                {roleInput && (
                  <div className={styles.newRole}>
                    <p className={styles.rolePara}>
                      Enter the name of the role you want to create{" "}
                    </p>
                    <div className={styles.invite_inputs}>
                      <input
                        type="text"
                        placeholder="Role Name"
                        id="customRole"
                        className={styles.createInput}
                      />
                    </div>
                    <button
                      className={styles.createRole}
                      onClick={handleAddCustomRole}
                    >
                      Create Role
                    </button>
                  </div>
                )}
              </div>

              
              
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ManageMembers;
