import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

const Histogram = ({
  openNewPage,
  dateTimesLive,
  dataArrLive,
  getRandomColor,
  chartChannel,
  selectedTabIndex,
  hour,
  minute,
  oneDayDataTs
}) => {
  const [chartOptions, setChartOptions] = useState([]);
  const [histogramData, setHistogramData] = useState([]);
  ChartJS.register(ArcElement, Tooltip, Legend);

  useEffect(() => {
    const timeArr = dateTimesLive.map((item) => {
      const date = new Date(item);
      return isNaN(date) ? 0 : date.getTime();
    });

    const currentTime = new Date();

    const endTime = new Date(currentTime);
    endTime.setHours(currentTime.getHours() - hour);
    endTime.setMinutes(currentTime.getMinutes() - minute);
    
    let newEndTime;

    if(isNaN(endTime.getTime())){
      newEndTime = new Date();
    }
    else{
      newEndTime = endTime;
    }
    const formattedCurrentTime = currentTime.toISOString();
    const formattedEndTime =  newEndTime.toISOString();

    const filteredTimeArr = oneDayDataTs.filter(time => {
      return time >= formattedEndTime && time <= formattedCurrentTime;
  });

    let channelsLastValues = [];

    const availableChannels = dataArrLive.filter(
      (data, index) => data.data.slice(-1)[0] !== null
    );


    const matchedDatasets =
      chartChannel?.length > 0
        ? chartChannel?.map((label) =>
        availableChannels?.find((dataset) => dataset.label === label)
          )
        : availableChannels;

    if (matchedDatasets[0] !== undefined) {
      matchedDatasets?.map((data) =>
        channelsLastValues.push({
          label: data.label,
          data: data.data,
          backgroundColor: getRandomColor(),
        })
      );
    }

    const newChartData = {
      labels: filteredTimeArr.length > 0 ?filteredTimeArr : timeArr.slice(-20),
      datasets: channelsLastValues,
    };

    const options = {
      scales: {
        x: {
          type: "time",
        },
        y: {
          beginAtZero: true,
        },
      },
      plugins: {
        decimation: {
          enabled: true,
          algorithm: "lttb",
          threshold: 200,
        },
        zoom: {
          pan: {
            enabled: true,
            mode: "y",
            modifierKey: "ctrl",
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },

            mode: "y",
          },
        },
        legend: {
          align: "left", // Set the alignment to 'left'
          labels: {
            boxWidth: 5, // Adjust the width of the legend color box
            font: {
              size: 10, // Set the font size
            },
          },
        },
      },
    };
    setChartOptions(options);
    setHistogramData(newChartData);
  }, [dateTimesLive, selectedTabIndex, chartChannel,oneDayDataTs, openNewPage, hour, minute]);

  return (
    <div>
      {histogramData && histogramData.labels ? (
        <Bar data={histogramData} options={chartOptions} />
      ) : (
        <p>Loading chart data...</p>
      )}
    </div>
  );
};

const NewHistogram = ({
  dateTimesLive,
  dataArrLive,
  getRandomColor,
  chartChannel,
  selectedTabIndex,
  endHours,
  endMinutes,
  oneDayDataTs
}) => {
  const [newChartData, setNewChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState([]);

  ChartJS.register(ArcElement, Tooltip, Legend);
  useEffect(() => {
    const timeArr = dateTimesLive.map((item) => {
      const date = new Date(item);
      return isNaN(date) ? 0 : date.getTime();
    });
  
    const currentTime = new Date();

    const endTime = new Date(currentTime);
    endTime.setHours(currentTime.getHours() - endHours);
    endTime.setMinutes(currentTime.getMinutes() - endMinutes);
    
    let newEndTime;

    if(isNaN(endTime.getTime())){
      newEndTime = new Date();
    }
    else{
      newEndTime = endTime;
    }
    const formattedCurrentTime = currentTime.toISOString();
    const formattedEndTime =  newEndTime.toISOString();

    const filteredTimeArr = oneDayDataTs.filter(time => {
      return time >= formattedEndTime && time <= formattedCurrentTime;
  });

    let channelsLastValues = [];

    const availableChannels = dataArrLive.filter(
      (data, index) => data.data.slice(-1)[0] !== null
    );


    if (chartChannel !== undefined) {
      const arrData = chartChannel.map((header, index) =>
      availableChannels.find((label) => header === label.label)
      );

      arrData.map((data) => 
        channelsLastValues.push({
          label : data.label,
          data : data.data,
          backgroundColor : getRandomColor(),
        })
      )
    } else {
      availableChannels.map((data) =>
        channelsLastValues.push({
          label: data.label,
          data: data.data,
          backgroundColor: getRandomColor(),
        })
      );
    }

    const newChartData = {
      labels: filteredTimeArr.length > 0 ? filteredTimeArr : timeArr.slice(-25),
      datasets: channelsLastValues,
    };

    const options = {
      scales: {
        x: {
          type: "time",
        },
        y: {
          beginAtZero: true,
        },
      },
      animation: false,
      plugins: {
        decimation: {
          enabled: true,
          algorithm: "lttb",
          threshold: 200,
        },
        zoom: {
          pan: {
            enabled: true,
            mode: "y",
            modifierKey: "ctrl",
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },

            mode: "y",
          },
        },
        legend: {
          align: "left", // Set the alignment to 'left'
          labels: {
            boxWidth: 5, // Adjust the width of the legend color box
            font: {
              size: 10, // Set the font size
            },
          },
        },
      },
    };
    setChartOptions(options);
    setNewChartData(newChartData);
  }, [dateTimesLive, selectedTabIndex, chartChannel, endHours, endMinutes]);

  return (
    <div>
      {newChartData && newChartData.labels ? (
        <Bar data={newChartData} options={chartOptions} />
      ) : (
        <p>Loading chart data...</p>
      )}
    </div>
  );
};

export { Histogram, NewHistogram };
