  import React from "react";
  import { useNavigate } from "react-router-dom";
  import styles from "./Styles/navMenuItem.module.css";

  const NavMenuItem = (props) => {
    const navigate = useNavigate();
    return (
      <button
        onClick={(e) => {
          navigate(props.goto);
          props.setSliderBarLocation(e.clientY);
          // console.log(e.clientY);
        }}
        className={`${props.iconOnly ? styles.item : styles.hiddenText} ${
          props.current ? styles.currentOption : ""
        }`}
        style={{ backgroundColor: props.backgroundColor }}
      >
        <div
          className={`${props.current ? styles.iconCurrent : ""} ${
            styles.itemIcon
          }`}
        >
          {props.icon}
        </div>
        {props.iconOnly ? (
          <div className={styles.itemText}>{props.text}</div>
        ) : (
          <></>
        )}
      </button>
    );
  };

  export default NavMenuItem;
