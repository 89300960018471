import { mappls } from "mappls-web-maps";
import { useEffect } from "react";

function MapComponent({ location, setLong, setLat }) {
  useEffect(() => {
    try {
      if (location) {
        var current;
        var currentLocationString;

        try {
          currentLocationString = location.trim().split(",");
        }
        catch {
          current = [23.0225, 72.5714];
        }

        if (currentLocationString.length < 2) {
          current = [23.0225, 72.5714];
        }
        else if (isNaN(currentLocationString[0]) || isNaN(currentLocationString[1])) {
          current = [23.0225, 72.5714];
        }
        else {
          current = currentLocationString.map(Number);
        }

        // Map Properties
        const mapProps = {
          center: current,
          zoomControl: true,
          zoom: 7,
        };

        // New Map Variable
        var mapplsClassObject = new mappls();
        // Map marker
        var marker;
        setLat(current[0]);
        setLong(current[1]);

        // Checking if the object exists
        if (mapplsClassObject) {
          // Inintializing with API key
          mapplsClassObject.initialize(
            "4b5d031b33d1a6ecb0ddbc322dc81ab4",
            () => {
              const map = mapplsClassObject.Map({
                id: "map_",
                properties: mapProps,
              });

              if (map) {
                marker = mapplsClassObject.Marker({
                  map: map,
                  position: current,
                });
              }
            }
          );
        }
      }
    } catch (e) {
      console.log("Something bad happened while loading map: " + e);
    }
  }, [location]);
  
  return <div></div>;
}

export default MapComponent;