import React, { useEffect, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import proxy from "proxy";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import styles from "./Transfer.module.css";
import { transferProject } from "../../../../../../../redux/slices/dangerZoneSlice.js";
import { useDispatch, useSelector } from "react-redux";

function Transfer() {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [email, setEmail] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uniqueProjectId = searchParams.get("project");
  const toast = useToast();
  const dispatch = useDispatch();
  const transferMessage = useSelector(
    (state) => state.dangerZone.transferMessage
  );
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const token = localStorage.getItem("token");
  const userPermission = useSelector(state => state.organization.userPermission);


  const HandleTransfer = () => {
    const transferData = {
      email : email,  
      token: token,
      projectId: uniqueProjectId,
    };

    dispatch(transferProject(transferData)).then((response) => {
      if(response.payload.status === 200) {
        toast({
          title: `Check ${response.payload.email}`,
          description: `project confirmation link has been sent to the email address, check ${response.payload.email} to confirm`,
          status: "info",
          position: "top-right",
          duration: 7000,
          isClosable: true,
        });
        navigate("/");
      }
    })
  };

 

  return (
    <>
      <div className={styles.transferProject}>
        <label htmlFor="">Transfer Project</label>
        {userPermission.transferProject && (
        <button onClick={onOpen} className={styles.transferBtn}>
          Transfer
        </button>
        )}
      </div>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Transfer Project
            </AlertDialogHeader>

            <AlertDialogBody>
              Enter the Email Address to transfer the project?
              <input
                type="email"
                className="transfer-email"
                placeholder="Enter Email Address"
                onChange={handleEmailChange}
              />
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                className="cancel-button"
                ref={cancelRef}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                className="confirm-button"
                onClick={HandleTransfer}
                ml={3}
              >
                Transfer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default Transfer;
