import React, { useState } from "react";
import styles from "./Toggle.module.css";
// import proxy from "../../../proxy";

function NotificationToggle({ setIsToggle, isToggle, handleToggle }) {
  return (
    <div
      className={`${styles.toggleBox} ${isToggle ? styles.active : ""}`}
      onClick={handleToggle}
    >
      <span
        className={`${styles.toggle} ${isToggle ? styles.active : ""}`}
        onClick={handleToggle}
      ></span>
    </div>
  );
}

export default NotificationToggle;
