import React, { useState, useEffect } from 'react';
import styles from './DerivedChannels.module.css';
import AddChannelModal from './AddChannelModal';
import { AiOutlinePlus } from 'react-icons/ai';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import tableInitWorker from '../../../../../../Utils/restructure.worker';
import WorkerBuilder from '../../../../../../Utils/builder.worker';
import valueExtractorWorker from '../../../../../../Utils/valueExtractor.worker';
import {
  fetchChannelData,
  deleteChannel,
} from '../../../../../../redux/slices/derivedChannelSlice';
import portlessProxy from 'bareproxy';

import proxy from 'proxy';
import { useToast } from '@chakra-ui/react';

const io = require('socket.io-client');
const tableInitializer = new WorkerBuilder(tableInitWorker);
const valueExtractor = new WorkerBuilder(valueExtractorWorker);

const Derivedchannels = () => {
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(false);
  const [channels, setChannels] = useState([]);
  const [editingChannelIndex, setEditingChannelIndex] = useState(null);
  const [value, setValue] = useState('');
  const [expression, setExpression] = useState('');
  const [data, setData] = useState([]);
  const [channelNames, setChannelNames] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [dateTimesLive, setDateTimesLive] = useState([]);
  const [dataArrLive, setDataArrLive] = useState([]);
  const [isWorkerBusy, setIsWorkerBusy] = useState(false);
  const [socketData, setSocketData] = useState(null);
  const [init, setInit] = useState(false);
  const [searchParams] = useSearchParams();
  const [selectedCardId, setSelectedCardId] = useState('');
  const [selectedCardName, setSelectedCardName] = useState('');
  const [selectedCardValue, setSelectedCardValue] = useState('');
  const uniqueProjectId = searchParams.get('project');
  const [socket,setSocket]=useState(io(portlessProxy, {
    cors: {
      origin: '*',
      allowedHeaders: ['*'],
      allowedMethods: ['GET', 'POST'],
      insecure: true,
    },
  }))
  
  const channelData = useSelector((state) => state.derivedChannel.channelData);

  const dispatch = useDispatch();
  const email = localStorage.getItem('email');

  useEffect(() => {
    const data = {
      email: email,
      projectId: uniqueProjectId,
    };
   
    socket.on('connect',()=>{
      toast({
        title: `Successfully with Server`,
        status: 'success',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
    })
    socket.emit('joinRoom', `${uniqueProjectId}`);
    socket.on('message',(data)=>{
      const newData=JSON.parse(data)
      newData.projectId=uniqueProjectId;
      socket.emit("clientMessage",newData)
    })

    socket.on('liveCalutationData', (data) => {
      setSocketData(data);
    });
    dispatch(fetchChannelData(data));
  }, []);

  useEffect(() => {
    // Parsing data from from get request in the parent component
    const copiedDataset = data;
    const fetchData = async () => {
      try {
        if (data.length > 0) {
          // Live Data Chart
          const currentTime = new Date();
          currentTime.setTime(currentTime.getTime() - 1 * 60 * 60 * 1000); // Subtracting 1 hour
          const liveDataFiltered = copiedDataset.filter(
            (dataset) => new Date(dataset.time) >= currentTime,
          );

          // Restructure Data for Table
          const tableDataArrayGen = async () => {
            const dataPromise = new Promise((resolve) => {
              tableInitializer.onmessage = (e) => {
                resolve(e.data);
              };
            });
            tableInitializer.postMessage({ data: liveDataFiltered });
            return await dataPromise;
          };
          const tableDataArray = await tableDataArrayGen();
          let gotHeaders = [];
          if (tableDataArray.data) {
            gotHeaders = tableDataArray.headers;
            const arrayOfValueArrays = tableDataArray.channelWiseData;

            const getNewDataArr = () =>
              gotHeaders.map((header, index) => ({
                label: header,
                data: arrayOfValueArrays[index],
                fill: false,
              }));

            const newDataArr = getNewDataArr();
            setChannelNames(gotHeaders);
            setTableData(tableDataArray.data);
            setDataArrLive(newDataArr);
            setDateTimesLive(tableDataArray.dateTimeArray);
            setInit(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(
          `${proxy}/db/get-data?token=${token}&projectID=${uniqueProjectId}`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              Project_id: uniqueProjectId,
            }),
          },
        );
        const responseBody = await response.json();
        setData(responseBody);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    try {
      const updateData = async () => {
        if (socketData && init && !isWorkerBusy) {
          setIsWorkerBusy(true);
          const structuredData = async () => {
            const dataPromise = new Promise((resolve) => {
              valueExtractor.onmessage = (e) => {
                resolve(e.data);
              };
            });
            valueExtractor.postMessage({
              socketData: socketData,
              currentTable: tableData,
              dateTimeArray: dateTimesLive,
              dataArray: dataArrLive,
              headers: channelNames,
              duration: 1,
            });
            return await dataPromise;
          };

          const workerResponse = await structuredData();
          if (workerResponse.updatedTable) {
            setChannelNames(workerResponse.updatedHeaders);
            setTableData(workerResponse.updatedTable);
            setDataArrLive(workerResponse.updatedLiveArray);
            setDateTimesLive(workerResponse.updatedDateTimeArray);
          }
        }
      };
      updateData();
    } catch (error) {
      console.log(error);
    }
    setIsWorkerBusy(false);
  }, [socketData]);

  const handleEditModal = (index) => {
    setIsModalOpen(true);
    setModalType('Edit');
    setEditingChannelIndex(index);
  };

  const handleAddModal = () => {
    setIsModalOpen(true);
    setModalType('Add');
    setEditingChannelIndex(null);
  };


  const handleSaveChannel = (newChannel) => {
    if (editingChannelIndex !== null) {
      const updatedChannels = [...channels];
      updatedChannels[editingChannelIndex] = newChannel;
      setChannels(updatedChannels);
    } else {
      setChannels([...channels, newChannel]);
    }

    setIsModalOpen(false);
  };

  const handleDeleteChannel = (id) => {
    const data = {
      email: email,
      projectId: uniqueProjectId,
      _id: id,
    };

    dispatch(deleteChannel(data)).then((response) => {
      if (response.payload.status === 200) {
        toast({
          title: `Derived channel deleted successfully`,
          status: 'success',
          position: 'top-right',
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: `Channel has not been deleted.`,
          status: 'error',
          position: 'top-right',
          duration: 3000,
          isClosable: true,
        });
      }
    });
  };

  const calculateValue = (expr) => {
    try {
      const result = eval(expr);
      setValue(result);
    } catch (error) {
      setValue('Error');
    }
  };

  const handleExpressionChange = (e) => {
    const newExpression = e.target.value;
    setExpression(newExpression);
    calculateValue(newExpression);
  };

  const editChannel = (index, id, channelName, channelValue) => {
    handleEditModal(index);
    setSelectedCardId(id);
    setSelectedCardName(channelName);
    setSelectedCardValue(channelValue);
  };

  useEffect(() => {
  
  },[])

  return (
    <div className={styles.container}>
      <div className={styles.cards}>
        {channelData?.map((channel, index) => (
          <div className={styles.card} key={index}>
            <div className={styles.firstHalf}>
              <div className={styles.name}>{channel.channelName}</div>
              <div className={styles.value}>
                value:
                <span className={styles.number}>{socketData && socketData[channel?._id] || channel?.channelValue}</span>
              </div>
            </div>
            <div className={styles.secondHalf}>
              <button
                className={styles.delete}
                onClick={() => handleDeleteChannel(channel._id)}
              >
                Delete
              </button>
              <button
                className={styles.edit}
                onClick={() =>
                  editChannel(
                    index,
                    channel._id,
                    channel.channelName,
                    channel.channelValue,
                  )
                }
              >
                Edit
              </button>
            </div>
          </div>
        ))}
      </div>
      <button className={styles.addChannel} onClick={handleAddModal}>
        <AiOutlinePlus />
        Add Channel
      </button>
      {isModalOpen && (
        <AddChannelModal
          setIsModalOpen={setIsModalOpen}
          modalType={modalType}
          onSave={handleSaveChannel}
          editingChannel={
            editingChannelIndex !== null ? channels[editingChannelIndex] : null
          }
          expression={expression}
          setExpression={setExpression}
          calculateValue={calculateValue}
          channelNames={channelNames}
          dataArrLive={dataArrLive}
          value={value}
          email={email}
          uniqueProjectId={uniqueProjectId}
          selectedCardId={selectedCardId}
          selectedCardName={selectedCardName}
          selectedCardValue={selectedCardValue}
        />
      )}
    </div>
  );
};

export default Derivedchannels;
