import React, { useEffect, useState } from "react";
import styles from "./Alarm.module.css";
import AlarmCard from "./components/AlarmCard/AlarmCard";
import AlarmLogs from "./components/AlarmLogs/AlarmLogs";
import ModelAlarm from "./components/AlarmCard/ModelAlarm";
import { useLocation } from "react-router-dom";
import proxy from "proxy";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  addAlarm,
  fetchAlarmData,
  deleteAlarmAction,
  updateAlarm,
} from "../../../../redux/slices/alarmSlice.js";
import { useToast } from "@chakra-ui/react";

const Alarm = ({ setPage }) => {
  setPage("dashboard");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uniqueProjectId = searchParams.get("project");
  const [data, setData] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [selectedCard, setSelectedCard] = useState();
  const [titleText, setTitleText] = useState("");
  const [newAlarm, setNewAlarm] = useState();
  const [alarmTab, setAlarmtab] = useState(true);
  const [logsTab, setLogsTab] = useState(false);
  const [inputValue, setInputValue] = useState();
  const [botToken, setBotToken] = useState(null);
  const [chatId, setChatId] = useState(null);

  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const alarmData = useSelector((state) => state.alarmData.alarmData);
  const userPermission = useSelector(state => state.organization.userPermission);
  const toast = useToast();
  const [selectedTriggerType, setSelectedTriggerType] = useState("onTrigger");

  // Function to access Alarm Tab
  const handleAlarmtab = () => {
    setAlarmtab(true);
    setLogsTab(false);
  };

  // Function to access Logs Tab
  const handleLogsTab = () => {
    setAlarmtab(false);
    setLogsTab(true);
  };

  // Delete Alarm API

  const deleteAlarm = async (alarmIdToDelete) => {
    const alarmToDelete = {
      Project_id: uniqueProjectId,
      token: localStorage.getItem("token"),
      alarmId: alarmIdToDelete,
      scope: "visionWeb.alarm.delete",
    };

    dispatch(deleteAlarmAction(alarmToDelete))
      .then((response) => {
        if (response?.payload?.responseBody?.status === 200) {
          toast({
            title: `Successfully deleted the alarm`,
            status: "success",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: `Couldn't delete the alarm`,
            status: "error",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
        }
      })
  };

  // Add Alarm API

  useEffect(() => {
    if (newAlarm) {

      let newAlarmData = {
        token: localStorage.getItem("token"),
        alarmName: newAlarm.alarmName,
        channel: newAlarm.channel,
        value: newAlarm.value,
        medium: newAlarm.medium,
        operation: newAlarm.operation,
        Project_id: uniqueProjectId,
        triggerType: newAlarm.triggerType,
        triggerValue: newAlarm.triggerValue,
        scope: "visionWeb.alarm.add",
      };

      if (newAlarm.medium === "telegram") {
        newAlarmData.BotToken = botToken
        newAlarmData.chatID = chatId
      }

      dispatch(addAlarm(newAlarmData))
        .then((response) => {
          if (response?.payload?.responseBody?.status === 200) {
            toast({
              title: `Successfully added the alarm`,
              status: "success",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
          } else {
            toast({
              title: `Couldn't add the alarm`,
              status: "error",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
          }
        })
    }
  }, [newAlarm]);

  const openEditModel = () => {
    setOpenModel(true);
    setTitleText("Edit Alarm");
  };

  const handleAddAlarm = () => {
    setOpenModel(true);
    setTitleText("Add Alarm");
    setSelectedCard({
      alarmName: "",
      channel: "",
      value: 0,
      medium: "email",
      operation: "=",
      on: true,
    });
  };

  // Get Alarm API
  useEffect(() => {

    const alarmBody = {
      token: localStorage.getItem("token"),
      Project_id: uniqueProjectId,
      scope: "visionWeb.alarm.read",
    };
    dispatch(fetchAlarmData(alarmBody));
  }, [newAlarm, inputValue]);

  // Update Alarm API
  useEffect(() => {
    if (inputValue) {
      const updatedAlarmData = {
        Project_id: uniqueProjectId,
        alarmId: inputValue.alarmId,
        alarmName: inputValue.alarmName,
        channel: inputValue.channel,
        value: inputValue.value,
        medium: inputValue.medium,
        operation: inputValue.operation,
        triggerType: inputValue.triggerType,
        triggerValue: inputValue.triggerValue,
        scope: "visionWeb.alarm.update",
      };

      
      dispatch(updateAlarm({ token: token, newData: updatedAlarmData }))
        .then((response) => {
          if (response?.payload?.responseBody?.status === 200) {
            toast({
              title: `Successfully updated the alarm`,
              status: "success",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
          } else {
            toast({
              title: `Couldn't update the alarm`,
              status: "error",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
          }
        })
      }
  }, [inputValue]);

  return (
    <>
      <div className={styles.body}>
        <div className={styles.alarm}>
          {!openModel ? (
            <div className={styles.alarmHeadWrapper}>
              <div className={styles.alarmBodyWrapper}>
                <div className={styles.tabWrapper}>
                  <button
                    className={
                      alarmTab
                        ? `${styles.alarmTabBtn} ${styles.active}`
                        : styles.alarmTabBtn
                    }
                    onClick={handleAlarmtab}
                  >
                    Alarms
                  </button>
                  <button
                    className={
                      logsTab
                        ? `${styles.logsTabBtn} ${styles.active}`
                        : styles.logsTabBtn
                    }
                    onClick={handleLogsTab}
                  >
                    Logs
                  </button>
                </div>
                {alarmTab ? (
                  <div className={styles.alarmCardWrapper}>
                    {alarmData?.map((alarm, index) => (
                      <AlarmCard
                        key={index}
                        setSelectedCard={setSelectedCard}
                        setOpenModel={setOpenModel}
                        alarm={alarm}
                        openEditModel={openEditModel}
                        titleText={titleText}
                        setTitleText={setTitleText}
                        handleAddAlarm={handleAddAlarm}
                        deleteAlarm={deleteAlarm}
                        setSelectedTriggerType = {setSelectedTriggerType}
                      />
                    ))}
                  </div>
                ) : (
                  <div>
                    <AlarmLogs />
                  </div>
                )}
                {userPermission?.addAlarm && (
                  <div
                    className={styles.addAlarm}
                    style={logsTab ? { display: "none" } : null}
                    onClick={handleAddAlarm}
                  >
                    <AiOutlinePlus />
                    Add Alarm
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className={styles.modelWrapper}>
              <ModelAlarm
                setOpenModel={setOpenModel}
                setInputValue={setInputValue}
                inputValue={inputValue}
                alarm={selectedCard}
                uniqueProjectId={uniqueProjectId}
                setSelectedCard={setSelectedCard}
                titleText={titleText}
                setNewAlarm={setNewAlarm}
                selectedCard={selectedCard}
                setBotToken={setBotToken}
                setChatId={setChatId}
                selectedTriggerType={selectedTriggerType}
                setSelectedTriggerType={setSelectedTriggerType}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Alarm;
