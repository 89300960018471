import React, { useEffect, useState } from "react";
import styles from "./ToolBarGrid.module.css";

const ToolBarGrid = ({ tableData, gridIndex, chartChannel }) => {
  const [graphData, setGraphdata]= useState();

  useEffect(() => {
    const selectedChannelsData = chartChannel?.length > 0  ? chartChannel.map((channel) => tableData.find((data) => channel === data.Header)) : [tableData[0]];
    setGraphdata(selectedChannelsData);
  }, [chartChannel, tableData]);

  return (
    <>
    {tableData.length > 0 ? (
    <div className={styles.channels} key={gridIndex}>
      {graphData?.map((data, index) => (
        <div className={styles.channel} key={index}>
          <div className={styles.channelWrapper}>
            <div className={styles.channelTop}>
              <div className={styles.field}>{data?.Header}</div>
              <div className={styles.property}>{data?.val}</div>
            </div>
            <div className={styles.dateTime}>Last Updated: {data?.time}</div>
          </div>
        </div>
      ))}
    </div>
    ):(
      <div>No Data Available</div>
    )}
    </>
  );
};

const NewToolBarGrid = ({ tableData, gridIndex, chartChannel }) => {

  const [graphData, setGraphdata]= useState();

  useEffect(()=>{
    const selectedChannelsData = chartChannel ? chartChannel.map((channel) => tableData.find((data) => channel === data.Header)) : [tableData[0]]
    setGraphdata(selectedChannelsData);
  },[chartChannel, tableData])  
  
  return (
    <>
    {tableData.length > 0 ? (
    <div className={styles.channels} key={gridIndex}>
      {graphData?.map((data, index) => (
        <div className={styles.channel} key={index}>
          <div className={styles.channelWrapper}>
            <div className={styles.channelTop}>
              <div className={styles.field}>{data?.Header}</div>
              <div className={styles.property}>{data?.val}</div>
            </div>
            <div className={styles.dateTime}>Last Updated: {data?.time}</div>
          </div>
        </div>
      ))}
    </div>
    ):(
      <div>No Data Available</div>
    )}
    </>
  );
};

export {ToolBarGrid,NewToolBarGrid};
