import React from "react";
import styles from "./LogData.module.css";

const LogData = ({ data }) => {
  const currentTime = new Date();
  const responsetime = new Date(data.time);
  const timeDifference = Math.floor((currentTime - responsetime) / 60000);

  const time = new Date(data.time);
  const hours = time.getUTCHours();
  const minutes = time.getUTCMinutes();
  const seconds = time.getUTCSeconds();
  
  const timestamp= new Date(data.timestamp)
  const newtime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  const newdate = timestamp.toLocaleDateString();

  

  // Format the time as a string (e.g., "10:23:25")
  const newTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  const newDate = time.toLocaleDateString();

  const formatTimeDifference = (minutes) => {
    if (minutes < 60) {
      return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
    } else if (minutes < 1440) {
      // 1440 minutes in a day (24 hours)
      const hours = Math.floor(minutes / 60);
      return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
    } else {
      const days = Math.floor(minutes / 1440);
      return days === 1 ? "1 day ago" : `${days} days ago`;
    }
  };

  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <div className={styles.bodyContainer}>
          <div className={styles.logInfo}>
            <div className={styles.alarmName}>{data.alarmName}</div>
            <div className={styles.time}>
              {timeDifference === 0
                ? "Just Now"
                : formatTimeDifference(timeDifference)}
            </div>
          </div>
          <div className={styles.valueWrapper}>
            <div className={styles.value}>value:</div>
            <div className={styles.cardBody}>{data.value}</div>
          </div>
        </div>
        <div className={styles.timeInfo}>
          <div className={styles.logTime}>{newDate}</div>
          <div className={styles.logTime}>{newTime}</div>
        </div>
      </div>
    </div>
  );
};

export default LogData;
