import React, { useEffect } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import proxy from "proxy";
import { useLocation } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import styles from "./Delete.module.css";
import { useDispatch, useSelector } from "react-redux";
import { deleteProject } from "../../../../../../../redux/slices/dangerZoneSlice.js";

function Delete() {
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uniqueProjectId = searchParams.get("project");
  const dispatch = useDispatch();
  const deleteMessage = useSelector((state) => state.dangerZone.deleteMessage);
  const token = localStorage.getItem("token");

  const HandleDelete = () => {
    const deleteProjectData = {
      token: token,
      projectId: uniqueProjectId,
    };


    dispatch(deleteProject(deleteProjectData)).then((response) => {
      if(response.payload.code === 200){
        toast({
          title: "Project Deleted",
          description: `Project ${response.payload.project.Project} (${response.payload.project.Project_id}) has been deleted`,
          status: "warning",
          position: "top-right",
          duration: 5000,
          isClosable: true,
          Project_id: uniqueProjectId,
        });
        navigate("/");
      }
    })
  };
  const userPermission = useSelector(state => state.organization.userPermission);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  return (
    <>
      <div className={styles.deleteProject}>
        <label htmlFor="">Delete Project</label>
        {userPermission.deleteProject && (
        <button onClick={onOpen} className={styles.deleteBtn}>
          Delete
        </button>
        )}
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Project
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure you want to delete the project?
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button
                  className="cancel-button"
                  ref={cancelRef}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                  <Button
                    className="confirm-button"
                    onClick={(e) => {
                      HandleDelete();
                      e.preventDefault();
                    }}
                    ml={3}
                  >
                    Delete
                  </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </div>
    </>
  );
}

export default Delete;
