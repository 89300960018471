import React, { useEffect, useState } from "react";
import styles from "./MovableTable.module.css";

const MovableTable = (props) => {
  const [headers, setHeaders] = useState();
  const [data, setData] = useState();
  const [isColumnFormat, setIsColumnFormat] = useState();

  useEffect(() => {
    setIsColumnFormat(props.column);
    setHeaders(
      props.chartChannel?.length > 0 ? props.chartChannel : props.channelNames
    );
    const selectedChannels =
      props.chartChannel?.length > 0
        ? props.chartChannel?.map((label) =>
            props.dataArrLive?.find((dataset) => dataset.label === label)
          )
        : props.dataArrLive;

    const selectedChannelData = [
      selectedChannels[0] !== undefined
        ? selectedChannels.map((data, index) => data.data[data.data.length - 1])
        : [],
    ];

    setData(selectedChannelData);
  }, [props.dataArrLive, props.chartChannel, props.column]);

  const renderTable = () => {
    if (!isColumnFormat) {
      return (
        <table className={styles.tableWrapper}>
          <thead>
            <tr>
              {headers?.map((header, columnIndex) => (
                <th key={columnIndex}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row?.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    } else {
      return (
        <table className={styles.tableWrapper}>
          <tbody>
            {headers?.map((row, rowIndex) => (
              <tr>
                <th key={rowIndex}>{row}</th>
                {data?.map((element, elementIndex) => (
                  <React.Fragment key={rowIndex}>
                    <td key={elementIndex}>{element[rowIndex]}</td>
                  </React.Fragment>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
  };

  return (
    <div className={styles.table}>
      {props.dataArrLive.length > 0 ? (
        renderTable()
        ) : (
          <div> Table Data Not Available</div>
      )}
    </div>
  );
};

const NewMovableTable = (props) => {
  const [headers, setHeaders] = useState();
  const [data, setData] = useState();
  const [isColumnFormat, setIsColumnFormat] = useState();

  useEffect(() => {
    setIsColumnFormat(props.isColumn);
    setHeaders(props.chartChannel ? props.chartChannel : props.channelNames);
    const selectedChannels = props.chartChannel
      ? props.chartChannel?.map((label) =>
          props.dataArrLive?.find((dataset) => dataset.label === label)
        )
      : props.dataArrLive;

    const selectedChannelData = [
      selectedChannels[0] !== undefined
        ? selectedChannels.map((data, index) => data.data[data.data.length - 1])
        : [],
    ];
    setData(selectedChannelData);
  }, [props.dataArrLive, props.chartChannel, props.isColumn]);

  const renderTable = () => {
    if (!isColumnFormat) {
      return (
        <table className={styles.tableWrapper}>
          <thead>
            <tr>
              {headers?.map((header, columnIndex) => (
                <th key={columnIndex}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row?.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    } else {
      return (
        <table className={styles.tableWrapper}>
          <tbody>
            {headers?.map((row, rowIndex) => (
              <tr>
                <th key={rowIndex}>{row}</th>
                {data?.map((element, elementIndex) => (
                  <React.Fragment key={rowIndex}>
                    <td key={elementIndex}>{element[rowIndex]}</td>
                  </React.Fragment>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
  };


  return (
    <div className={styles.table}>
      {props.dataArrLive.length > 0 ? (
        renderTable()
        ) : (
          <div> Table Data Not Available</div>
      )}
    </div>
  );
};

export { MovableTable, NewMovableTable };
