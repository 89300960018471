import React, { useEffect, useState } from "react";
import { mappls } from "mappls-web-maps";

const AddProjectMap = ({ setData }) => {
    useEffect(() => {
        const initializeMap = async () => {
            try {
                // Map Properties
                const mapProps = {
                    center: [22.6708, 71.5724],
                    traffic: false,
                    zoom: 7,
                    geolocation: false,
                    clickableIcons: false,
                };

                // Initialize mappls
                const mapplsClassObject = new mappls();

                // Check if mapplsClassObject is initialized
                if (mapplsClassObject) {
                    // Initialize mappls with API key
                    mapplsClassObject.initialize("4b5d031b33d1a6ecb0ddbc322dc81ab4", () => {
                        const map = mapplsClassObject.Map({ id: "map_", properties: mapProps });
                        if (map) {
                            let marker;

                            // Add event listener for click on map
                            map.on("click", (e) => {
                                const current = e.lngLat;
                                if (marker) {
                                    marker.setPosition(current);
                                } else {
                                    marker = mapplsClassObject.Marker({
                                        map: map,
                                        position: current,
                                        clusters: false,
                                    });
                                }

                                // Update the plocation field in the state correctly
                                setData((prevData) => ({
                                    ...prevData,
                                    plocation: `${current.lat}, ${current.lng}`,
                                }));
                            });
                        }
                    });
                }
            } catch (error) {
                console.error("Error initializing map:", error);
            }
        };

        initializeMap();

        // Cleanup function
        return () => {
            // Any cleanup code here if needed
        };
    }, [setData]);

    return (
        <div id="map_">
            {/* This div is where the map will be rendered */}
        </div>
    );
};

export default AddProjectMap;
