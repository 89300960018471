import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Billing.module.css";
import CardCollection from "./components/CardCollection";
import { useDispatch, useSelector } from "react-redux";
import { getBillingInfo, getPlanDetails } from "../../redux/slices/billingSlice";
import CurrentPlanDetails from "./components/CurrentPlanDetails";
import ExplorePlan from "./components/ExplorePlan";
import BillingHistory from "./components/BillingHistory";

const Billing = ({ setPage, setPageTitle }) => {
  setPage("billing");
  setPageTitle("Plans and Billing");
  const [billingData, setBillingData] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("CurrentPlans");

  useEffect(() => {

    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    dispatch(getBillingInfo({ token, email }));
    dispatch(getPlanDetails());

  }, []);

  React.useLayoutEffect(() => {
    document.title = "Billing";
    if (localStorage.getItem("token") == null) {
      //condition if not logged in
      navigate("/auth/login");
    }
  }, []);



  return (
    <div className={styles.container}>
      {/* <Navbar page="billing" /> */}
      <div className={styles.body}>
        {/* <TitleBar title="Plans and Billing" /> */}
        <div className={styles.bodyContainer}>
          <CardCollection
            reqs={billingData.Request}
            dt={billingData.DataAmount}
            users={billingData.User}
          />
        </div>

        <div className={styles.billingDetails}>
          <div className={styles.billinOptions}>
            <div className={styles.optionsBtnWrapper}>

              <button className={styles.optionBtn} style={{ fontWeight: `${selectedTab === "CurrentPlans" ? "700" : "400"}` }} onClick={() => setSelectedTab("CurrentPlans")}>Current Plans</button>
              <button className={styles.optionBtn} style={{ fontWeight: `${selectedTab === "ExplorePlans" ? "700" : "400"}` }} onClick={() => setSelectedTab("ExplorePlans")}>Explore Plans</button>
              <button className={styles.optionBtn} style={{ fontWeight: `${selectedTab === "History" ? "700" : "400"}` }} onClick={() => setSelectedTab("History")}>History</button>
            </div>

            <button className={styles.filterBtn}>Filters</button>
          </div>
          {selectedTab === 'CurrentPlans' && (
            <CurrentPlanDetails />
          )}

          {selectedTab === 'ExplorePlans' && (
            <ExplorePlan />
          )}
          {selectedTab === 'History' && (
            <BillingHistory />
          )}
        </div>
      </div>
    </div>
  );
};

export default Billing;
