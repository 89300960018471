import React, { useEffect, useState } from 'react'
import styles from "./BillingHistory.module.css"
import download from "../../../assets/download.svg";
import { useDispatch, useSelector } from 'react-redux';
import { getBillingHistory } from '../../../redux/slices/billingSlice.js';
import mainLogo from "../../../assets/Mainlogo.png"
import jsPDF from "jspdf";
import 'jspdf-autotable';


const BillingHistory = () => {

    const dispatch = useDispatch();
    const billHistory = useSelector((state) => state.billing.billHistory);
    const userDetails = useSelector((state) => state.billing.userDetails)
    const [historyData, setHistoryData] = useState([])

    useEffect(() => {
        const email = localStorage.getItem("email");
        dispatch(getBillingHistory(email))
    }, [])

    useEffect(() => {
        
        if (billHistory.length > 0) {
            let parsedData = billHistory.map(bill => {
                let parsedBill = { ...bill };

                // Create a shallow copy of the bill object
                const parsedDate = (date) => {
                    const dateObj = new Date(date);
                    const day = dateObj.getDate();
                    const month = dateObj.getMonth() + 1;
                    const year = dateObj.getFullYear();
                    const hour = dateObj.getHours();
                    const minutes = dateObj.getMinutes();
                    const seconds = dateObj.getSeconds();

                    return `${day}-${month}-${year} ${hour}:${minutes}:${seconds}`;
                }


                const paymentDate = parsedDate(parsedBill.paymentDate);
                const dueDate = parsedDate(parsedBill.dueDate);
                parsedBill.paymentDate = paymentDate;
                parsedBill.dueDate = dueDate;


                if (parsedBill.currentPlan === "S") {
                    parsedBill.currentPlan = "Starter";
                } else if (parsedBill.currentPlan === "L") {
                    parsedBill.currentPlan = "Lite";
                } else if (parsedBill.currentPlan === "P") {
                    parsedBill.currentPlan = "Pro";
                } else if (parsedBill.currentPlan === "AOU") {
                    parsedBill.currentPlan = "Add-on User";
                } else {
                    parsedBill.currentPlan = "Add-on Project";
                }
                return parsedBill;
            });

            setHistoryData(parsedData)
        }

    }, [billHistory]);

    

    const handleDownloadInvoice = (paymentDate) => {
        let selectedData = historyData.find((data) => data.paymentDate === paymentDate);
        if (!selectedData) {
            console.error('No data found for the given payment date.');
            return;
        }

        const imgWidth = 35;
        const imgHeight = 10;

        const doc = new jsPDF();

        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        doc.addImage(mainLogo, 'PNG', 10, 12.5, imgWidth, imgHeight);

        const title = "Tax Invoice";
        doc.setFontSize(22);
        doc.text(title, 160, 20);

        // Add the border

        doc.setLineWidth(0.1); // Set the line width for the border
        doc.rect(5, 5, pageWidth - 10, pageHeight - 10); // Draw the border

        // Draw vertical line beside the logo
        // doc.setLineWidth(0.1); // Adjust the line width for the vertical line
        // doc.line(50, 5, 50, 30);

        // doc.setLineWidth(0.1); // Adjust the line width for the vertical line
        // doc.line(140, 5, 140, 30);

        // Draw horizontal line under the logo
        doc.setLineWidth(0.1); // Adjust the line width for the horizontal line
        doc.line(5, 30, pageWidth - 5, 30);

        //company name
        doc.setFontSize(14);
        const companyName = "Dicot Innovations Private Limited"
        doc.text(companyName, 60, 11.5);

        // compnay address
        const address = "A-805, Siddhivinayak Towers, Off SG Road B/H DCP Office, Makarba Ahmedabad Gujarat 380051 India GSTIN 24AAJCD0311M1ZX";

        const addressLines = doc.splitTextToSize(address, pageWidth - 120); // Split address into multiple lines
        let addressYPosition = 16; // Position for the first line of the address
        doc.setFontSize(8);
        addressLines.forEach((line) => {
            doc.text(line, 60, addressYPosition);
            addressYPosition += 4; // Move to the next line
        });

        const tableData = [];
        for (let key in selectedData) {
            if (selectedData.hasOwnProperty(key)) {
                tableData.push([key, selectedData[key]]);
            }
        }

        // user details
        const billToText = "Bill to:";
        doc.setFillColor(230, 230, 230); // Background color
        doc.rect(13, 39, pageWidth - 20, 8, 'F');
        doc.setTextColor(0, 0, 0); // Text color
        doc.setFontSize(12);
        doc.text(billToText, 15, 45);

        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        doc.text(`${userDetails.companyName}`, 15, 53)
        doc.text(`${userDetails.address}`, 15, 59)
        doc.text(`${userDetails.city}`, 15, 65)
        doc.text(`${userDetails.pincode}`, 15, 71)
        doc.text(`${userDetails.state}`, 15, 77)
        doc.text(`${userDetails.country}`, 15, 83)
        doc.text(`GSTIN ${userDetails.gstNumber}`, 15, 89)

        // Table's headers and values
        let tableValues = Object.values(selectedData);
        const tableHeaders = ["Payment Date", "Amount", "Email", "Plan", "Duration", "Due Date"];

        // Add the table to the document
        doc.text("Payment Details :", 15, 100)
        doc.autoTable({
            head: [tableHeaders],
            body: [tableValues],
            startY: 105,
            theme: 'grid',
            headStyles: { fillColor: [242, 243, 244], textColor: [0, 0, 0] }, // Optional: Customize the table header style
            styles: { fontSize: 10 }
        });

        doc.text("Terms & Conditions", 15, 135);

        // Define the terms text
        let terms = [
            "1. The payment is non-refundable",
            "2. The product price includes online support till the expiry of the product or till the mentioned date, if any mentioned",
            "3. Payment is due on receipt and any service/product will be provided only after the payment",
            "4. Payment can be made using Razorpay gateway provided or using the net-banking app of your bank",
            "5. By paying and using our products, you agree to our Privacy Policies and Usage terms mentioned on our website https://dicot.tech",
            "6. Reselling our products/services without permission from Dicot Innovations Private Limited is unauthorized and can be met with legal action"
        ];

        // Define the maximum width for the text
        let maxWidth = 180; // Adjust as needed
        let startY = 140;   // Starting Y position for the text

        // Loop through each term and add it to the PDF
        terms.forEach(function (term, index) {
            // Split the text to fit within the maxWidth
            var splitText = doc.splitTextToSize(term, maxWidth);
            // Add the text to the PDF, incrementing the Y position as needed
            doc.text(splitText, 15, startY);
            startY += splitText.length * 5; // Adjust the line height as needed
        });

        doc.text("This invoice is electronically generated; therefore, no signature is needed.", 40 , pageHeight-10);
        doc.save(`invoice_${paymentDate}.pdf`);
    };



    return (
        <div className={styles.billingHistory}>
            <div className={styles.headers}>
                <div className={styles.header}>Sr.NO</div>
                <div className={styles.header}>Plan</div>
                <div className={styles.header}>Amount</div>
                <div className={styles.header}>Date</div>
                {/* <div className={styles.header}>Add-ons</div> */}
                <div className={styles.header}>Duration</div>
                <div className={styles.header}>Invoice</div>

            </div>
            <div className={styles.billsContainer}>

                {historyData.length > 0 && (
                    historyData?.map((bill, index) => (

                        <div className={styles.headerValues} key={index}>
                            <div className={styles.headerValue}>{index + 1}</div>
                            <div className={styles.headerValue}>{bill.currentPlan}</div>
                            <div className={styles.headerValue}>₹{bill.amount}</div>
                            <div className={styles.headerValue}>{bill.paymentDate}</div>
                            {/* <div className={styles.headerValue}>1</div> */}
                            <div className={styles.headerValue}>{bill.duration}</div>
                            <button className={styles.headerValue} onClick={() => handleDownloadInvoice(bill.paymentDate)} >
                                <img src={download} alt="" />
                                <label htmlFor="" style={{ cursor: "pointer" }}>Download</label>
                            </button>
                        </div>
                    ))
                )}
            </div>
        </div>
    )
}

export default BillingHistory