import mqtt from 'mqtt';
import {mqttProxy , mqttServerProxy} from '../mqttProxy';
let mqttClient;

export const intializeMqtt = (options) => {
  try {
    return new Promise((resolve, reject) => {
      mqttClient = mqtt.connect(mqttProxy,options);
      mqttClient.on('connect', () => {
        console.log('MQTT Connected Successfully');
        resolve(true);
      });

      mqttClient.on('error', (error) => {
        console.error('MQTT Connection Error:', error);
        // reject(error);
      });
    });
  } catch (error) {
    console.log('Mqtt connection error:', error);
  }
};

export const subscribeTopics = (topics) => {
  try {
    mqttClient.subscribe(topics, (err) => {
      if (err) {
        console.error('Failed to subscribe to topics:', err);
      } else {
        console.log('Mqtt topics subscribe successfully');
      }
    });
  } catch (error) {
    console.log('Subscribe topic error:', error);
  }
};

export const unsubscribeTopic = async (topic) => {
  try {
    mqttClient.unsubscribe(topic, (error) => {
      if (error) {
        console.error(`Failed to unsubscribe from topic ${topic}:`, error);
      } else {
        console.log(`Successfully unsubscribed from topic ${topic}`);
      }
    });
  } catch (error) {
    console.log('Unsubscribe topic error:', error);
  }
};

export const getMqttData = (onNewData) => {
  try {
    mqttClient.on('message', async (topic, message) => {
      if (message) {
        const data = await message.toString();

        let metadataObject = await JSON.parse(data);
        if (metadataObject) {
          if (metadataObject?.Devices?.length > 0) {
            const stringValChannelToAppend = await metadataObject.Devices.map(
              (obj) => {
                return {
                  Header: obj.Header,
                  value: obj.value.toString(),
                };
              },
            );

            metadataObject.Devices = stringValChannelToAppend;
            if (typeof onNewData === 'function') {
              onNewData(metadataObject);
            }
          }
        }
      }
    });
  } catch (error) {
    console.log('Get mqtt data error:', error);
  }
};

export const disconnectMqtt = () => {
  try {
    mqttClient = mqtt.connect('ws://broker.hivemq.com:8000/mqtt');
    mqttClient.end(false, {}, () => {
      console.log('Disconnected from MQTT broker.');
    });
  } catch (error) {
    console.log('Mqtt disconnect error:', error);
  }
};
