import React, { useEffect } from "react";
import Suspend from "./components/Suspend";
import Delete from "./components/Delete";
import Transfer from "./components/Transfer";
import styles from "./DangerZone.module.css";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import proxy from "proxy";
import { useSelector } from "react-redux";

function DangerZone({ setPage }, props) {
  const [currentRole, setCurrentRole] = useState(null);
  const [searchParams] = useSearchParams();
  const ProjectID = searchParams.get("project");

  const settingsData = useSelector((state) => state.projectSetting.settingData);
  const userPermission = useSelector(state => state.organization.userPermission);


  useEffect(() => {
    if (Object.keys(settingsData).length > 0) {
      if (settingsData.data.admin === settingsData.currentEmail) {
        setCurrentRole("admin");
      }
    }
  }, [settingsData]);

  return (
    <div>
      <div className={styles.hero}>
        <div className={styles.page_hero}>
          <h3>Danger Zone</h3>
          <div className={styles.wrapper}>
            <div className={styles.actions}>
              <Suspend />
            </div>
            <div className={styles.actions}>
              <Delete />
            </div>
            <div className={styles.actions}>
              <Transfer />
            </div>
          </div>
        </div>
      </div>
      </div>
  );
}

export default DangerZone;
