import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import proxy from "proxy";

const initialState = {
  isLoading: false,
  isError: false,
  billingInfo: {},
  planDetails : [],
  orderDetails : {},
  billHistory : [],
  activePlans : [],
  userDetails : {}
};

export const getBillingInfo = createAsyncThunk(
  "getBillingInfo",
  async ({ token, email }) => {
    const response = await fetch(
      `${proxy}/api/getBillInfo?token=${token}&email=${email}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const responseBody = await response.json();
    return responseBody;
  }
);

export const getPlanDetails = createAsyncThunk("getPlanDetails", async() => {
  const response = await fetch(`${proxy}/bill/getPlanDetails` , {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })

  const responseBody = await response.json();
  return responseBody
})

export const createOrder = createAsyncThunk("createOrder", async(data) => {
  const response = await fetch(`${proxy}/bill/create/orderId` , {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body : JSON.stringify(data)
  })

  const responseBody = await response.json();
 
  return responseBody;
})

export const getBillingHistory = createAsyncThunk("getBillingHistory" , async(email) => {
  const response = await fetch(`${proxy}/bill/getPlansHistory?email=${email}` , {
    method : "GET",
    headers : {
      "Content-Type" : "application/json"
    },
  })

  const responseBody = await response.json();
  return responseBody;
})

export const getActivePlans = createAsyncThunk("getActivePlans", async(email) => {
  const response = await fetch(`${proxy}/bill/getActivePlans?email=${email}`, {
    method : "GET",
    headers : {
      "Content-Type" : "application/json"
    }
  })

  const responseBody = await response.json();
  return responseBody;
})

export const billingSlice = createSlice({
  name: "billing",
  initialState,
  extraReducers: (builder) => {

    // user billing info
    builder.addCase(getBillingInfo.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });

    builder.addCase(getBillingInfo.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.billingInfo = action.payload;
    });

    builder.addCase(getBillingInfo.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });


    // All plan details
    builder.addCase(getPlanDetails.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });

    builder.addCase(getPlanDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.planDetails = action.payload.planDetails;
    });

    builder.addCase(getPlanDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });

    //create order
    builder.addCase(createOrder.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });

    builder.addCase(createOrder.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.orderDetails = action.payload;
    });

    builder.addCase(createOrder.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });

    //Plans history

    builder.addCase(getBillingHistory.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });

    builder.addCase(getBillingHistory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.billHistory = action.payload.message;
      state.userDetails = action.payload.userDetails;
    });

    builder.addCase(getBillingHistory.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });

    // Active Plans
    builder.addCase(getActivePlans.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });

    builder.addCase(getActivePlans.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.activePlans = action.payload.message;
    });

    builder.addCase(getActivePlans.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });

  },
});

export default billingSlice.reducer;