import React from "react";
import FeedbackForm from "./FeedbackForm.jsx";
import styles from "./Feedback.module.css";
import { useNavigate } from "react-router-dom";

function Feedback({setPage, setPageTitle}) {
  setPage("feedback")
  setPageTitle("Feedback");
  const navigate = useNavigate();
  React.useLayoutEffect(() => {
    document.title = "Report";
    if (localStorage.getItem("token") == null) {
      //condition if not logged in
      navigate("/auth/login");
    } // eslint-disable-next-line
  }, []);
  return (
    <div className={styles.superWrapper}>
      <div className={styles.wrapper1}>
        <div className={styles.feedback}>
          <FeedbackForm />
        </div>
      </div>
    </div>
  );
}

export default Feedback;
