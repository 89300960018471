import React from 'react'
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
  } from "@chakra-ui/react";

const OverviewTable = ({tableData}) => {
  return (

    <TableContainer overflowY="auto" overflowX="auto">
    <Table size="sm">
      <Thead>
        <Tr>
          <Th>Time</Th>
          <Th>Channel</Th>
          <Th isNumeric>Last value</Th>
          <Th isNumeric>Minimum</Th>
          <Th isNumeric>Maximum</Th>
        </Tr>
      </Thead>
      <Tbody>
        {tableData.length > 0 &&
          tableData.map((data, index) => {
            if (data) {
              return (
                <Tr key={index}>
                  <Td>{data.time}</Td>
                  <Td>{data.Header}</Td>
                  <Td isNumeric>{data.val}</Td>
                  <Td isNumeric>{data.minValue}</Td>
                  <Td isNumeric>{data.maxValue}</Td>
                </Tr>
              );
            }
          })}
      </Tbody>
    </Table>
  </TableContainer>
  )
}

export default OverviewTable;